import * as Yup from 'yup';
// import moment from 'moment';
import checkoutFormModel from './checkoutFormModel';
const {
  formField: {
    //personal details
    Resedential_Address17,
    Postal_Address20,
    ID_No6,
    Mobile_No214,
    LandlineHome_phone16,
    Passport_No7,
    No_of_dependents9,
    profession_cd_Profession11,
    Nationality10,

    //montly expenditure #####INCOME
    Gross_Incomepe1,
    Net_Income_per2,
    Other_Incomesp3,
    Total_IncomeNe4,
    Monthly_Rental1,
    Monthly_Bus_Fare2,
    Monthly_vehicle3,
    Monthly_school4,
    Monthly_Insurance7,
    Monthly_credit_8,
    Monthly_Groceries9,
    Monthly_Utiliti10,
    Other_monthly_e11,
   
    //docs
    doc1 }
} = checkoutFormModel;

//const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
const MOBILE_NO_REGEX = /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;


// eslint-disable-next-line import/no-anonymous-default-export
export default [
  Yup.object().shape({
      //personal details page
      [Resedential_Address17.name]: Yup.string().required(`${Resedential_Address17.requiredErrorMsg}`),
      [Postal_Address20.name]: Yup.string().required(`${Postal_Address20.requiredErrorMsg}`),
      [ID_No6.name]: Yup.string().required(`${ID_No6.requiredErrorMsg}`),
      [Passport_No7.name]: Yup.string(),
      [No_of_dependents9.name]: Yup.string(),
      [profession_cd_Profession11.name]: Yup.string(),
      [Nationality10.name]: Yup.string().required(`${Nationality10.requiredErrorMsg}`),
      [Mobile_No214.name]:
        Yup
          .string()
          .matches(MOBILE_NO_REGEX, "Please enter a valid mobile number")
          .required(`${Mobile_No214.requiredErrorMsg}`),
      [LandlineHome_phone16.name]: Yup.number().typeError("Enter numbers only"),
  }),
  Yup.object().shape({
  //montly expenditure #####INCOME
  [Gross_Incomepe1.name]: Yup.number().typeError('Enter numbers only').required(`${Gross_Incomepe1.requiredErrorMsg}`),
  [Net_Income_per2.name]: Yup.number().typeError('Enter numbers only').required(`${Net_Income_per2.requiredErrorMsg}`),
  [Other_Incomesp3.name]: Yup.number().typeError('Enter numbers only'),
  // [Total_IncomeNe4.name]: Yup.number(),
  [Monthly_Rental1.name]: Yup.number().typeError('Enter numbers only'),
  [Monthly_Bus_Fare2.name]: Yup.number().typeError('Enter numbers only'),
  [Monthly_vehicle3.name]: Yup.number().typeError('Enter numbers only'),
  [Monthly_school4.name]: Yup.number().typeError('Enter numbers only'),
  [Monthly_Insurance7.name]: Yup.number().typeError('Enter numbers only'),
  [Monthly_credit_8.name]: Yup.number().typeError('Enter numbers only'),
  [Monthly_Groceries9.name]: Yup.number().typeError('Enter numbers only').required(`${Monthly_Groceries9.requiredErrorMsg}`),
  [Monthly_Utiliti10.name]: Yup.number().typeError('Enter numbers only').required(`${Monthly_Utiliti10.requiredErrorMsg}`),
  [Other_monthly_e11.name]: Yup.number().typeError('Enter numbers only'), 
  // Net_IncomeTota12,
  //
}),

];
