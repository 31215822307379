import React, { useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import axios from "axios";
import { Buffer } from "buffer";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";

const BankStatementForRenewal = () => {
  const [file, setFile] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [statusError, setStatusError] = useState("");
  const navigate = useNavigate();

  function handleFile(e) {
    setFile(e.target.files[0]);
  }

  console.log(file);
  function handleInput(e) {
    setName(e.target.value);
    setDescription(e.target.value);
  }

  //submit file
  async function submitFile(e) {
    e.preventDefault();

    let apiHeaders = {
      Authorization:
        "Basic " + Buffer.from("webuser:Demo@#2022").toString("base64"),
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Allow_Methods: ["PUT"],
      Allow_Origin: ["*"],
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": "https://clubplus-backend.onrender.com",
      "x-api-key": "oHJ0G18QYt2SMVNpogzio8WttytilwE17tuAky7p",
      "X-Fineract-Platform-TenantId": "clubplus",
    };

    try {
      const formData = new FormData();

      formData.append("file", file);
      formData.append("name", name);
      formData.append("description", description);

      const response = await axios.post(
        "https://api.demo.irl.musoniservices.com/v1/ml_client_details/26810/documents",
        formData,
        {
          headers: apiHeaders,
          mode: "no-cors",
          withCredentials: true,
          credentials: "same-origin",
          // onUploadProgress: onUploadProgress,
        }
      );
      // console.log(percentage);

      console.log(response.status);
      if (response.status == 200) {
        setStatus("Document uploaded successfully");
      }
      setTimeout(() => navigate("/upload-success"), 3000);
      // console.log(percentage);
    } catch (err) {
      console.error(err);
      setStatusError("Error uploading file");
    }
  }

  return (
    <div className="bg-white">
      <Header />
      <div class="max-w-2xl py-2 px-5 m-auto w-full bg-gray-100 mt-4 rounded-lg">
        <h1 className="font-extrabold text-xl sm:text-2xl pb-4 text-center">
          Upload Loan Topup/Renewal Documents
        </h1>
        <h1 className="font-bold text-lg pb-4 text-center">
          1 Month Bank Statement For Loan Renewal
        </h1>
        <h2 className="pb-4 text-center">
          <i className="text-red-600">NB:</i> If the last loan has been paid up for more than 12 months submit
        </h2>
        {status && (
          <div
            className="bg-green-300 rounded-lg py-3 px-6 text-base text-gray-700 mb-3"
            role="alert"
          >
            {<p>{status}</p>}
          </div>
        )}
        {statusError && (
          <div
            className="bg-red-100 rounded-lg py-3 px-6 text-base text-red-700 mb-3"
            role="alert"
          >
            {<p>{statusError}</p>}
          </div>
        )}

        <form onSubmit={submitFile}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="name"
                label="Name Of Document"
                onChange={handleInput}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Description Of Document"
                onChange={handleInput}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <label className=" w-auto pb-4 text-center text-xs text-red-500 py-4">
                UPLOAD in JPG/PNG/PDF/XML format
              </label>

              <label class="block bg-gray-300 pl-4 py-8 rounded-lg">
                <span class="sr-only bg-black">Choose File</span>
                <TextField
                  type="file"
                  name="file"
                  onChange={handleFile}
                  class="block w-full text-sm text-white file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300"
                />
              </label>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="mx-4 my-4 flex justify-center">
                <button
                  type="submit"
                  className="w-1/2 text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-md text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
                >
                  Upload
                </button>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default BankStatementForRenewal;
