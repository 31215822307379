import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { InputField, DatePickerField, SelectField } from "../../FormFields";


const titles = [
  {
    value: "1",
    label: "Mr",
  },
  {
    value: "2",
    label: "Miss",
  },
  {
    value: "3",
    label: "Mrs",
  },
  {
    value: "4",
    label: "Doc",
  },
  {
    value: "5",
    label: "Prof",
  },
];

export default function AddressForm(props) {
  const {
    formField: {
      ID_No6,
      Passport_No7,
      No_of_dependents9,
      Nationality10,
      profession_cd_Profession11,
      // MaritalStatus_cd_Marital_Status12,
      Mobile_No214,
      LandlineHome_phone16,
      Resedential_Address17,
      Postal_Address20,
      //submittedon_date,
    },
  } = props;


  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Personal Details

      </Typography>
      <Grid container spacing={3}>
     
        <Grid item xs={12} sm={6}>
          <InputField
            name={Passport_No7.name}
            label={Passport_No7.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={LandlineHome_phone16.name}
            label={LandlineHome_phone16.label}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={ID_No6.name} label={ID_No6.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Mobile_No214.name}
            label={Mobile_No214.label}
            fullWidth
            type=""
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField
            name={No_of_dependents9.name}
            label={No_of_dependents9.label}
            fullWidth
          />
        </Grid>
      
        <Grid item xs={12} sm={6}>
          <InputField
            name={Nationality10.name}
            label={Nationality10.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Resedential_Address17.name}
            label={Resedential_Address17.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Postal_Address20.name}
            label={Postal_Address20.label}
            fullWidth
          />
        </Grid>

        
      </Grid>
      <div className="flex flex-col bg-gray-200 h-auto justify-between mt-4 px-4 pt-4 rounded-lg">
          <h1 className="uppercase text-red-700 text-medium font-bold pt-2">notice!</h1>
          <div className=" my-4">
          If personal, employment details and other related details that were intitially submitted changed please apply for a new loan 
          </div>
          <a href="/apply-loan" class="w-40 mb-4 text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-md text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800">Apply New Loan</a>

        </div>
    </React.Fragment>
  );
}
