/* eslint-disable import/no-anonymous-default-export */
export default {
  formId: 'checkoutForm',

  formField: {
    //personal details
    Mobile_No214: {
      name: 'Mobile_No214',
      label: 'Mobile Number 0777777777',
      requiredErrorMsg: 'Mobile Number is required'

    },
    LandlineHome_phone16: {
      name: 'LandlineHome_phone16',
      label: 'Landline',

    },

    //submittedon_date,
    Resedential_Address17: {
      name: 'Resedential_Address17',
      label: 'Residential Address*',
      requiredErrorMsg: 'Resedential Address is required'
    },
    Postal_Address20: {
      name: 'Postal_Address20',
      label: 'Postal Address*',
      requiredErrorMsg: 'Residential Address is required'
    },
    ID_No6: {
      name: 'ID_No6',
      label: 'Identification Number',
      requiredErrorMsg: 'ID number is required'
    },
    Passport_No7: {
      name: 'Passport_No7',
      label: 'Passport Number',
      requiredErrorMsg: 'Passport Number is required'
    },
    No_of_dependents9: {
      name: 'No_of_dependents9',
      label: 'Number Of Dependents',
    },
    profession_cd_Profession11: {
      name: 'profession_cd_Profession11',
      label: 'Profession',
    },
    Nationality10: {
      name: 'Nationality10',
      label: 'Nationality',
      requiredErrorMsg: 'Nationality is required'

    },
    loanAmount: {
      name: 'loanAmount',
      label: 'Loan Amount',
      requiredErrorMsg: 'Loan Amount is required'

    },
    loanType: {
      name: 'loanType',
      label: 'Loan Type',
      requiredErrorMsg: 'Loan Type is required'

    },

    //employment details
    Employer_Physic3: {
      name: 'Employer_Physic3',
      label: 'Work Address',
      requiredErrorMsg: 'Work Address is required'
    },
    Employer1: {
      name: 'Employer1',
      label: 'Name Of Employer',
      requiredErrorMsg: 'Name Of Employer is required'
    },
    Job_Title2: {
      name: 'Job_Title2',
      label: 'Job Title',
      requiredErrorMsg: 'Job Title is required'
    },
    Work_Phone_Number12: {
      name: 'Work_Phone_Number12',
      label: 'Work Number',
      requiredErrorMsg: 'Work number is required'

    },
    Pay_Dayeg_25t12: {
      name: 'Pay_Dayeg_25t12',
      label: 'Pay Date eg 28, 29 or 31',
      requiredErrorMsg: 'Pay Date is required'
    },
    Time_at_current7: {
      name: 'Time_at_current7',
      label: 'Time Of Current Job in years ',
      requiredErrorMsg: 'Time of current job is required'
    },
    Contract_Status9: {
      name: 'Contract_Status9',
      label: 'Contract Status',
      requiredErrorMsg: 'Contract Status is required'
    },
    Previous_compan8: {
      name: 'Previous_compan8',
      label: 'Previous Company',
      requiredErrorMsg: 'Previous Company is required'
    },
    //banking details
    Bank_Name1: {
      name: 'Bank_Name1',
      label: 'Bank Name',
      requiredErrorMsg: 'Bank Name is required'
    },
    Account_Name2: {
      name: 'Account_Name2',
      label: 'Account name used to open the Account',
      requiredErrorMsg: 'Name you used to open the Account is required'
    },
    Branch_Name3: {
      name: 'Branch_Name3',
      label: 'Branch Name',
      requiredErrorMsg: 'Branch Name is required'
    },
    Bank_Branch_Code4: {
      name: 'Bank_Branch_Code4',
      label: 'Bank Branch Code',
      requiredErrorMsg: 'Bank Branch Code is required'
    },
    Account_Number5: {
      name: 'Account_Number5',
      label: 'Account Number',
      requiredErrorMsg: 'Account_Number Account is required'
    },
    Account_Type6: {
      name: 'Account_Type6',
      label: 'Account Type eg current, savings',
      requiredErrorMsg: 'Account type is required'
    },
    //references # spouse details
    First_Name2: {
      name: 'First_Name2',
      label: 'First Name'
    },
    TITLE_cd_Title1: {
      name: 'TITLE_cd_Title1',
      label: 'Title'
    },
    Maiden_Name4: {
      name: 'Maiden_Name4',
      label: 'Maiden Name'
    },
    Surname3: {
      name: 'Surname3',
      label: 'Lastname'
    },
    spouseID_No6: {
      name: 'spouseID_No6',
      label: 'Identification Number'
    },
    spousePassport_No7: {
      name: 'spousePassport_No7',
      label: 'Passport Number'
    },
    Date_Of_Birth5: {
      name: 'Date_Of_Birth5',
      label: 'Date Of Birth'
    },
    spouseNo_of_dependents9: {
      name: 'spouseNo_of_dependents9',
      label: 'Number Of Dependents'
    },
    Gender_cd_Gender8: {
      name: 'Gender_cd_Gender8',
      label: 'Gender'
    },
    spouseNationality10: {
      name: 'spouseNationality10',
      label: 'Nationality'
    },
    spouse_profession_cd_Profession11: {
      name: 'spouse_profession_cd_Profession11',
      label: 'Profession'
    },
    Mobile_No12: {
      name: 'Mobile_No12',
      label: 'Mobile Number'
    },
    Mobile_no213: {
      name: 'Mobile_no213',
      label: 'Secondary Mobile Number'
    },
    Email_address14: {
      name: 'Email_address14',
      label: 'Email Address'
    },

    Landline15: {
      name: 'Landline15',
      label: 'Landline Number'
    },
    Residential_Address16: {
      name: 'Residential_Address16',
      label: 'Residential Address'
    },
    Postal_Address17: {
      name: 'Postal_Address17',
      label: 'Postal Address'
    },
    //# next of keen details
    GuarantorRelationship_cd_relation_to_client: {
      name: 'GuarantorRelationship_cd_relation_to_client',
      label: 'Relationship'
    },
    TITLE_cd_Title5: {
      name: 'TITLE_cd_Title5',
      label: 'Title'
    },
    name: {
      name: 'name',
      label: 'Name'
    },
    Surname6: {
      name: 'Surname6',
      label: 'Surname'
    },
    Mobile_No8: {
      name: 'Mobile_No8',
      label: 'Mobile Number eg 0777777777'
    },
    Email_Address9: {
      name: 'Email_Address9',
      label: 'Email Address'
    },
    LandlineHomephone10: {
      name: 'LandlineHomephone10',
      label: 'Home Landline Phone Number'
    },
    Residential_Address11: {
      name: 'Residential_Address11',
      label: 'Residential_Address'
    },
    Employer_Physic12: {
      name: 'Employer_Physic12',
      label: 'Employer Physical Address'
    },
    Work_Phone_no13: {
      name: 'Work_Phone_no13',
      label: 'Work Phone Number'
    },
    Gender_cd_Gender19: {
      name: 'Gender_cd_Gender19',
      label: 'Gender'
    },
    dateOfBirth: {
      name: 'dateOfBirth',
      label: 'Date Of Birth'
    },
    ID_number17: {
      name: 'ID_number17',
      label: 'Identification Number eg 77888888A77'
    },
    Passport_number18: {
      name: 'Passport_number18',
      label: 'Passport Number eg PX112233'
    },
    Nationality20: {
      name: 'Nationality20',
      label: 'Nationality'
    },
    profession_cd_Profession21: {
      name: 'profession_cd_Profession21',
      label: 'Profession'
    },
    Postal_Address22: {
      name: 'Postal_Address22',
      label: 'Postal Address'
    },
    Employer23: {
      name: 'Employer23',
      label: 'Employer'
    },
    Mobile_Number24: {
      name: 'Mobile_Number24',
      label: 'Mobile Number'
    },
    Number_of_Dependency25: {
      name: 'Number_of_Dependency25',
      label: 'Number Of Dependents'
    },
    ////references #detils of relatives who do not stay with you
    relativesTITLE_cd_Title1: {
      name: 'relativesTITLE_cd_Title1',
      label: 'Title'
    },
    relativesFirst_Name2: {
      name: 'relativesFirst_Name2',
      label: 'Firstname'
    },
    relativesSurname3: {
      name: 'relativesSurname3',
      label: 'Lastname'
    },
    Relationship4: {
      name: 'Relationship4',
      label: 'Relationship'
    },
    Mobile_No6: {
      name: 'Mobile_No6',
      label: 'Mobile Number'
    },
    Email_Address7: {
      name: 'Email_Address7',
      label: 'Email Address'
    },
    Home_Landline8: {
      name: 'Home_Landline8',
      label: 'House Number'
    },
    Resedential_Address9: {
      name: 'Resedential_Address9',
      label: 'Resedential Address'
    },
    Business_Address10: {
      name: 'Business_Address10',
      label: 'Business Address'
    },
    Occupation5: {
      name: 'Occupation5',
      label: 'Occupation'
    },
    Work_phone_no11: {
      name: 'Work_phone_no11',
      label: 'Work Phone Number'
    },
    ////references #detils of relatives who dont stays with you
    First_Name21111: {
      name: 'First_Name21111',
      label: 'First Name'
    },
    Surname31010: {
      name: 'Surname31010',
      label: 'Surname'
    },
    Relationship599: {
      name: 'Relationship599',
      label: 'Relationship'
    },
    Occupation488: {
      name: 'Occupation488',
      label: 'Occupation'
    },

    Mobile_No677: {
      name: 'Mobile_No677',
      label: 'Mobile Number'
    },
    Email_address766: {
      name: 'Email_address766',
      label: 'Email Address'
    },
    Home_landlineH855: {
      name: 'Home_landlineH855',
      label: 'Home Landline Number'
    },
    Resedential_add4: {
      name: 'Resedential_add4',
      label: 'Residential Address'
    },
    Work_phone_no1033: {
      name: 'Work_phone_no1033',
      label: 'Work Phone Number'
    },
    //montly expenditure #####INCOME
    Gross_Incomepe1: {
      name: 'Gross_Incomepe1',
      label: 'Gross Income'
    },
    Net_Income_per2: {
      name: 'Net_Income_per2',
      label: 'Net Income'
    },
    Other_Incomesp3: {
      name: 'Other_Incomesp3',
      label: 'Other Incomes'
    },
    Total_IncomeNe4: {
      name: 'Total_IncomeNe4',
      label: 'Total Income'
    },
    Monthly_Rental1: {
      name: 'Monthly_Rental1',
      label: 'Monthly Rental'
    },
    Monthly_Bus_Fare2: {
      name: 'Monthly_Bus_Fare2',
      label: 'Monthly Bus Fare'
    },
    Monthly_vehicle3: {
      name: 'Monthly_vehicle3',
      label: 'Monthly Vehicle Expenses'
    },
    Monthly_school4: {
      name: 'Monthly_school4',
      label: 'Monthly Fees (PreSchool ,High School ...)'
    },
    Monthly_Insurance7: {
      name: 'Monthly_Insurance7',
      label: 'Monthly Insurance'
    },
    Monthly_credit_8: {
      name: 'Monthly_credit_8',
      label: 'Monthly Credit Account Instalments'
    },
    Monthly_Groceries9: {
      name: 'Monthly_Groceries9',
      label: 'Monthly Groceries'
    },
    Monthly_Utiliti10: {
      name: 'Monthly_Utiliti10',
      label: 'Monthly Utilities & Electricity'
    },
    Other_monthly_e11: {
      name: 'Other_monthly_e11',
      label: 'Other Monthly Expenses'
    },
    Net_IncomeTota12: {
      name: 'Net_IncomeTota12',
      label: 'Total Income'
    },
    //////
    copyOfID: {
      name: 'copyOfID',
      label: 'Copy Of ID'
    },
    proofOfResidence: {
      name: 'proofOfResidence',
      label: 'Proof Of Residence'
    },
    passportPhoto: {
      name: 'passportPhoto',
      label: 'Passport Photo '
    },
    bankStatement: {
      name: 'bankStatement',
      label: 'Bank Statement '
    },
    payslip: {
      name: 'payslip',
      label: 'Payslip'
    },
    nextOfKinID: {
      name: 'nextOfKinID',
      label: 'Next Of Kin ID'
    }
  }
};
