import React, { useState } from "react";
import login from "../../images/signin.svg";
import logoo from "../../images/logoo.png";
import { Buffer } from "buffer";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setUser } from "../../Features/userSlice";
import { setOtpUser } from "../../Features/userOtpSlice";
import { reactLocalStorage } from "reactjs-localstorage";
import { createSearchParams, useNavigate } from "react-router-dom";



    

const Login = (props) => {
    const [apiError, setApiError] = useState(null);
    const [apiSuccess, setApiSuccess] = useState(null);
    const [visible, setIsVisible] = useState(true);
    //const [verid, setVerId] = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [clientId, setclientId] = useState('');
    const [mobileNo, setmobileNo] = useState('');
    

    const handleSubmit = (event) => {
      event.preventDefault();

      if (apiError != null) {
        setTimeout(function () {
          setIsVisible(false);
        }, 5000);
      }
      function refreshPage() {
        setTimeout(function(){ window.location.reload(false); }, 5000);
      }
      let apiHeaders = {
        Authorization:
          "Basic " + Buffer.from("webuser:Demo@#2022").toString("base64"),
        "Content-Type": "application/json",
        Accept: "application/json",
        Allow_Methods: ["GET, POST, PUT, PATCH, DELETE"],
        Allow_Origin: ["*"],
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Origin": "http://127.0.0.1:5000",
        "x-api-key": "oHJ0G18QYt2SMVNpogzio8WttytilwE17tuAky7p",
        "X-Fineract-Platform-TenantId": "clubplus",
      };

      console.log(clientId);
      console.log(mobileNo);
      if (typeof mobileNo !== "undefined") {
        var pattern = new RegExp(
          /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i
        );
        if (!pattern.test(mobileNo)) {
          setApiError("Please Enter Number Only");
          refreshPage();
        } else if (mobileNo.length != 10) {
          setApiError("Please enter valid  Mobile Number.");
          refreshPage()
        } else {
          //continue with geting data from API
          axios
            .get(
              "https://demo.irl.musoniservices.com:8443/api/v1/clients/" +
                clientId,
              {
                mode: "cors",
                withCredentials: true,
                credentials: "cross-origin",
                headers: apiHeaders,
              }
            )
            .then((res) => {
              //console.log(res.data);
            //  dispatch(setUser(res.data));
              console.log(res.data);
              console.log("we are here");
              console.log(res.data)
               dispatch(setUser(res.data));
              //checkif the phone number exists within the system
              if (res.data.mobileNo != mobileNo) {
                setApiError("Phone number and Client ID does not match our records.");
                refreshPage()
              } else {
                setApiSuccess("Otp send to registered number.");
                //remove zero

                const nozero = mobileNo.slice(1);
                //add country code to mobileNo and
                const fullMobileNo = "263" + nozero;
                //continue to send otp// send otp to user
                axios
                  //.post("https://clubplus.development.co.zw/sendOTP", 
                  //.post("https://rest.bluedotsms.com/api/SendSMS",
                  //.post("https://rest.bluedotsms.com/api/GetDeliveryStatus",
                  .post("https://rest.bluedotsms.com/api/Verify",
                  {
                    "api_id": "API5917286622",
                    "api_password": "Password1",
                     "sender_id": "CLUBPLUS",
                     "phonenumber": fullMobileNo,
                     "message_id": "20060379",
                     "brand": "CLUBPLUS",
                     "sms_type": "P",
                     "encoding": "T",
                      //"sender_id": "CLUBPLUS",
                      "templateid": null,
                      //"textmessage":"Club Plus Otp is: ",
                      "V1": null,
                      "V2": null,
                      "V3": null,
                      "V4": null,
                      "V5": null
                  })
                  .then((res) => {
                     console.log(res);
                   // const hash = res.data.hash;
                   // hashHandleChange(hash);
                    console.log(res.data.remarks);
                    console.log(res.data.verfication_id);

                    navigate({
                        pathname: "/otp",
                    search: createSearchParams({id:res.data.verfication_id}).toString()
                });
                    //setVerId(res.data.verfication_id);
                    reactLocalStorage.setObject(res.data);
                    //props.nextStep();
                  })
                  .catch((error) => {
                    console.log(error);
                    console.log(res.data);
                    console.log(res.data.setOtpUser);
                    setApiError("Problem with otp server");
                  });
              }
            })
            .catch((error) => {
              console.log(error);
              //for testing with no internet
             
              //navigate("/apply-loan");
             
  
              if (error.response.status == 404){
                setApiError("Account does not exist");
  
              }
            });
        }
      }
    
      


  //clear values on input fields
      setclientId('');
      setmobileNo('');

     
    };


    return (
        <div className="flex h-screen bg-gray-100 md:bg-gray-100">
          <div className="hidden lg:flex h-full">
            <img src={login} className="mx-auto" alt="clubplus" />
           
          </div>
          <div className="w-full flex items-center justify-center lg:w-1/2 ">
            <div className="max-w-2xl mx-auto">
              <div className="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm p-4 sm:p-6 lg:p-8 dark:bg-gray-800 dark:border-gray-700">
                {/* //form */}
                <form className="space-y-6" onSubmit={handleSubmit}>
                  <a href="/">
                    <img src={logoo} className="mx-auto" alt="clubplus" />
                  </a>
                  <h3 className="text-xl text-black font-bold dark:text-white text-center">
                    Sign in
                  </h3>
                  {/* //display api response error */}
                    
                  {apiError && visible && (
                      <div
                        className="bg-red-100 rounded-lg py-3 px-6 text-base text-red-700 mb-3"
                        role="alert"
                      >
                        {<p>{apiError}</p>}
                      </div>
                    )}
                         {apiSuccess && visible && (
                      <div
                        className="bg-green-100 rounded-lg py-3 px-6 text-base text-red-700 mb-3"
                        role="alert"
                      >
                        {<p>{apiSuccess}</p>}
                      </div>
                    )}
    
                  <div className="flex flex-col justify-between space-y-3">
                    <input
                         type="text"
                         id="clientId"
                         name="clientId"
                         value={clientId}
                         placeholder="Client ID"
                         onChange={(event) =>
                           setclientId(event.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 focus:outline-none block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    />
    
                    <input
                      type="text"
                      name="mobileNo"
                      value={mobileNo}
                      id="mobileNo"
                      placeholder="0777777777"
                      onChange={(event) =>
                    setmobileNo(event.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 focus:outline-none block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    />
                  </div>
                  <div className="flex items-center justify-center">
                    <button
                      type="submit"
                      //onClick={Continue}
                      className="w-3/5 text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-md text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                    >
                      Send OTP
                    </button>

                  </div>
                </form>
    
                <div className="flex items-center pt-5 justify-between">
                  <div class="text-sm">
                    <a
                      href="/forgot-password"
                      className="text-sm flex-start hover:underline ml-auto text-red-400"
                    >
                      Forgot Password?
                    </a>
                  </div>
                  <div className="text-sm font-medium text-gray-500 dark:text-gray-300 items-end">
                    New User:
                    <a href="/register" className=" hover:underline text-red-400">
                      Register
                    </a>
                  </div>
                </div>
                <a href="/otp">Have OTP</a>
                {/* <button onClick={tap}>click</button> */}
              </div>
            </div>
          </div>
        </div>
      );
                      

};

export default Login;