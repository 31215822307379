import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userOtp: null
  };
  
  const userOtpSlice = createSlice({
    name: "userOtp",
    initialState,
    reducers: {
        setOtpUser: (state, action) => {
        state.userOtp = action.payload;
      }
    }
  });
  
  export const { setOtpUser } = userOtpSlice.actions;
  
  export default userOtpSlice.reducer;