import React from "react";
export default function StepperControl({ handleClick, currentStep, steps }) {
  return (
    <div className="container mt-4 mb-8 flex justify-around">
      <button
        onClick={() => handleClick()}
        className={`cursor-pointer rounded-md border-2 bg-red-500 w-1/4 py-2 px-4 text-white transition duration-200 ease-in-out hover:bg-red-700 hover:text-white  ${
          currentStep === 1 ? " cursor-not-allowed opacity-50 " : ""
        }`}
      >
        Back
      </button>

      <button
        onClick={() => handleClick("next")}
        className="cursor-pointer rounded-md bg-red-500 py-2 px-4 w-1/4  text-white transition duration-200 ease-in-out hover:bg-red-700 hover:text-white"
      >
        {currentStep === steps.length - 1 ? "Submit" : "Next"}
      </button>
    </div>
  );
}
