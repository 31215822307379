import React,{useState} from "react";
import Header from '../Header';
import Footer from '../Footer';
import Home from "../Home"
import { useNavigate } from "react-router-dom";

const LoanRenewalCurrency = () => {
    
//   const [token, setToken] = useState()
//   const navigate = useNavigate();

//     if(!token){
//         alert("Either your session Expired or You have not Logged in !!! Please Log in first.")
//         return <Home setToken={setToken}/>
//     }
    return (
<div className='bg-white py-2 px-5 m-auto w-full'>
<Header />
<div className="px-20 pt-10 pb-10">
<div className="bg-gray-200 h-60 w-auto rounded-lg px-2 mt-2 flex flex-col justify-between">
        <h1 className="text-xl font-bold px-4 pt-4">
          Your Loan Application is still under review. A loan officer will get in touch with you.
        </h1>
        <a
          href="/Home"
          class="w-40 2xl:object-fill ml-4 mb-4 text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
        >
          Logout
        </a>
      </div>

</div>
<Footer />
</div>




    );
}

export default LoanRenewalCurrency;