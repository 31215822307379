/* eslint-disable import/no-anonymous-default-export */
import checkoutFormModel from './checkoutFormModel';
const {
  formField: {
    //personals details page
    Resedential_Address17,
    Postal_Address20,
    ID_No6,
    Mobile_No214,
    LandlineHome_phone16,
    Passport_No7,
    No_of_dependents9,
    profession_cd_Profession11,
    Nationality10,

    //emoployment details
    Employer1,
    Employer_Physic3,
    Job_Title2,
    Time_at_current7,
    Previous_compan8,
    Contract_Status9,
    Pay_Dayeg_25t12,
    Work_Phone_Number12,
    //banking deatails page
    Bank_Name1,
    Account_Name2,
    Branch_Name3,
    Bank_Branch_Code4,
    Account_Number5,
    Account_Type6,
    //references #spouse details

    TITLE_cd_Title1,
    First_Name2,
    Surname3,
    Maiden_Name4,
    Date_Of_Birth5,
    spouseID_No6,
    spousePassport_No7,
    Gender_cd_Gender8,
    spouseNo_of_dependents9,
    spouseNationality10,
    spouse_profession_cd_Profession11,
    Mobile_No12,
    Mobile_no213,
    Email_address14,
    Landline15,
    Residential_Address16,
    Postal_Address17,
    //references #next of keen details
    GuarantorRelationship_cd_relation_to_client,
    TITLE_cd_Title5,
    name,
    Surname6,
    Mobile_No8,
    Email_Address9,
    LandlineHomephone10,
    Residential_Address11,
    Employer_Physic12,
    Work_Phone_no13,
    Gender_cd_Gender19,
    dateOfBirth,
    ID_number17,
    Passport_number18,
    Nationality20,
    profession_cd_Profession21,
    Postal_Address22,
    Employer23,
    Mobile_Number24,
    Number_of_Dependency25,

    //references #details of relatives who dont stay with you
    relativesTITLE_cd_Title1,
    relativesFirst_Name2,
    relativesSurname3,
    Relationship4,
    Mobile_No6,
    Email_Address7,
    Home_Landline8,
    Resedential_Address9,
    Business_Address10,
    Occupation5,
    Work_phone_no11,
    //references #details of second relatives who dont stay with you
    First_Name21111,
    Surname31010,
    Relationship599,
    Occupation488,
    Mobile_No677,
    Email_address766,
    Home_landlineH855,
    Resedential_add4,
    Work_phone_no1033,

    //montly expenditure #####INCOME
    Gross_Incomepe1,
    Net_Income_per2,
    Other_Incomesp3,
    Total_IncomeNe4,
    Monthly_Rental1,
    Monthly_Bus_Fare2,
    Monthly_vehicle3,
    Monthly_school4,
    Monthly_Insurance7,
    Monthly_credit_8,
    Monthly_Groceries9,
    Monthly_Utiliti10,
    Other_monthly_e11,
    Net_IncomeTota12,
    //docs

    copyOfID,
    proofOfResidence,
    passportPhoto,
    bankStatement,
    payslip,
    nextOfKinID

  }
} = checkoutFormModel;

export default {
  //personal details 
  [Resedential_Address17.name]: '',
  [Postal_Address20.name]: '',
  [ID_No6.name]: '',
  [Mobile_No214.name]: '',
  [LandlineHome_phone16.name]: '',
  [Passport_No7.name]: '',
  [No_of_dependents9.name]: '',
  [profession_cd_Profession11.name]: '',
  [Nationality10.name]: '',

  ////employment details
  [Employer_Physic3.name]: '',
  [Employer1.name]: '',
  [Job_Title2.name]: '',
  [Time_at_current7.name]: '',
  [Previous_compan8.name]: '',
  [Contract_Status9.name]: '',
  [Pay_Dayeg_25t12.name]: '',
  [Work_Phone_Number12.name]: '',
  //banking details
  [Bank_Name1.name]: '',
  [Account_Name2.name]: '',
  [Branch_Name3.name]: '',
  [Bank_Branch_Code4.name]: '',
  [Account_Number5.name]: '',
  [Account_Type6.name]: '',
  //references #spouse details
  [TITLE_cd_Title1.name]: '',
  [First_Name2.name]: '',
  [Surname3.name]: '',
  [Maiden_Name4.name]: '',
  [Date_Of_Birth5.name]: '',
  [spouseID_No6.name]: '',
  [spousePassport_No7.name]: '',
  [Gender_cd_Gender8.name]: '',
  [spouseNo_of_dependents9.name]: '',
  [spouseNationality10.name]: '',
  [spouse_profession_cd_Profession11.name]: '',
  [Mobile_No12.name]: '',
  [Mobile_no213.name]: '',
  [Email_address14.name]: '',
  [Landline15.name]: '',
  [Residential_Address16.name]: '',
  [Postal_Address17.name]: '',
  //  //references #net of keen details

  [GuarantorRelationship_cd_relation_to_client.name]: '',
  [TITLE_cd_Title5.name]: '',
  [name.name]: '',
  [Surname6.name]: '',
  [Mobile_No8.name]: '',
  [Email_Address9.name]: '',
  [LandlineHomephone10.name]: '',
  [Residential_Address11.name]: '',
  [Employer_Physic12.name]: '',
  [Work_Phone_no13.name]: '',
  [Gender_cd_Gender19.name]: '',
  [dateOfBirth.name]: '',
  [ID_number17.name]: '',
  [Passport_number18.name]: '',
  [Nationality20.name]: '',
  [profession_cd_Profession21.name]: '',
  [Postal_Address22.name]: '',
  [Employer23.name]: '',
  [Mobile_Number24.name]: '',
  [Number_of_Dependency25.name]: '',
  //references # details of relatives who do not stay with you
  [relativesTITLE_cd_Title1.name]: '',
  [relativesFirst_Name2.name]: '',
  [relativesSurname3.name]: '',
  [Relationship4.name]: '',
  [Mobile_No6.name]: '',
  [Email_Address7.name]: '',
  [Home_Landline8.name]: '',
  [Resedential_Address9.name]: '',
  [Business_Address10.name]: '',
  [Occupation5.name]: '',
  [Work_phone_no11.name]: '',
  //references # details of relatives who dont stay with you
  [First_Name21111.name]: '',
  [Surname31010.name]: '',
  [Relationship599.name]: '',
  [Occupation488.name]: '',
  [Mobile_No677.name]: '',
  [Email_address766.name]: '',
  [Home_landlineH855.name]: '',
  [Resedential_add4.name]: '',
  [Work_phone_no1033.name]: '',

  //montly expenditure #####INCOME
  [Gross_Incomepe1.name]: '',
  [Net_Income_per2.name]: '',
  [Other_Incomesp3.name]: '',
  [Total_IncomeNe4.name]: '',
  [Monthly_Rental1.name]: '',
  [Monthly_Bus_Fare2.name]: '',
  [Monthly_vehicle3.name]: '',
  [Monthly_school4.name]: '',
  [Monthly_Insurance7.name]: '',
  [Monthly_credit_8.name]: '',
  [Monthly_Groceries9.name]: '',
  [Monthly_Utiliti10.name]: '',
  [Other_monthly_e11.name]: '',
  [Net_IncomeTota12.name]: '',
  //docs
  [copyOfID.name]: '',
  [proofOfResidence.name]: '',
  [passportPhoto.name]: '',
  [bankStatement.name]: '',
  [payslip.name]: '',
  [nextOfKinID.name]: '',
};