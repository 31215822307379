import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { InputField, SelectField } from "../FormFields";

export default function BankingDetails(props) {
  const {
    formField: {
      Bank_Name1,
      Account_Name2,
      Branch_Name3,
      Bank_Branch_Code4,
      Account_Number5,
      Account_Type6,
    },
  } = props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Banking Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Bank_Name1.name}
            label={Bank_Name1.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Account_Name2.name}
            label={Account_Name2.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Branch_Name3.name}
            label={Branch_Name3.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Bank_Branch_Code4.name}
            label={Bank_Branch_Code4.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Account_Number5.name}
            label={Account_Number5.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Account_Type6.name}
            label={Account_Type6.label}
            fullWidth
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
