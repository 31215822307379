import React from "react";
import Header from './Header';
import Footer from './Footer';
import DashboardContent from './Dashboardcontent';
import LoanStatus from './LoanStatus';
import Dash from './TestDash';

const Dashboard = () => {

   
  
    return (
        <div className='bg-white relative'>
            <Header />
            <DashboardContent />
            <LoanStatus />
            <Footer />
        </div>




    );
}

export default Dashboard;
