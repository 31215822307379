import React, { useState } from "react";
import Login from "./Login";
import Otp from "./VerifyOtp";
const StepForm = () => {
  const [state, setState] = useState({
    mobileNo: "",
    hash: "",
    otp: "",
    clientId: "",
  });

  const [step, setStep] = useState(1);

  const handleChange = (input) => (e) => {
    setState({ ...state, [input]: e.target.value });
  };
  const hashHandleChange = (hash) => {
    setState({ ...state, hash: hash });
  };
  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const { mobileNo, hash, otp, clientId } = state;
  const value = { mobileNo, hash, otp, clientId };

  switch (step) {
    case 1:
      return (
        <Login
          nextStep={nextStep}
          hashHandleChange={hashHandleChange}
          handleChange={handleChange}
          value={value}
        />
      );
    case 2:
      return (
        <Otp
          nextStep={nextStep}
          prevStep={prevStep}
          handleChange={handleChange}
          value={value}
        />
      );
    default:
      return (
        <Login nextStep={nextStep} handleChange={handleChange} value={value} />
      );
  }
};

export default StepForm;
