import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { InputField, SelectField } from "../../FormFields";

const titles = [
  {
    value: "363",
    label: "MR",
  },
  {
    value: "364",
    label: "MS",
  },
  {
    value: "365",
    label: "MRS",
  },
  {
    value: "366",
    label: "DR",
  },
];

const loanTypes = [
  {
    value: "four_months",
    label: "4 Months To Pay",
  },
  {
    value: "six_months",
    label: "6 Months To Pay",
  },
];
const professions = [
  {
    value: "369",
    label: "Doctor",
  },
  {
    value: "370",
    label: "Nurse",
  },
  {
    value: "371",
    label: "Teacher",
  },
  {
    value: "372",
    label: "Lawyer",
  },
  {
    value: "373",
    label: "Other",
  },
];

const gender = [
  {
    value: "218",
    label: "Male ",
  },
  {
    value: "219",
    label: "Female ",
  },
];

const relationships = [
  {
    value: 5,
    label: "Spouse",
  },
  {
    value: 6,
    label: "Parent",
  },
  {
    value: 7,
    label: "Sibling",
  },
  {
    value: 8,
    label: "Business Associate",
  },
  {
    value: 9,
    label: "other",
  },
];

export default function References(props) {
  const {
    formField: {
      //spouse details
      TITLE_cd_Title1,
      First_Name2,
      Surname3,
      Maiden_Name4,
      Date_Of_Birth5,
      spouseID_No6,
      spousePassport_No7,
      Gender_cd_Gender8,
      spouseNo_of_dependents9,
      spouseNationality10,
      spouse_profession_cd_Profession11,
      Mobile_No12,
      Mobile_no213,
      Email_address14,
      Landline15,
      Residential_Address16,
      Postal_Address17,
      //next of keen details
      GuarantorRelationship_cd_relation_to_client,
      TITLE_cd_Title5,
      name,
      Surname6,
      Mobile_No8,
      Email_Address9,
      LandlineHomephone10,
      Residential_Address11,
      Employer_Physic12,
      Work_Phone_no13,
      Gender_cd_Gender19,
      dateOfBirth,
      ID_number17,
      Passport_number18,
      Nationality20,
      profession_cd_Profession21,
      Postal_Address22,
      Employer23,
      Mobile_Number24,
      Number_of_Dependency25,
      //relatives who stay with you details
      relativesTITLE_cd_Title1,
      relativesFirst_Name2,
      relativesSurname3,
      Relationship4,
      Mobile_No6,
      Email_Address7,
      Home_Landline8,
      Resedential_Address9,
      Business_Address10,
      Occupation5,
      Work_phone_no11,
      //relatives who does not stay with you
      First_Name21111,
      Surname31010,
      Relationship599,
      Occupation488,
      Mobile_No677,
      Email_address766,
      Home_landlineH855,
      Resedential_add4,
      Work_phone_no1033,
    },
  } = props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Spouse's Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={TITLE_cd_Title1.name}
            label={TITLE_cd_Title1.label}
            data={titles}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={First_Name2.name}
            label={First_Name2.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Maiden_Name4.name}
            label={Maiden_Name4.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={Surname3.name} label={Surname3.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={spouseID_No6.name}
            label={spouseID_No6.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={spousePassport_No7.name}
            label={spousePassport_No7.label}
            fullWidth
            type=""
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Date_Of_Birth5.name}
            label={Date_Of_Birth5.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={spouseNo_of_dependents9.name}
            label={spouseNo_of_dependents9.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={Gender_cd_Gender8.name}
            label={Gender_cd_Gender8.label}
            data={gender}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={spouseNationality10.name}
            label={spouseNationality10.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={spouse_profession_cd_Profession11.name}
            label={spouse_profession_cd_Profession11.label}
            data={professions}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Mobile_No12.name}
            label={Mobile_No12.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Mobile_no213.name}
            label={Mobile_no213.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Landline15.name}
            label={Landline15.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Email_address14.name}
            label={Email_address14.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Residential_Address16.name}
            label={Residential_Address16.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            name={Postal_Address17.name}
            label={Postal_Address17.label}
            fullWidth
          />
        </Grid>
        {/* next of kin details //////-that is-///////////////////// */}

        <Grid item xs={12}>
          <h1 className="text-xl">Next Of Keen's Details</h1>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={TITLE_cd_Title5.name}
            label={TITLE_cd_Title5.label}
            data={titles}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={GuarantorRelationship_cd_relation_to_client.name}
            label={GuarantorRelationship_cd_relation_to_client.label}
            data={relationships}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={name.name} label={name.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={Surname6.name} label={Surname6.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Mobile_No8.name}
            label={Mobile_No8.label}
            fullWidth
            type=""
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Email_Address9.name}
            label={Email_Address9.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={LandlineHomephone10.name}
            label={LandlineHomephone10.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Residential_Address11.name}
            label={Residential_Address11.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Employer_Physic12.name}
            label={Employer_Physic12.label}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField
            name={Work_Phone_no13.name}
            label={Work_Phone_no13.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={Gender_cd_Gender19.name}
            label={Gender_cd_Gender19.label}
            data={gender}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={dateOfBirth.name}
            label={dateOfBirth.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={ID_number17.name}
            label={ID_number17.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Passport_number18.name}
            label={Passport_number18.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Nationality20.name}
            label={Nationality20.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={profession_cd_Profession21.name}
            label={profession_cd_Profession21.label}
            data={professions}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Postal_Address22.name}
            label={Postal_Address22.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Employer23.name}
            label={Employer23.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Mobile_Number24.name}
            label={Mobile_Number24.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Number_of_Dependency25.name}
            label={Number_of_Dependency25.label}
            fullWidth
          />
        </Grid>
        {/* Details of relatives who doesnt live with you */}
        {/* ////////////////////////////////////////////////////// */}
        <Grid item xs={12}>
          <h1 className="text-xl">Details Of Relatives Who Do Not Live with you</h1>
        </Grid>
  
        <Grid item xs={12} sm={6}>
          <SelectField
            name={relativesTITLE_cd_Title1.name}
            label={relativesTITLE_cd_Title1.label}
            data={titles}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={relativesFirst_Name2.name}
            label={relativesFirst_Name2.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={relativesSurname3.name}
            label={relativesSurname3.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Relationship4.name}
            label={Relationship4.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Mobile_No6.name}
            label={Mobile_No6.label}
            fullWidth
            type=""
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Email_Address7.name}
            label={Email_Address7.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Home_Landline8.name}
            label={Home_Landline8.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Business_Address10.name}
            label={Business_Address10.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Resedential_Address9.name}
            label={Resedential_Address9.label}
            fullWidth
          />
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <InputField
            name={Occupation5.name}
            label={Occupation5.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Work_phone_no11.name}
            label={Work_phone_no11.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <h1 className="text-xl">
            Details Of Second Relatives Who Does Not Live with you
          </h1>
        </Grid>
        
    
        <Grid item xs={12} sm={6}>
          <InputField
            name={First_Name21111.name}
            label={First_Name21111.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Surname31010.name}
            label={Surname31010.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Relationship599.name}
            label={Relationship599.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Occupation488.name}
            label={Occupation488.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Mobile_No677.name}
            label={Mobile_No677.label}
            fullWidth
            type=""
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Resedential_add4.name}
            label={Resedential_add4.label}
            fullWidth
            type=""
          />
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <InputField
            name={Email_address766.name}
            label={Email_address766.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Home_landlineH855.name}
            label={Home_landlineH855.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Work_phone_no1033.name}
            label={Work_phone_no1033.label}
            fullWidth
          />
     </Grid>
      </Grid>
    </React.Fragment>
  );
}
