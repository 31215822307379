import React from "react";
import { useStepperContext } from "../../StepperContext";

export default function Details() {
  const { userData, setUserData } = useStepperContext();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };
  return (
    <div class="max-w-2xl py-2 px-5 m-auto w-full">
       <h1 className="font-bold text-xl sm:text-2xl pb-4">Contact Details</h1>
      <div class="grid grid-cols-2 gap-4 m-auto">
      
        <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Email Address:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="email" class=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300" placeholder="Email Address" onChange={handleChange}
            value={userData["email"] || ""}
            name="email"
          />
        </div>

        <div class="col-span-2 lg:col-span-1">
          <label className="">Phone Number:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="text" class=" bg-white rounded-lg p-2 w-full focus:outline-none" placeholder="+263 777 777 777" onChange={handleChange}
            value={userData["phone1"] || ""}
            name="phone1"
          />
        </div>
        <div class="col-span-2 lg:col-span-1">
          <label className="">Phone Number 2:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="text" class=" bg-white rounded-lg p-2 w-full focus:outline-none" placeholder="+263 777 777 777" onChange={handleChange}
            value={userData["phone2"] || ""}
            name="phone2"
          />
        </div>

        <div class="col-span-2 lg:col-span-1">
          <label className="">House Number:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="text" class=" bg-white rounded-lg p-2 w-full focus:outline-none" placeholder="House Number" onChange={handleChange}
            value={userData["houseNumber"] || ""}
            name="houseNumber"
          />
        </div>

        <div class="col-span-2 lg:col-span-1">
          <label className="">Street Name:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="text" class=" bg-white rounded-lg p-2 w-full focus:outline-none" placeholder="Street Name" onChange={handleChange}
            value={userData["streetName"] || ""}
            name="streetName"
          />
        </div>

        <div class="col-span-2 lg:col-span-1">
          <label className="">Town / City:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="text" class=" bg-white rounded-lg p-2 w-full focus:outline-none" placeholder="Town / City" onChange={handleChange}
            value={userData["city"] || ""}
            name="city"
          />
        </div>


      </div>
    </div>
  );
}
