import React from "react";
import MaterialLayout from "../applyloan/Layout/MaterialLayout";
import CheckoutPage from "./../applyloan/ApplyLoan";

function Apply() {
  return (
    <MaterialLayout>
      <CheckoutPage />
    </MaterialLayout>
  );
}

export default Apply;
