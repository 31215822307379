import React, { useState } from "react";
import { FaBell } from "react-icons/fa";
import logoo from "../images/logoo.png";
import avatar from "../images/scharakupa.jpg";
import Home from "./Home"
import axios from "axios";
import { FiLogOut } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Header = () => {

  const userData = useSelector((state) => state.user)
  const user = userData.user
  const navigate = useNavigate();

  const logout = () => {

    //window.location.reload();
    navigate("/")

  };

  return (
    <div className="">
      {/* <div className="flex justify-between  bg-white border-gray-200 px-4 pt-2">
        <div class="flex items-center">
          <a href="/" class="text-xl font-bold flex items-center lg:ml-2.5">
            <img src={logoo} class="h-14 mr-2" alt="Logo" />
          </a>
        </div>
        <div class="flex items-center">
          <div className="pr-2">
            <div class=" justify-center items-center text-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900">
              8
            </div>

            <FaBell />
          </div>

          <a href="/profile">
            <div class="avatar">
              <div class="w-10 ">
                <img src={avatar} className="rounded-full" alt="userAvatar" />
              </div>
            </div>


            <div className="flex flex-col">
              <h2 className="pl-2 text-sm">WELCOME</h2>
              <h2 className="pl-2 font-bold"> {user ? user.displayName : "Development Mode"}</h2>
            </div>
          </a>
          <div className="flex  pl-2 pr-10">
            <button
              onClick={logout}
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
            >
              <span>Logout</span>
              <span>...</span>
              <FiLogOut />
            </button>
          </div>
        </div>
      </div> */}

      <div className="flex justify-between  bg-white border-gray-200 px-4 pt-2">
        <div class="flex items-center">
          <a href="/" class="text-xl font-bold flex items-center lg:ml-2.5">
            <img src={logoo} class="h-14 mr-2" alt="Logo" />
          </a>
        </div>
        <div class="flex items-center">
          <div className="pr-2">
            <div class=" justify-center items-center text-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900">
              8
            </div>

            <FaBell />
          </div>

          <a href="/profile" className="flex items-center">
            <div class="avatar">
              <div class="w-10 ">
                <img src={avatar} className="rounded-full" alt="userAvatar" />
              </div>
            </div>

            <div className="flex flex-col items-center pl-2">
              <h2 className="text-sm">WELCOME</h2>
              <h2 className="font-bold">{user ? user.displayName : "Development Mode"}</h2>
            </div>
          </a>
          
          <div className="flex pl-2 pr-10">
            <button
              onClick={logout}
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
            >
              <span>Logout</span>
              <span>...</span>
              <FiLogOut />
            </button>
          </div>
        </div>
      </div>


      <div class="flex justify-center ">
        <div class="mt-4 sm:w-3/12 md:w-3/12 lg:w-3/12 2xl:w-3/12 xl:w-3/12 ">
          <div class="relative text-center flex flex-col min-w-0 break-words  bg-gray-100  rounded mb-6 xl:mb-0">
            <div class="flex p-4 ">
              <div class="relative w-full pr-4 max-w-full flex-grow flex-1 items-center">
                <a href="/dashboard">
                  <h5 class="text-blueGray-400 uppercase font-bold text-xs">
                    Dashboard
                  </h5>
                </a>
              </div>
              <div class="relative w-full pr-4 max-w-full flex-grow flex-1 items-center">
                <a href="/currency">
                  <h5 class=" text-blueGray-400 uppercase font-bold text-xs">
                    Loans
                  </h5>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header
