import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import ProfileDetails from "../profile/ProfileDetails";
import ProfileModify from "../profile/ProfileModify";
import avatar from "../images/scharakupa.jpg";
import axios from "axios";



const Profile = () => {
    const userData = useSelector((state) => state.user)
    const user = userData.user
    console.log(user)
  

    const location = useLocation();

    // let apiHeaders = {
    //     Authorization:
    //       "Basic " + Buffer.from("webuser:Demo@#2022").toString("base64"),
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //     Allow_Methods: ["HEAD, GET, POST, PUT, PATCH, DELETE, OPTION"],
    //     Allow_Origin: ["*"],
    //     "Access-Control-Allow-Credentials": true,
    //     "Access-Control-Allow-Origin": "*",
    //     "x-api-key": "oHJ0G18QYt2SMVNpogzio8WttytilwE17tuAky7p",
    //     "X-Fineract-Platform-TenantId": "clubplus",
    //   };

    // useEffect(() => {
    //     axios.get(`https://api.demo.irl.musoniservices.com/v1/datatables/cct_PERSONALDETAILS/${user.id}`,{
    //         headers: {
    //             Authorization:
    //               "Basic " + Buffer.from("webuser:Demo@#2022").toString("base64"),
    //             "Content-Type": "application/json",
    //             Accept: "application/json",
    //             Allow_Methods: ["GET"],
    //             Allow_Origin: ["*"],
    //             "Access-Control-Allow-Credentials": true,
    //             "Access-Control-Allow-Origin": "https://clubplus-backend.onrender.com",
    //             "x-api-key": "oHJ0G18QYt2SMVNpogzio8WttytilwE17tuAky7p",
    //             "X-Fineract-Platform-TenantId": "clubplus",
    //           }
    //     })
    //     .then((response) =>console.log(response.data))
    //     .catch((error)=> console.log(error))

    // },[])

    // const handleNewUserData = async (updatedUserData) => {
    //     axios({
    //         method: "PUT",
    //         url: `https://demo.irl.musoniservices.com:8443/api/v1/clients/${user.id}`,
    //         data: updatedUserData,
    //         mode: "cors",
    //         withCredentials: true,
    //         credentials: "origin",
    //         headers: apiHeaders,
    //       })
    //         .then(async function (response) {
    //           console.log(response.data);
    //         })
    //         .catch(async function (error) {
    //           console.log(error);  
    //         });
    // }

    return (
        <div className='bg-white relative'>
            <Header/>
            <div className="lg:flex justify-center">
                { location.pathname === "/profile" ? <ProfileDetails user={user} avatar={avatar}/> : <ProfileModify user={user} />}
            </div>    
            <Footer />
        </div>
    );
}

export default Profile;