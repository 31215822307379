import React from "react";
import { useState } from "react";
import Stepper from "./renew-loan-contexts/components/Stepper";
import StepperControl from "./renew-loan-contexts/components/StepperControl";
import { UseContextProvider } from "./renew-loan-contexts/StepperContext";

import PersonalDetails from "./renew-loan-contexts/components/steps/PersonalDetails";
import Complete from "./renew-loan-contexts/components/steps/Complete";
import Header from "./Header";
import Footer from "./Footer";
import MonthlyExpenditure from "./renew-loan-contexts/components/steps/MonthlyExpenditure";
import UploadDocuments from "./renew-loan-contexts/components/steps/UploadDocuments";

const LoanRenewal = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const steps = [

    "Personal Details",
    
    "Monthly Expenditure",
    "Upload Documents",
    "Complete"
  ];

  const displayStep = (step) => {

    switch (step) {
      case 1:
        return <PersonalDetails />;
      case 2:
        return <MonthlyExpenditure />;
      case 3:
        return <UploadDocuments />;
      case 4:
        return <Complete />;
      default:
    }
  };

  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  return (
    <div className="bg-white">
      <Header />
      <div className="mx-auto rounded-lg w-3/4 bg-gray-100 pb-2 max-w-2xl py-10 px-5 m-auto mt-10">
        <h1 className="font-bold text-xl sm:text-2xl text-center">Loan TopUp /Renewal </h1>
        {/* Stepper */}
        <div className="horizontal container mt-5 ">
          <Stepper steps={steps} currentStep={currentStep} />

          <div className="my-10 p-2 w-full flex flex-col">
            <UseContextProvider>{displayStep(currentStep)}</UseContextProvider>
          </div>
        </div>

        {/* navigation button */}
        {currentStep !== steps.length && (
          <StepperControl
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps}
          />
        )}
      </div>
      <Footer />
    </div>
  );
}

export default LoanRenewal;

// const LoanRenewal = () => {
//   return (
//     <div className="text-black">
//       hi there
//     </div>
//   )
// }
// export default LoanRenewal;
