import React from "react";
import { useState } from "react";
import otp1 from "../../images/otp.png";
import logoo from "../../images/logoo.png";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {useSearchParams} from "react-router-dom";
import PropTypes from 'prop-types'

async function LoginUser(credentials) {
  return fetch('https://localhost:3001/login',{
      method: 'POST',
      header: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
  }).then(data => data.json())
}


const Otp = ({setToken}) => {
  
    const [searchparams] = useSearchParams();
    console.log(searchparams.get("id"));
    const [apiError, setApiError] = useState(null);
    const [apiSuccess, setApiSuccess] = useState(null);
    const [visible, setIsVisible] = useState(true);
   // const dispatch = useDispatch();
   const ver_id = searchparams.get("id");

    const [otp, setotp] = useState('');
    const userData = useSelector((state) => state.user)
    const user = userData.user
    const clientType = user.clientType
  //console.log(user);




  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(otp);
  }
    

  if (apiError != null) {
    setTimeout(function () {
      setIsVisible(false);
    }, 5000);
  }

  const checkCode = (e)=>{
    axios.post("https://rest.bluedotsms.com/api/VerifyStatus", {
      "api_id": "API5917286622",
      "api_password": "Password1",
       "sender_id": "CLUBPLUS",
       "verfication_id": ver_id,
       "brand": "CLUBPLUS",
       "sms_type": "P",
       "encoding": "T",
        "verfication_code": otp,
        "templateid": null,
        "V1": null,
        "V2": null,
        "V3": null,
        "V4": null,
        "V5": null
    })
    .then(function (response) {
      console.log(response);
      if(response.data.remarks==="Verification Code Verfied Sucessfully"){
        //otp verified true
        //assign user dashboard according to application status
        if(user.status==="Active"){
          //navigate user to dashboard//
          navigate({
            pathname: "/Dashboard"
    });
  
        }else if (!clientType.active){
         // user not yet verified but profiled
          navigate({
            pathname: "/pending-verify"
    });
          
        }
        else {
         /*  //user not yet profiled 
          select currenct for profiling// */
          navigate({
            pathname: "/select-currency"
    });

        }

      }else{
        //message verification failed
        setApiError("OTP Verification Failed");
        console.log(response);
      }
    })
    .catch(function (error) {
      console.log(error);
      alert(error);
    });
  }
  const Back = (e) => {
    e.preventDefault();
    navigate({
      pathname: "/Login"
});

  };
  return (
    <div className="container">
      <div className="flex h-screen bg-gray-100 md:bg-gray-100">
        <div className="hidden lg:flex h-full">
          <img src={otp1} className="mx-auto" alt="clubplus" />
        </div>
        <div className="w-full flex items-center justify-center lg:w-1/2 ">
          <div class="max-w-2xl mx-auto">
            <div class="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm p-4 sm:p-6 lg:p-8 dark:bg-gray-800 dark:border-gray-700">
              <form class="space-y-6" action="#" onSubmit={handleSubmit}>
                <a href="/">
                  <img src={logoo} className="mx-auto" alt="clubplus" />
                </a>
                <h3 class="text-xl text-black font-bold dark:text-white text-center">
                  Check OTP Sent to your email/phone to login
                </h3>

                {apiError && visible && (
                  <div
                    className="bg-red-100 rounded-lg py-3 px-6 text-base text-red-700 mb-3"
                    role="alert"
                  >
                    {<p>{apiError}</p>}
                  </div>
                )}

                <input
                         type="text"
                         id="otp"
                         name="otp"
                         value={otp}
                         onChange={(event) =>
                           setotp(event.target.value)}
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-500 focus:outline-none focus:border-gray-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  placeholder="Enter OTP"
                />
                <div className="flex space-x-4">
                  <button
                    type="submit"
                    onClick={Back}
                    class="w-full text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-md text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    onClick={checkCode}
                    class="w-full text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-md text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Verify
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
Otp.propTypes = {
  setToken: PropTypes.func.isRequired
}

export default Otp;
