import { Grid } from "@material-ui/core";
import { FileInputField } from "../FormFields";
import React, { useState, setState } from "react";
// import axios from "axios";
// import { Buffer } from "buffer";
// import { reactLocalStorage } from "reactjs-localstorage";

const UploadDocuments = (props) => {
  const {
    formField: { 
      copyOfID,
      proofOfResidence,
      passportPhoto,
      bankStatement,
      payslip,
      nextOfKinID},
  } = props;

  return (
    <div class="max-w-2xl py-2 px-5 m-auto w-full">
      <h1 className="font-extrabold text-xl sm:text-2xl pb-4 text-center">
        Upload Documents
      </h1>

      <div className=" grid grid-cols-2 gap-4 m-auto">
    
        <div className="col-span-2 lg:col-span-1 flex flex-col items-center ju">
          <h1 className="font-bold w-1/2 pb-4 text-center">Copy Of ID</h1>
          <label className=" w-auto pb-4 text-center text-xs text-red-500">
            UPLOAD in JPG/PNG/PDF/XML format
          </label>

          <label class="block bg-gray-300 pl-4 py-8 rounded-lg">
            <span class="sr-only bg-black">Choose File</span>
            <FileInputField
              name={copyOfID.name}
              label={copyOfID.label}
              class="block w-full text-sm text-white file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300"
            />
          </label>
        </div>
        <div className="col-span-2 lg:col-span-1 flex flex-col items-center ju">
          <h1 className="font-bold w-1/2 pb-4 text-center">Proof Of Residence</h1>
          <label className=" w-auto pb-4 text-center text-xs text-red-500">
            UPLOAD in JPG/PNG/PDF/XML format
          </label>

          <label class="block bg-gray-300 pl-4 py-8 rounded-lg">
            <span class="sr-only bg-black">Choose File</span>
            <FileInputField
              name={proofOfResidence.name}
              label={proofOfResidence.label}
              class="block w-full text-sm text-white file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300"
            />
          </label>
        </div>
        <div className="col-span-2 lg:col-span-1 flex flex-col items-center ju">
          <h1 className="font-bold w-1/2 pb-4 text-center">Passport Photo</h1>
          <label className=" w-auto pb-4 text-center text-xs text-red-500">
            UPLOAD in JPG/PNG/PDF/XML format
          </label>

          <label class="block bg-gray-300 pl-4 py-8 rounded-lg">
            <span class="sr-only bg-black">Choose File</span>
            <FileInputField
              name={passportPhoto.name}
              label={passportPhoto.label}
              class="block w-full text-sm text-white file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300"
            />
          </label>
        </div>
        <div className="col-span-2 lg:col-span-1 flex flex-col items-center ju">
          <h1 className="font-bold w-1/2 pb-4 text-center">FCA Bank Statement</h1>
          <label className=" w-auto pb-4 text-center text-xs text-red-500">
            UPLOAD in JPG/PNG/PDF/XML format
          </label>

          <label class="block bg-gray-300 pl-4 py-8 rounded-lg">
            <span class="sr-only bg-black">Choose File</span>
            <FileInputField
              name={bankStatement.name}
              label={bankStatement.label}
              class="block w-full text-sm text-white file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300"
            />
          </label>
        </div>
        <div className="col-span-2 lg:col-span-1 flex flex-col items-center ju">
          <h1 className="font-bold w-1/2 pb-4 text-center">FCA Payslip</h1>
          <label className=" w-auto pb-4 text-center text-xs text-red-500">
            UPLOAD in JPG/PNG/PDF/XML format
          </label>
          <label class="block bg-gray-300 pl-4 py-8 rounded-lg">
            <span class="sr-only bg-black">Choose File</span>
            <FileInputField
              name={payslip.name}
              label={payslip.label}
              class="block w-full text-sm text-white file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300"
            />
          </label>
        </div>
        <div className="col-span-2 lg:col-span-1 flex flex-col items-center">
          <h1 className="font-bold w-1/2 pb-4 text-center">Next Of Kin ID</h1>
          <label className=" w-auto pb-4 text-center text-xs text-red-500">
            UPLOAD in JPG/PNG/PDF/XML format
          </label>

          <label class="block bg-gray-300 pl-4 py-8 rounded-lg">
            <span class="sr-only bg-black">Choose File</span>
            <FileInputField
              name={nextOfKinID.name}
              label={nextOfKinID.label}
              class="block w-full text-sm text-white file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300"
            />
          </label>
        </div>
      </div>
      <div className="flex flex-col items-center justfy-center">
        <h1 className="font-extrabold text-xl sm:text-2xl pt-4 text-center">
          Download Documents
        </h1>

        <div className="bg-gray-300 h-10 mt-4 w-3/4 rounded-sm flex justify-between items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-8 ml-2 h-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
            />
          </svg>

          <h2 className="ml-4 hidden lg:flex">Loan Application</h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-8 h-8 mr-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
            />
          </svg>
        </div>

        <div className="bg-gray-300 h-10 mt-4 w-3/4 rounded-sm flex justify-between items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-8 ml-2 h-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
            />
          </svg>

          <h2 className="ml-4 hidden lg:flex">Loan Application</h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-8 h-8 mr-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
            />
          </svg>
        </div>

        <div className="bg-gray-300 h-10 mt-4 w-3/4 rounded-sm flex justify-between items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-8 ml-2 h-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
            />
          </svg>

          <h2 className="ml-4 hidden lg:flex">Loan Application</h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-8 h-8 mr-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
            />
          </svg>
        </div>
      </div>

      {/* <div class="flex justfy-center items-center ">
        <h1 className="font-bold w-1/2 pb-4 text-center ">
          Proof Of Residence
        </h1>
      </div>
      <div class="flex justfy-center items-center">
        <label className=" w-1/2 pb-4 text-center text-xs text-red-500">
          UPLOAD in JPG/PNG/PDF/XML format
        </label>
        <label className="w-1/2 pb-4 text-center text-xs text-red-500">
          UPLOAD in JPG/PNG/PDF/XML format
        </label>
      </div>

      <div class="flex space-x-6 justfy-center items-center">
        <label class="block bg-white pl-4 py-8 rounded-lg">
          <span class="sr-only bg-black">Choose File</span>
          <input
            type="file"
            class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300"
          />
        </label>
        <label class="block bg-white pl-4 py-8 rounded-lg">
          <span class="sr-only bg-black">Choose File</span>
          <input
            type="file"
            class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300"
          />
        </label>
      </div>
      <div class="flex justfy-center items-center">
        <h1 className="font-bold w-1/2 pb-4 text-center">Copy Of ID</h1>
        <h1 className="font-bold w-1/2 pb-4 text-center ">
          Proof Of Residence
        </h1>
      </div>
      <div class="flex justfy-center items-center">
        <label className=" w-1/2 pb-4 text-center text-xs text-red-500">
          UPLOAD in JPG/PNG/PDF/XML format
        </label>
        <label className="w-1/2 pb-4 text-center text-xs text-red-500">
          UPLOAD in JPG/PNG/PDF/XML format
        </label>
      </div>

      <div class="flex space-x-6 justfy-center items-center">
        <label class="block bg-white pl-4 py-8 rounded-lg">
          <span class="sr-only bg-black">Choose File</span>
          <input
            type="file"
            class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300"
          />
        </label>
        <label class="block bg-white pl-4 py-8 rounded-lg">
          <span class="sr-only bg-black">Choose File</span>
          <input
            type="file"
            class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300"
          />
        </label>
      </div>
      <div class="flex justfy-center items-center">
        <h1 className="font-bold w-1/2 pb-4 text-center">Copy Of ID</h1>
        <h1 className="font-bold w-1/2 pb-4 text-center ">
          Proof Of Residence
        </h1>
      </div>
      <div class="flex justfy-center items-center">
        <label className=" w-1/2 pb-4 text-center text-xs text-red-500">
          UPLOAD in JPG/PNG/PDF/XML format
        </label>
        <label className="w-1/2 pb-4 text-center text-xs text-red-500">
          UPLOAD in JPG/PNG/PDF/XML format
        </label>
      </div>

      <div class="flex space-x-6 justfy-center items-center">
        <label class="block bg-white pl-4 py-8 rounded-lg">
          <span class="sr-only bg-black">Choose File</span>
          <input
            type="file"
            class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300"
          />
        </label>
        <label class="block bg-white pl-4 py-8 rounded-lg">
          <span class="sr-only bg-black">Choose File</span>
          <input
            type="file"
            class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300"
          />
        </label>
      </div>
      <div class="flex justfy-center items-center">
        <h1 className="font-bold w-1/2 pb-4 text-center">Copy Of ID</h1>
        <h1 className="font-bold w-1/2 pb-4 text-center ">
          Proof Of Residence
        </h1>
      </div>
      <div class="flex justfy-center items-center">
        <label className=" w-1/2 pb-4 text-center text-xs text-red-500">
          UPLOAD in JPG/PNG/PDF/XML format
        </label>
        <label className="w-1/2 pb-4 text-center text-xs text-red-500">
          UPLOAD in JPG/PNG/PDF/XML format
        </label>
      </div>

      <div class="flex space-x-6 justfy-center items-center">
        <label class="block bg-white pl-4 py-8 rounded-lg">
          <span class="sr-only bg-black">Choose File</span>
          <input
            type="file"
            class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300"
          />
        </label>
        <label class="block bg-white pl-4 py-8 rounded-lg">
          <span class="sr-only bg-black">Choose File</span>
          <input
            type="file"
            class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300"
          />
        </label>
      </div> */}

      {/*
      <FileUploader handleChange={handleChange} name="file" types={fileTypes}/>

      <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
         */}
    </div>
  );
};

export default UploadDocuments;
