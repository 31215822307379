import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
} from "@material-ui/core";
import useStyles from "./styles";
import formField from "../FormModel/checkoutFormModel";

function MonthlyExpenditure(props) {
  const { formValues } = props;

  const classes = useStyles();

  JSON.stringify(formField);
  //console.log(formValues);
  formValues.Total_IncomeNe4 =
    parseInt(formValues.Net_Income_per2) + parseInt(formValues.Other_Incomesp3);
  formValues.Net_IncomeTota12 =
    parseInt(formValues.Monthly_Rental1) +
    parseInt(formValues.Monthly_Bus_Fare2) +
    parseInt(formValues.Monthly_vehicle3) +
    parseInt(formValues.Monthly_school4) +
    parseInt(formValues.Monthly_Insurance7) +
    parseInt(formValues.Monthly_credit_8) +
    parseInt(formValues.Monthly_Groceries9) +
    parseInt(formValues.Monthly_Utiliti10) +
    parseInt(formValues.Other_monthly_e11);

  return (
    <List>
      <ListItem className={classes.listItem}>
        <div className="flex flex-col">
          <h3 className="uppercase font-bold test-2xl text-center py-4 bg-gray-200 rounded-lg">
            {" "}
            Monthly Expenditure
          </h3>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <h4 className="font-bold uppercase bg-gray-100 text-center py-2 my-2 rounded-lg">
                {" "}
                1: Income
              </h4>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Gross_Incomepe1.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Gross_Incomepe1
                  ? formValues.Gross_Incomepe1
                  : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Net_Income_per2.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Net_Income_per2
                  ? formValues.Net_Income_per2
                  : "Not provided"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              {" "}
              <ListItemText
                primary={formField.formField.Other_Incomesp3.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.Other_Incomesp3
                  ? formValues.Other_Incomesp3
                  : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <ListItemText
                primary={formField.formField.Total_IncomeNe4.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.Total_IncomeNe4
                  ? formValues.Total_IncomeNe4
                  : "Not provided"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <h4 className="font-bold uppercase bg-gray-100 text-center py-2 rounded-lg">
                {" "}
                2: Fixed Monthly Expenditure
              </h4>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <ListItemText
                primary={formField.formField.Monthly_Rental1.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.Monthly_Rental1
                  ? formValues.Monthly_Rental1
                  : "Not provided"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              {" "}
              <ListItemText
                primary={formField.formField.Monthly_Bus_Fare2.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.Monthly_Bus_Fare2
                  ? formValues.Monthly_Bus_Fare2
                  : "Not provided"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Monthly_vehicle3.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.Monthly_vehicle3
                  ? formValues.Monthly_vehicle3
                  : "Not provided"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              {" "}
              <ListItemText
                primary={formField.formField.Monthly_school4.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.Monthly_school4
                  ? formValues.Monthly_school4
                  : "Not provided"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              {" "}
              <ListItemText
                primary={formField.formField.Monthly_Insurance7.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Monthly_Insurance7
                  ? formValues.Monthly_Insurance7
                  : "Not provided"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              {" "}
              <ListItemText
                primary={formField.formField.Monthly_credit_8.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Monthly_credit_8
                  ? formValues.Monthly_credit_8
                  : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <ListItemText
                primary={formField.formField.Monthly_Groceries9.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Monthly_Groceries9
                  ? formValues.Monthly_Groceries9
                  : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <ListItemText
                primary={formField.formField.Monthly_Utiliti10.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Monthly_Utiliti10
                  ? formValues.Monthly_Utiliti10
                  : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <ListItemText
                primary={formField.formField.Other_monthly_e11.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Other_monthly_e11
                  ? formValues.Other_monthly_e11
                  : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <ListItemText
                primary={formField.formField.Net_IncomeTota12.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Net_IncomeTota12
                  ? formValues.Net_IncomeTota12
                  : "Not provided"}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </ListItem>
    </List>
  );
}

export default MonthlyExpenditure;
