import * as yup from "yup";

// const PASSWORD_REGEX =
//   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/;
// const MOBILE_NO_REGEX = /^(\+[\d]{1,5}|0)?[7-9]\d{9}$/;
const MOBILE_NO_REGEX = /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

// const ID_NUMBER_REGEX = /\b\w([.^\S]*)\w*\b/;

export const validationSchema = yup.object({
  firstname: yup
    .string()
    .min(3, "Please enter your real name")
    .required("First name is required"),
  lastname: yup
    .string()
    .min(3, "Please enter your real lastname")
    .required("Last name is required"),
  emailAddress: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email address is required"),
  mobileNo: yup
    .string()
    .matches(MOBILE_NO_REGEX, "Please enter a valid phone number")
    .required("Mobile number is required"),
  middlename: yup
    .string(),
  genderId: yup
  .string()
  .required("Gender is required"),
  mobileNoSecondary: yup
  .string()
  .matches(MOBILE_NO_REGEX, "Please enter a valid phone number")

  // idNumber: yup
  //   .string()
  //   .matches(ID_NUMBER_REGEX, "Please enter a valid identification number")
  //   .required("Identification number is required"),
  // password: yup
  //   .string()
  //   .matches(
  //     PASSWORD_REGEX,
  //     "Password should be minimum 6 characters, at least one letter, one number and one special character"
  //   )
  //   .required("Password number is required"),
  // confirmPassword: yup
  //   .string()
  //   .when("password", {
  //     is: (val) => (val && val.length > 0 ? true : false),
  //     then: yup
  //       .string()
  //       .oneOf([yup.ref("password")], "Password does not match"),
  //   })
  //   .required("Confim password is required"),
});

