import React, {useState} from 'react';


const ProfileModify = ({user, onUpdate}) => {

  const [title, setTitle] = useState(user.title);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [middleName, setMiddleName] = useState(user.middleName);
  const [idNumber, setIdNumber] = useState(user.idNumber);
  const [physicalAddress, setPhysicalAddress] = useState(user.physicalAddress);
  const [profilePicture, setProfilePicture] = useState(user.profilePicture);
  const [newPassword, setNewPassword] = useState(user.newPassword);
  const [confirmPassword, setConfirmPassword] = useState(user.confirmPassword);

  const onSubmit = (e) => {
    e.preventDefault();

    const user = {title, firstName, middleName, lastName, idNumber, physicalAddress, profilePicture, newPassword, confirmPassword}

    onUpdate(user)

  }
  
  return (
      <div>
        <div className="bg-gray-100 w-auto rounded-lg p-10 mt-6">
          <h2 className="text-3xl font-bold mb-8 text-center">Modify Profile</h2>
          <form action={onSubmit} className="flex flex-col">
          <div className="grid grid-cols-2 gap-4 mt-2">
              <label>Title: </label>
              <select name="title" value={title} onChange={(e) => setTitle(e.target.value)}>
                  <option value="Mr">Mr</option>
                  <option value="Ms">Ms</option>
                  <option value="Miss">Miss</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Dr">Dr</option>
                  <option value="Prof">Prof</option>
              </select>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-2">
              <label>First Name: </label>
              <input className="pl-2" type="text" name="firstname" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-2">
              <label>Middle Name: </label>
              <input className="pl-2" type="text" name="middlename" placeholder="Middle Name" value={middleName} onChange={(e) => setMiddleName(e.target.value)}/>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-2">
              <label>Last Name: </label>
              <input className="pl-2" type="text" name="lastname" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)}/>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-2">
              <label>ID Number: </label>
              <input className="pl-2" type="text" name="idnumber" placeholder="ID Number" value={idNumber} onChange={(e) => setIdNumber(e.target.value)}/>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-2">
              <label>Physical Address: </label>
              <input className="pl-2" type="text" name="address" placeholder="Physical Address" value={physicalAddress} onChange={(e) => setPhysicalAddress(e.target.value)}/>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-2">
              <label>Updated Profile Picture: </label>
              <div class="flex flex-col items-center justify-center h-28 rounded-md bg-white p-10">
                <div class="flex flex-col items-center justify-center">
                  <p>Drag & drop image to upload</p>
                  or
                </div>
                <label class="bg-gray-100 px-4 py-2 rounded-md cursor-pointer hover:bg-gray-300">
                  Select file
                  <input type="file" className="hidden" name="profile-picture" accept=".jpg,.jpeg,.png,.gif" value={profilePicture} onChange={(e) => setProfilePicture(e.target.value)}/>
                </label>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-2">
              <label>New Password: </label>
              <input className="pl-2" type="password" name="new-password1" placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-2">
              <label>Confirm Password: </label>
              <input className="pl-2" type="password" name="confirm-password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
            </div>
          <div className="pt-5 mt-20 ml-auto">
            <input className="min-w-[197px] text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-md text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="submit" value="Update Profile" />
          </div>
          </form>
        </div>
      </div>
  );
}
 
export default ProfileModify;