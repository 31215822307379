import React from "react";
//import logo from '../images/u6.svg';
import "../index.css"
import logoo from '../images/logoo.png';

function App() {
    return (
        <div class="min-h-screen  bg-gray-100 flex flex-col justify-center sm:py-12 ">
            <div class="p-10 mx-auto md:w-full md:max-w-md">

                <div class="bg-white shadow w-full rounded-lg ml-5 ">

                    <img src={logoo} className="mx-auto p-8" alt="clubplus" />
                    <h2 className="text-2xl md:text-4xl text-center font-bold">Welcome To </h2>

                    <h2 className="text-2xl md:text-4xl text-center font-bold">Loan Management System</h2>
                    <div className="flex flex-col items-center pb-10">
                        <a href="/Login">
                    <div className="pt-5">
                            <button type="submit" class="min-w-[197px] text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-md text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Login</button>

                        </div>
                        </a>
                        <h2 className="text-center pt-10 font-bold ">New Customer? Register here</h2>
                        <a href="/register">
                    <div className="pt-5">
                            <button type="submit" class="min-w-[197px] text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-md text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Register</button>

                        </div>
                        
                    </a>
                        
                    </div>



                </div>
            </div>
        </div>


        //     <div className="min-h-screen flex flex-col text-white items-center">
        //         <main className="container mx-auto px-6 pt-5 flex-1 text-center">
        //             <h2 className="text-2xl md:text-4xl lg:text-6xl uppercase">Welcome to</h2>
        //             <section class="container mx-auto pb-10 flex pt-5">
        //                 <img src={logo} className="mx-auto" alt="clubplus" />
        //             </section>

        //             <h2 className="text-2xl md:text-4xl lg:text-6xl uppercase">Loan Management System</h2>

        //             <p className="text-base md:text-lg lg:text-2xl mb-8 pt-5"> A digital platform that helps automate every stage of the loan lifecycle</p>

        //             <div className="text-lg md:text-2xl md:rounded-br-none md:rounded-bl-none rounded-bl-none lg:text-3xl py-2 px-4 md:py-4 md:px-10 lg:py-6 lg:px-12 bg-white bg-opacity-10 w-fit mx-auto mb-8 rounded-full">
        //                 Over 943,245 members
        //             </div>
        //             <div className="flex flex-col md:flex-row justify-center mb-4">
        //                 <a href="/register">
        //                     <input
        //                         type="submit"
        //                         value="Register"
        //                         name="register"

        //                         className="bg-primary md:rounded-tl-none md:rounded-tr-none rounded-full text-2xl py-4 px-6 md:px-10 lg:py-6 lg:px-12 font-bold uppercase cursor-pointer hover:opacity-75 duration-150"
        //                     />
        //                 </a>

        //                 <br />       <a href="/login">
        //                     <input
        //                         type="submit"
        //                         value="Login"
        //                         name="login"

        //                         className="bg-primary md:rounded-tr-none rounded-full text-2xl py-4 px-6 md:px-10 lg:py-6 lg:px-12 font-bold uppercase cursor-pointer hover:opacity-75 duration-150"
        //                     />
        //                 </a>

        //             </div>
        //         </main>


        // 		<footer className="container mx-auto p-6">
        // 			<div className="flex flex-col md:flex-row items-center justify-between">
        //             <p>Crafted with 💖 by <a href="https://www.webentangled.com/">Web Entangled</a></p>

        // 				<div className="flex -mx-6">
        // 					<a href="#" className="mx-3 hover:opacity-80 duration-150">About us</a> | 
        // 					<a href="#" className="mx-3 hover:opacity-80 duration-150">Privacy</a> | 
        // 					<a href="#" className="mx-3 hover:opacity-80 duration-150">Contact</a>
        // 				</div>
        // 			</div>
        // 		</footer>
        //     </div>
    );
}

export default App;
