import React from "react";
import { useStepperContext } from "../../StepperContext";

export default function BankingDetails() {
  const { userData, setUserData } = useStepperContext();

  const handleChange = (e) => {
   
    setUserData( e.target.files);
    
    console.log(userData)
  };
  return (
    <div class="max-w-2xl py-2 px-5 m-auto w-full">
       <h1 className="font-bold text-xl sm:text-2xl pb-4">Banking Details</h1>
      <div class="grid grid-cols-2 gap-4 m-auto">

        <div class="col-span-2 lg:col-span-1">
          <label className="">Bank Name:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="file" class=" bg-white rounded-lg p-2 w-full focus:outline-none" placeholder="Bank Name" onChange={handleChange}
            value={userData["bankName"] || ""}
            name="bankName"
          />
        </div>

        <div class="col-span-2 lg:col-span-1">
          <label className="">Account Type:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="text" class=" bg-white rounded-lg p-2 w-full focus:outline-none" placeholder="FCA Account Type" onChange={handleChange}
            value={userData["accountType"] || ""}
            name="accountType"
          />
        </div>

        <div class="col-span-2 lg:col-span-1">
          <label className="">Branch Name:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="text" class=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Branch Name" onChange={handleChange}
            value={userData["branchName"] || ""}
            name="branchName"
          />
        </div>

        <div class="col-span-2 lg:col-span-1">
          <label className="">Account Number:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="text" class=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="FCA Account Number" onChange={handleChange}
            value={userData["accountNumber"] || ""}
            name="accountNumber"
          />
        </div>
        <div className="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Mobile Money Account:</label>
        </div>

        <div className="col-span-2 lg:col-span-1">
          <select type="text" className=" bg-white rounded-lg p-2 w-full focus:outline-none" onChange={handleChange}
            value={userData["mobileMoneyAccount"] || ""}
            name="mobileMoneyAccount"
          >
            <option value="Telecel">Telecel</option>
            <option value="Ecocash">Ecocash</option>
            <option value="One Money">One Money</option>

          </select>
        </div>
      </div>
    </div>
  );
}
