import React from "react";
import { useFormikContext } from "formik";
import { Typography, Grid } from "@material-ui/core";
import PersonalDetails from "./PersonalDetails";
import EmploymentDetails from "./EmploymentDetails";
import BankingDetails from "./BankingDetails";
import References from "./References";
import NextOfKinDetails from "./NextOfKinDetails";
import RelativesDetails from "./RelativesDetails";
import MonthlyExpenditure from "./MonthlyExpenditure";
import { useSelector } from "react-redux";

export default function ReviewOrder() {
  const { values: formValues } = useFormikContext();
  const user = useSelector((state) => state.user);
  //console.log(user);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        <h3 className="text-center">Review your application</h3>
      </Typography>
      <PersonalDetails formValues={formValues} />
      <EmploymentDetails formValues={formValues} />
      <BankingDetails formValues={formValues} />
      <References formValues={formValues} />
      <NextOfKinDetails formValues={formValues} />
      <RelativesDetails formValues={formValues} />
      <MonthlyExpenditure formValues={formValues} />
    </React.Fragment>
  );
}
