import React from "react";
import { Typography } from "@material-ui/core";

function CheckoutSuccess() {
  return (
    
      <div className="bg-gray-200 h-60 w-auto rounded-lg px-2 mt-2 flex flex-col justify-between">
        <h1 className="text-xl font-bold px-4 pt-4">
          Your Loan Application Details have been submitted successfully, proceed to upload required documents
        </h1>
        <a
          href="/upload-id"
          class="w-40 2xl:object-fill ml-4 mb-4 text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
        >
          Go to Documents Upload
        </a>
      </div>
   
  );
}

export default CheckoutSuccess;
