import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  Item,
} from "@material-ui/core";
import useStyles from "./styles";
import formField from "../FormModel/checkoutFormModel";

function EmploymentDetails(props) {
  const { formValues } = props;

  const classes = useStyles();

  JSON.stringify(formField);
  //console.log(formValues);
  // console.log(formField.formField.Passport_No7.label);
  return (
    <List>
      <ListItem className={classes.listItem}>
        <div className="flex flex-col md:flex-col">
        <h3 className="uppercase font-bold test-2xl text-center py-4 bg-gray-200 rounded-lg">Employment Details</h3>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6} >
              <ListItemText
                primary={formField.formField.Employer1.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Employer1}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Employer_Physic3.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Employer_Physic3 ? formValues.Employer_Physic3 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Job_Title2.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.Job_Title2}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Time_at_current7.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.Time_at_current7}
              </Typography>
            </Grid>
            <Grid item xs={6}>
            <ListItemText
            primary={formField.formField.Previous_compan8.label}
            className={classes.heading}
          />
          <Typography
            variant="body2"
            className={classes.value}
            sx={{ paddingY: 2 }}
          >
            {formValues.Previous_compan8 ? formValues.Previous_compan8 : "Not provided"}
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <ListItemText
            primary={formField.formField.Contract_Status9.label}
            className={classes.heading}
          />
          <Typography
            variant="body2"
            className={classes.value}
            sx={{ paddingY: 2 }}
          >
            {formValues.Contract_Status9 ? formValues.Contract_Status9 : "Not provided"}
          </Typography>
            </Grid>

            <Grid item xs={6}>

          <ListItemText
            primary={formField.formField.Pay_Dayeg_25t12.label}
            className={classes.heading}
          />
          <Typography
            variant="body2"
            className={classes.value}
            sx={{ paddingY: 2 }}
          >
            {formValues.Pay_Dayeg_25t12}
          </Typography>
            </Grid>

        
          <Grid item xs={6}>

          <ListItemText
            primary={formField.formField.Work_Phone_Number12.label}
            className={classes.heading}
          />
          <Typography variant="body2" className={classes.value}>
            {formValues.Work_Phone_Number12}
          </Typography>
          </Grid>
         </Grid>
        </div>
      </ListItem>
    </List>
  );
}

export default EmploymentDetails;
