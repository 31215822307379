import React from 'react';
import MaterialLayout from "../applyloan/Layout/MaterialLayout";
import CheckoutPage from "../renewzw/RenewLoan";

function RenewZW() {
  return (
    <MaterialLayout>
      <CheckoutPage />
    </MaterialLayout>
  );
}

export default RenewZW;
