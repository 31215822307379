import React, { useState } from "react";
import loanrepay from '../images/loan-repay.svg';
import newloan from '../images/newloan.svg';
import loancal from '../images/loancal.svg';
import loantopup from '../images/loantopup.svg';
import DashboardContentZW from './DashboardContentZWL';
import DashboardContentUS from './DashboardContentUSD';

//import Currency from './Currency';
// import { TfiReload } from 'react-icons/TfiReload'


const DashboardContent = () => {
    const getInitialState = () => {
        const value = "USD";
        return value;
      };
    
      const [value, setValue] = useState(getInitialState);
    
      const handleChange = (e) => {
        setValue(e.target.value);
      };
    return (
        <>
            <div className="relative">
                <div class="mt-4 w-full grid grid-cols-1 md:grid-cols-4 xl:grid-cols-4 gap-4 px-5" >
                <div class="bg-gray-100 rounded-lg p-4 sm:p-6 xl:p-8 ">
                        <div class=" flex flex-start flex-col">
                        <h3 class="text-base pb-2 uppercase text-red-600">currency</h3>
                        <select value={value} onChange={handleChange}>
                            <option selected disabled>Select Currency</option>
                            <option value="USD">USD</option>
                            <option value="ZWL">ZWL</option>
                        </select>
                        </div>
                    </div>

                    <DashboardContentUS/>
                 
                </div>
            </div>
            <div className="px-5 pt-10">
                <div class="bg-gray-100 w-auto rounded-lg pt-10 sm:pl-0">
                    <div class=" flex justify-center">
                        <div className="flex flex-col items-center ">
                            <span class="text-2xl sm:text-4xl font-bold text-gray-900 ">What are you looking for</span>
                            <div className=" space-x-4 py-10 grid grid-cols-2 gap-2 md:grid-cols-4 xl:grid-cols-4">
                                <a href="/apply-currency" className='min-w-[10rem] max-w-[16rem]'>
                                    <div className=" bg-white rounded-lg flex flex-col justify-center items-center pt-10">
                                        <div className="">
                                            <img src={newloan} class="h-14" alt="loan" />
                                        </div>
                                        <h3 class="uppercase w-auto p-4 ">Apply for new loan</h3>
                                    </div>
                                </a>
                                <a href="/loan-renewal-currency">
                                    <div className=" bg-white rounded-lg flex flex-col justify-center items-center pt-10">
                                        <div className="">
                                            <img src={loantopup} class="h-14" alt="loan" />
                                        </div>
                                        <h3 class="uppercase w-auto p-4 ">Loan topup/ renewal</h3>
                                    </div>
                                </a>
                                <a href="/calculator-currency">
                                    <div className=" bg-white rounded-lg flex flex-col justify-center items-center pt-10">
                                        <div className="">
                                            <img src={loancal} class="h-14" alt="loan" />
                                        </div>
                                        <h3 class="uppercase w-auto p-4 text-center">loan calculator</h3>
                                    </div>
                                </a>
                                <a href="/dashboard">
                                    <div className=" bg-white rounded-lg flex flex-col justify-center items-center pt-10">
                                        <div className="">
                                            <img src={loanrepay} class="h-14" alt="loan" />
                                        </div>
                                        <h3 class="uppercase w-auto p-4 ">loan repayment</h3>
                                    </div>
                                </a>



                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </>
    )
}

export default DashboardContent;
