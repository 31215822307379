import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { InputField, SelectField } from "../FormFields";

export default function EmploymentDetails(props) {
  const {
    formField: {
      Employer1,
      Employer_Physic3,
      Job_Title2,
      Time_at_current7,
      Previous_compan8,
      Contract_Status9,
      Pay_Dayeg_25t12,
      Work_Phone_Number12,
    },
  } = props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Employment Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Employer_Physic3.name}
            label={Employer_Physic3.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={Employer1.name} label={Employer1.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Job_Title2.name}
            label={Job_Title2.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Work_Phone_Number12.name}
            label={Work_Phone_Number12.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Pay_Dayeg_25t12.name}
            label={Pay_Dayeg_25t12.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Time_at_current7.name}
            label={Time_at_current7.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Contract_Status9.name}
            label={Contract_Status9.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Previous_compan8.name}
            label={Previous_compan8.label}
            fullWidth
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
