import React from "react";
import { useStepperContext } from "../../StepperContext";

export default function PersonalDetails() {
  const { userData, setUserData } = useStepperContext();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  return (
    <div class="max-w-2xl py-2 px-5 m-auto w-full">
      <h1 className="font-bold text-xl sm:text-2xl pb-16">Personal Details</h1>
      <div class="grid grid-cols-2 gap-4 m-auto">


        <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Firstname:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="text" class=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300" placeholder="Firstname" onChange={handleChange}
            value={userData["firstname"] || ""}
            name="firstname"
          />
        </div>

        <div class="col-span-2 lg:col-span-1">
          <label className="">Middle Name:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="text" class=" bg-white rounded-lg p-2 w-full focus:outline-none" placeholder="Middle Name" onChange={handleChange}
            value={userData["middleName"] || ""}
            name="middleName"
          />
        </div>

        <div class="col-span-2 lg:col-span-1">
          <label className="">Lastname:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="text" class=" bg-white rounded-lg p-2 w-full focus:outline-none" placeholder="Last Name" onChange={handleChange}
            value={userData["lastname"] || ""}
            name="lastname"
          />
        </div>

        <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Existing Client ID:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="text" class=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300" placeholder="client ID" onChange={handleChange}
            value={userData["clientID"] || ""}
            name="clientID"
          />
        </div>

        <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Branch:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="text" class=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300" placeholder="Branch" onChange={handleChange}
            value={userData["branch"] || ""}
            name="branch"
          />
        </div>

       
        <div class="col-span-2 lg:col-span-1">
          <label className="">Date:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="date" class=" bg-white rounded-lg p-2  w-full focus:outline-none" onChange={handleChange}
            value={userData["date"] || ""}
            name="date"
          />
        </div>

        <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Email Address:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="email" class=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300" placeholder="Email Address " onChange={handleChange}
            value={userData["emailAddr"] || ""}
            name="emailAddr"
          />
        </div>
        <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Cell Number:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="email" class=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300" placeholder="+263 777 777 777" onChange={handleChange}
            value={userData["cell"] || ""}
            name="cell"
          />
        </div>

       
      </div>
      <div className="flex flex-col bg-white h-auto justify-between mt-4 px-4 pt-4 rounded-lg">
          <h1 className="uppercase text-red-700 text-medium font-bold pt-2">notice!</h1>
          <div className=" my-4">
          If personal, employment details and other related details that were intitially submitted changed please apply for a new loan 
          </div>
          <a href="/currency" class="w-40 mb-4 text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-md text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800">Apply New Loan</a>

        </div>

    </div>
  );
}
