import React from "react";
import otp from '../images/forgotpwd.svg';
import logoo from '../images/logoo.png';

const ForgotPassword = () => {
    return (
        <div className="container">


            <div className="flex h-screen bg-gray-100 md:bg-gray-100">
                <div className="hidden lg:flex w-auto">
                    <img src={otp} className="mx-auto" alt="clubplus" />
                </div>
                <div className="w-full flex items-center justify-center lg:w-1/2 ">
                    <div class="max-w-2xl mx-auto">
                        <div
                            class="bg-white shadow-md border flex items-center justify-center border-gray-200 rounded-lg max-w-sm p-4 sm:p-6 lg:p-8 dark:bg-gray-800 dark:border-gray-700">
                            <form class="space-y-6" action="#">
                                <a href="/">
                                    <img src={logoo} className="mx-auto" alt="clubplus" />
                                </a>
                                <h3 class="text-xl text-black font-bold dark:text-white text-center">Forgot Password</h3>
                                <h3 className=' text-black dark:text-white text-center'>Enter your email to retrieve your password </h3>

                                <input type="text" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-500 focus:outline-none focus:border-gray-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="Enter your email" required="" />
                               <div className="flex items-center justify-center">
                               <button type="submit" class=" text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Send Link to Reset Password</button>

                               </div>
                                <a href="/login">Login</a>
                            </form>
                        </div>


                    </div>
                </div>


            </div>
        </div>
    );
}

export default ForgotPassword;