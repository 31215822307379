import React from "react";
const Footer = () =>{

    return(
<>
<div className="px-5 pt-10 pb-10 ">
                <div class="bg-gray-100 w-auto rounded-lg">
                    <div class="flex items-center h-20 justify-center">
                        <div class="">
                            <h3 class="text-center w-auto">&copy; {new Date().getFullYear()} Clubplus Microfinance. All rights reserved</h3>

                        </div>

                    </div>
                </div>

            </div>
</>
    )
}

export default Footer