import React, { useEffect } from 'react';
import axios from 'axios';

const ProfileDetails = ({user, avatar}) => {

  useEffect(() => {
    axios.get(`https://api.demo.irl.musoniservices.com/v1/datatables/audits?entityName=CLIENT&clientId=${user.id}&includeJson=true&orderBy=id&sortBy=DESC`)
  .then((response) => {
    console.log(response.data);
  })
  .catch((error) => {
    console.error(error);
  });

  }, [])

  return (
        <React.Fragment>
        <div>
          <div className="flex justify-center items-center text-center mb-16 mt-6">
            <div className="avatar">
                <div className="flex justify-center mb-3">
                  <img src={avatar} className="rounded-full w-24 " alt="userAvatar" />
                </div>
                <div>
                  <h2 className="pl-2 text text-red-500 font-bold">Hello</h2>
                  <h2 className="pl-2 text-4xl font-bold"> {user.displayName}</h2>
                </div>
              </div>
            </div>

          <div className="bg-gray-100 w-auto rounded-lg p-10">
            <h2 className="text-3xl font-bold mb-8">Profile Details</h2>
            <div className="flex flex-col">
              <div className="grid grid-cols-2 gap-4 mt-2">
                <div className="font-bold">ID:</div>
                <div>{user.id}</div>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-2">
                <div className="font-bold">Physical Address:</div>
                <div>{}</div>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-2">
                <div className="font-bold">Phone Number:</div>
                <div>{user.mobileNo}</div>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-2">
                <div className="font-bold">Email Address:</div>
                <div>{user.emailAddress}</div>
              </div>
            </div>

            <div className="pt-5 mt-20">
              <a href="/profile-modify" className="min-w-[197px] text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-md text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Modify Profile</a>
            </div>
          </div>
        </div>
        </React.Fragment>  
      );
}
 
export default ProfileDetails;