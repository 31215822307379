import { combineReducers } from '@reduxjs/toolkit'
import userReducer from '../Features/userSlice'
import userOtpReducer from '../Features/userOtpSlice'


const rootReducer = combineReducers({
  user: userReducer,
  userOtp: userOtpReducer,
})

export default rootReducer