import React, { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Formik, Form } from "formik";

import AddressForm from "./Forms/PersonalDetailsForm";

import MonthlyExpenditure from "./Forms/MonthlyExpenditure";
import Review from "./Review";
import ZwTopupDoc1 from "./Forms/FilesUpload";
//import ZwTopupDoc2 from "./UploadDocuments/BankStatementForTopup";
import CheckoutSuccess from "./RenewSuccess.jsx";
import validationSchema from "./FormModel/validationSchema";
import checkoutFormModel from "./FormModel/checkoutFormModel";
import formInitialValues from "./FormModel/formInitialValues";

import axios from "axios";
import { Buffer } from "buffer";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";

const steps = ["Personal Details", "Monthly Expenditure","Upload Documents", "Review"];
const { formId, formField } = checkoutFormModel;

function _renderStepContent(step) {
  switch (step) {
    case 0:
      return <AddressForm formField={formField} />;

    case 1:
      return <MonthlyExpenditure formField={formField} />;

    case 2:
        return <ZwTopupDoc1/>;

    case 3:
      return <Review />;
    default:
      return <div>Not Found</div>;
  }
}

export default function RenewLoanPage() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  function _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const apiResponse = reactLocalStorage.getObject("apiResponse");
  const clientId = apiResponse.clientId;
  const userData = useSelector((state) => state.user);
  const user = userData.user;
  //console.log(user.id);

  async function _submitForm(values, actions) {
    await _sleep(1000);
    JSON.stringify(values);

    // console.log(values.copyOfID);
    //alert(JSON.stringify(values, null, 2));

      //headers
      let apiHeaders = {
        Authorization:
          "Basic " + Buffer.from("webuser:Demo@#2022").toString("base64"),
        "Content-Type": "application/json",
        Accept: "application/json",
        Allow_Methods: ["PUT"],
        Allow_Origin: ["*"],
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Origin": "https://clubplus-backend.onrender.com",
        "x-api-key": "oHJ0G18QYt2SMVNpogzio8WttytilwE17tuAky7p",
        "X-Fineract-Platform-TenantId": "clubplus",
      };
    ///clientPersonalDetails
    const clientPersonalDetails = {
      locale: "en",
      dateFormat: "yyyy-MM-dd",
      Resedential_Address17: values.Resedential_Address17,
      Postal_Address20: values.Postal_Address20,
      ID_No6: values.ID_No6,
      Mobile_No214: values.Mobile_No214,
      LandlineHome_phone16: values.LandlineHome_phone16,
      Passport_No7: values.Passport_No7,
      No_of_dependents9: values.No_of_dependents9,
      // profession_cd_Profession11: values.profession_cd_Profession11,
      Nationality10: values.Nationality10,
    };


    // //   ///monthly expenditure, income and fixed expenditure
    const monthlyExpenditure = {
      locale: "en",
      dateFormat: "yyyy-MM-dd",
      Gross_Incomepe1: values.Gross_Incomepe1,
      Net_Income_per2: values.Net_Income_per2,
      Other_Incomesp3: values.Other_Incomesp3,
      Total_IncomeNe4: values.Total_IncomeNe4,
    };

  
    // //   //axios api call  //send personal details to datatables api
    axios({
      method: "PUT",
      url:
        "https://api.demo.irl.musoniservices.com/v1/datatables/cct_PERSONALDETAILS/" +
        user.id,
      data: clientPersonalDetails,
      mode: "no-cors",
      withCredentials: true,
      credentials: "same-origin",
      headers: apiHeaders,
    })
      .then(async function (response) {
        console.log(response);
      })
      .catch(async function (error) {
        console.log(error);
      });
  

    // // send monthlyExpenditure to API
    axios({
      method: "PUT",
      url:
        "https://api.demo.irl.musoniservices.com/v1/datatables/cct_MonthlyIncome/" +
        user.id,
      data: monthlyExpenditure,
      mode: "no-cors",
      withCredentials: true,
      credentials: "same-origin",
      headers: apiHeaders,
    })
      .then(async function (response) {
        console.log(response);
      })
      .catch(async function (error) {
        console.log(error);
      });

    // fixedMonthlyExpenditure
    const fixedMonthlyExpenditure = {
      locale: "en",
      // dateFormat: "yyyy-MM-dd",

      Monthly_Rental1: values.Monthly_Rental1,
      Monthly_Bus_Fare2: values.Monthly_Bus_Fare2,
      Monthly_Rental1: values.Monthly_Rental1,
      Monthly_school4: values.Monthly_school4,
      Monthly_Insurance7: values.Monthly_Insurance7,
      Monthly_credit_8: values.Monthly_credit_8,
      Monthly_Groceries9: values.Monthly_Groceries9,
      Monthly_Utiliti10: values.Monthly_Utiliti10,
      Other_monthly_e11: values.Other_monthly_e11,
      Net_IncomeTota12: values.Net_IncomeTota12,
    };

    // send fixedMonthlyExpenditure to API
    axios({
      method: "PUT",
      url:
        "https://api.demo.irl.musoniservices.com/v1/datatables/cct_FixedMonthlyExpenditure/" +
        user.id,
      data: fixedMonthlyExpenditure,
      mode: "no-cors",
      withCredentials: true,
      credentials: "same-origin",
      headers: apiHeaders,
    })
      .then(async function (response) {
        console.log(response);
      })
      .catch(async function (error) {
        console.log(error);
      });
    //set setSubmitting to true .........
    actions.setSubmitting(true);
    //next step ...............
    setActiveStep(activeStep + 1);
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4" align="center">
        USD Loan TopUp /Renewal
      </Typography>
      <div className="hidden lg:flex justify-center">
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <React.Fragment>
        {activeStep === steps.length ? (
          <CheckoutSuccess />
        ) : (
          <Formik
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form id={formId}>
                {_renderStepContent(activeStep)}

                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={_handleBack} className={classes.button}>
                      Previous
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {isLastStep ? "Submit Application" : "Next"}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}
