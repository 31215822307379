import React from "react";
//import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
// import { useStepperContext } from "../../StepperContext";


const UploadDocuments = () => {
    // const { userData, setUserData } = useStepperContext();

    // const handleChange = (e) => {
    //   const { name, value } = e.target;
    //   setUserData({ ...userData, [name]: value });
    // };
    //const fileTypes = ["DOCS", "DOC", "PDF"];


    //   const [file, setFile] = useState(null);
    //   const handleChange = (file) => {
    //     setFile(file);
    //   };
    return (
        <div class="max-w-2xl py-2 px-5 m-auto w-full">
            <h1 className="font-extrabold text-xl sm:text-2xl pb-4 text-center">Upload Documents</h1>
            <div className=" grid grid-cols-2 gap-4 m-auto">
                <div className="col-span-2 lg:col-span-1 flex flex-col items-center ju">
                    <h1 className="font-bold w-1/2 pb-4 text-center">Current Payslip</h1>
                    <label className=" w-auto pb-4 text-center text-xs text-red-500">UPLOAD in JPG/PNG/PDF/XML format</label>

                    <label class="block bg-white pl-4 py-8 rounded-lg">
                        <span class="sr-only bg-black">Choose File</span>
                        <input type="file" class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300" />
                    </label>

                </div>
                <div className="col-span-2 lg:col-span-1 flex flex-col items-center ju">
                    <h1 className="font-bold pb-4 text-center">1 Month bank statement (loan topup)</h1>
                    <label className=" w-auto pb-4 text-center text-xs text-red-500">UPLOAD in JPG/PNG/PDF/XML format</label>

                    <label class="block bg-white pl-4 py-8 rounded-lg">
                        <span class="sr-only bg-black">Choose File</span>
                        <input type="file" class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300" />
                    </label>

                </div>
                <div className="col-span-2 lg:col-span-1 flex flex-col items-center">
                    <h1 className="font-bold pb-4 text-center">1 Month bank statement for loan renewal</h1>
                    
                    <span className="text-sm text-center pb-2">If the last loan has been paid up for more than 12 months submit </span>
                
                    <label className=" w-auto pb-4 text-center text-xs text-red-500">UPLOAD in JPG/PNG/PDF/XML format</label>

                    <label class="block bg-white pl-4 py-8 rounded-lg">
                        <span class="sr-only bg-black">Choose File</span>
                        <input type="file" class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300" />
                    </label>

                </div>
              
                </div>

            {/* <div class="flex justfy-center items-center ">
                <h1 className="font-bold w-1/2 pb-4 text-center ">Proof Of Residence</h1>
            </div>
            <div class="flex justfy-center items-center">
                <label className=" w-1/2 pb-4 text-center text-xs text-red-500">UPLOAD in JPG/PNG/PDF/XML format</label>
                <label className="w-1/2 pb-4 text-center text-xs text-red-500">UPLOAD in JPG/PNG/PDF/XML format</label>
            </div>

            <div class="flex space-x-6 justfy-center items-center">
                <label class="block bg-white pl-4 py-8 rounded-lg">
                    <span class="sr-only bg-black">Choose File</span>
                    <input type="file" class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300" />
                </label>
                <label class="block bg-white pl-4 py-8 rounded-lg">
                    <span class="sr-only bg-black">Choose File</span>
                    <input type="file" class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300" />
                </label>
            </div>
            <div class="flex justfy-center items-center">
                <h1 className="font-bold w-1/2 pb-4 text-center">Copy Of ID</h1>
                <h1 className="font-bold w-1/2 pb-4 text-center ">Proof Of Residence</h1>
            </div>
            <div class="flex justfy-center items-center">
                <label className=" w-1/2 pb-4 text-center text-xs text-red-500">UPLOAD in JPG/PNG/PDF/XML format</label>
                <label className="w-1/2 pb-4 text-center text-xs text-red-500">UPLOAD in JPG/PNG/PDF/XML format</label>
            </div>

            <div class="flex space-x-6 justfy-center items-center">
                <label class="block bg-white pl-4 py-8 rounded-lg">
                    <span class="sr-only bg-black">Choose File</span>
                    <input type="file" class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300" />
                </label>
                <label class="block bg-white pl-4 py-8 rounded-lg">
                    <span class="sr-only bg-black">Choose File</span>
                    <input type="file" class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300" />
                </label>
            </div>
            <div class="flex justfy-center items-center">
                <h1 className="font-bold w-1/2 pb-4 text-center">Copy Of ID</h1>
                <h1 className="font-bold w-1/2 pb-4 text-center ">Proof Of Residence</h1>
            </div>
            <div class="flex justfy-center items-center">
                <label className=" w-1/2 pb-4 text-center text-xs text-red-500">UPLOAD in JPG/PNG/PDF/XML format</label>
                <label className="w-1/2 pb-4 text-center text-xs text-red-500">UPLOAD in JPG/PNG/PDF/XML format</label>
            </div>

            <div class="flex space-x-6 justfy-center items-center">
                <label class="block bg-white pl-4 py-8 rounded-lg">
                    <span class="sr-only bg-black">Choose File</span>
                    <input type="file" class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300" />
                </label>
                <label class="block bg-white pl-4 py-8 rounded-lg">
                    <span class="sr-only bg-black">Choose File</span>
                    <input type="file" class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300" />
                </label>
            </div>
            <div class="flex justfy-center items-center">
                <h1 className="font-bold w-1/2 pb-4 text-center">Copy Of ID</h1>
                <h1 className="font-bold w-1/2 pb-4 text-center ">Proof Of Residence</h1>
            </div>
            <div class="flex justfy-center items-center">
                <label className=" w-1/2 pb-4 text-center text-xs text-red-500">UPLOAD in JPG/PNG/PDF/XML format</label>
                <label className="w-1/2 pb-4 text-center text-xs text-red-500">UPLOAD in JPG/PNG/PDF/XML format</label>
            </div>

            <div class="flex space-x-6 justfy-center items-center">
                <label class="block bg-white pl-4 py-8 rounded-lg">
                    <span class="sr-only bg-black">Choose File</span>
                    <input type="file" class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300" />
                </label>
                <label class="block bg-white pl-4 py-8 rounded-lg">
                    <span class="sr-only bg-black">Choose File</span>
                    <input type="file" class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300" />
                </label>
            </div>
             */}










            {/* 
        <FileUploader handleChange={handleChange} name="file" types={fileTypes}/>


        <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
           */}

        </div>

    );
}


export default UploadDocuments;