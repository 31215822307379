import React from "react";
import { Typography } from "@material-ui/core";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

function UploadSuccess() {
  return (
    <>
    <Header/>
    <div className="flex flex-col items-center justify-center">

      <div className="w-1/2 bg-gray-200 h-60 rounded-lg px-2 mt-2 flex flex-col justify-between">
        <h1 className="text-xl font-bold px-4 pt-4">
          Your Uploads have been submitted successfully, and your loan application is under
          review.
        </h1>
        <a
          href="/dashboard"
          class="w-40 2xl:object-fill ml-4 mb-4 text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
        >
          Go to Dashboard
        </a>
      </div>
      </div>
      <Footer/>
    
   </>
  );
}

export default UploadSuccess;
