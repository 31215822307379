import React from "react";
import Header from './Header';
import Footer from './Footer';
//import DashboardContent from './Dashboardcontent';

const LoanRenewalCurrency = () => {
    return (
        <div className='bg-white py-2 px-5 m-auto w-full'>
            <Header />
            <div className="px-20 pt-10 pb-10">
                <div class="bg-gray-100 w-auto rounded-lg px-20 h-80">
                    <div class="h-20 flex items-center justify-center">
                        <h1 class="text-center font-bold text-2xl pt-16">Select Currency</h1>
                    </div>
                    <div class="h-20 flex items-center justify-center ">
                        <div class="flex p-4 gap-2 pt-10 ">
                            <div class="relative w-full pr-4 rounded-sm max-w-full flex-grow flex-1 items-center bg-black">
                                <a href="/apply-zw">
                                    <h5 class="text-blueGray-400 uppercase font-bold text-xs text-white text-center pt-2 pl-2">ZWL</h5>   </a>
                            </div>
                            <div class="relative pr-4 max-w-full rounded-sm flex-grow flex-1 bg-red-600 w-40 h-8 items-center">
                                <a href="/apply-us">
                                    <h5 class=" text-blueGray-400 uppercase font-bold text-xs text-center pt-2">USD</h5>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <Footer />
        </div>




    );
}

export default LoanRenewalCurrency;