import React from "react";
import { Typography } from "@material-ui/core";

function RenewSuccess() {
  return (
    
      <div className="bg-gray-200 h-60 w-auto rounded-lg px-2 mt-2 flex flex-col justify-between">
        <h1 className="text-xl font-bold px-4 pt-4">
          Your Loan Top up/Renewal Application has been submitted successfully, and it's under review. Approval is subject to completion and signing of Loan Agreement.
        </h1>
        <a href="/dashboard"   className="button w-40 2xl:object-fill ml-4 mb-4 text-white bg-red-500 rounded-lg"
        >Back To Dashboard</a>
      </div>
   
  );
}

export default RenewSuccess;
