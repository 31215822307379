/* eslint-disable import/no-anonymous-default-export */
export default {
  formId: 'checkoutForm',

  formField: {
    //personal details
    Mobile_No214: {
      name: 'Mobile_No214',
      label: 'Mobile Number*',
      requiredErrorMsg: 'Mobile Number is required'

    },
    LandlineHome_phone16: {
      name: 'LandlineHome_phone16',
      label: 'Landline',

    },

    //submittedon_date,
    Resedential_Address17: {
      name: 'Resedential_Address17',
      label: 'Residential Address*',
      requiredErrorMsg: 'Residential Address is required'
    },
    Postal_Address20: {
      name: 'Postal_Address20',
      label: 'Postal Address*',
      requiredErrorMsg: 'Postal Address is required'
    },
    ID_No6: {
      name: 'ID_No6',
      label: 'Identification Number*',
      requiredErrorMsg: 'ID number is required'
    },
    Passport_No7: {
      name: 'Passport_No7',
      label: 'Passport Number',
      requiredErrorMsg: 'Passport Number is required'
    },
    No_of_dependents9: {
      name: 'No_of_dependents9',
      label: 'Number Of Dependents',
    },
    profession_cd_Profession11: {
      name: 'profession_cd_Profession11',
      label: 'Profession',
    },
    Nationality10: {
      name: 'Nationality10',
      label: 'Nationality*',
      requiredErrorMsg: 'Nationality is required'

    },
    loanAmount: {
      name: 'loanAmount',
      label: 'Loan Amount',
      requiredErrorMsg: 'Loan Amount is required'

    },
    loanType: {
      name: 'loanType',
      label: 'Loan Type',
      requiredErrorMsg: 'Loan Type is required'

    },

   
    //montly expenditure #####INCOME
    Gross_Incomepe1: {
      name: 'Gross_Incomepe1',
      label: 'Gross Income*',
      requiredErrorMsg: 'Gross Income is required'
    },
    Net_Income_per2: {
      name: 'Net_Income_per2',
      label: 'Net Income*',
      requiredErrorMsg: 'Net Income is required'
    },
    Other_Incomesp3: {
      name: 'Other_Incomesp3',
      label: 'Other Incomes'
    },
    Total_IncomeNe4: {
      name: 'Total_IncomeNe4',
      label: 'Total Income'
    },
    Monthly_Rental1: {
      name: 'Monthly_Rental1',
      label: 'Monthly Rental'
    },
    Monthly_Bus_Fare2: {
      name: 'Monthly_Bus_Fare2',
      label: 'Monthly Bus Fare'
    },
    Monthly_vehicle3: {
      name: 'Monthly_vehicle3',
      label: 'Monthly Vehicle Expenses'
    },
    Monthly_school4: {
      name: 'Monthly_school4',
      label: 'Monthly Fees (PreSchool ,High School ...)'
    },
    Monthly_Insurance7: {
      name: 'Monthly_Insurance7',
      label: 'Monthly Insurance'
    },
    Monthly_credit_8: {
      name: 'Monthly_credit_8',
      label: 'Monthly Credit Account Instalments'
    },
    Monthly_Groceries9: {
      name: 'Monthly_Groceries9',
      label: 'Monthly Groceries*',
      requiredErrorMsg: 'Monthly Groceries Exependiture is required'
    },
    Monthly_Utiliti10: {
      name: 'Monthly_Utiliti10',
      label: 'Monthly Utilities & Electricity*',
      requiredErrorMsg: 'Monthly Utilities Exependiture is required'
    },
    Other_monthly_e11: {
      name: 'Other_monthly_e11',
      label: 'Other Monthly Expenses'
    },
    Net_IncomeTota12: {
      name: 'Net_IncomeTota12',
      label: 'Total Income'
    },
    //////
    copyOfID: {
      name: 'copyOfID',
      label: 'Copy Of ID'
    },
    proofOfResidence: {
      name: 'proofOfResidence',
      label: 'Proof Of Residence'
    },
    passportPhoto: {
      name: 'passportPhoto',
      label: 'Passport Photo '
    },
    bankStatement: {
      name: 'bankStatement',
      label: 'Bank Statement '
    },
    payslip: {
      name: 'payslip',
      label: 'Payslip'
    },
    nextOfKinID: {
      name: 'nextOfKinID',
      label: 'Next Of Kin ID'
    }
  }
};
