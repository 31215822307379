import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  Item,
} from "@material-ui/core";
import useStyles from "./styles";
import formField from "../FormModel/checkoutFormModel";

function References(props) {
  const { formValues } = props;

  const classes = useStyles();

  JSON.stringify(formField);
 // console.log(formValues);
  // console.log(formField.formField.Passport_No7.label);
  return (
    <List>
      <ListItem className={classes.listItem}>
        <div className="flex flex-col md:flex-col">
          <div className="space-y-4">
            <h3 className="uppercase font-bold test-2xl text-center py-4 bg-gray-200 rounded-lg">
              References
            </h3>

            <h4 className="font-bold uppercase bg-gray-100 text-center py-2 rounded-lg">
              {" "}
              1: Spouse Details
            </h4>
          </div>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.TITLE_cd_Title1.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.TITLE_cd_Title1}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.First_Name2.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.First_Name2
                  ? formValues.Branch_Name3
                  : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Surname3.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.Surname3 ? formValues.Surname3 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Maiden_Name4.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.Maiden_Name4}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Date_Of_Birth5.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.Date_Of_Birth5}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.spouseID_No6.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.spouseID_No6
                  ? formValues.spouseID_No6
                  : "Not provided"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.spousePassport_No7.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.spousePassport_No7
                  ? formValues.spousePassport_No7
                  : "Not provided"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Gender_cd_Gender8.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Gender_cd_Gender8
                  ? formValues.Gender_cd_Gender8
                  : "Not provided"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.spouseNo_of_dependents9.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.spouseNo_of_dependents9
                  ? formValues.spouseNo_of_dependents9
                  : "Not provided"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.spouseNationality10.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.spouseNationality10
                  ? formValues.spouseNationality10
                  : "Not provided"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ListItemText
                primary={
                  formField.formField.spouse_profession_cd_Profession11.label
                }
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.spouse_profession_cd_Profession11
                  ? formValues.spouse_profession_cd_Profession11
                  : "Not provided"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Mobile_No12.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Mobile_No12
                  ? formValues.Mobile_No12
                  : "Not provided"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Mobile_no213.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Mobile_no213
                  ? formValues.Mobile_no213
                  : "Not provided"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Email_address14.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Email_address14
                  ? formValues.Email_address14
                  : "Not provided"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Landline15.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Landline15 ? formValues.Landline15 : "Not provided"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Residential_Address16.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Residential_Address16
                  ? formValues.Residential_Address16
                  : "Not provided"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Postal_Address17.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Postal_Address17
                  ? formValues.Postal_Address17
                  : "Not provided"}
              </Typography>
            </Grid>
          </Grid>
        
        </div>
      </ListItem>
    </List>
  );
}

export default References;
