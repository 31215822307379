import React from "react";
import StepForm from "./StepForm";
import Auth from "./Auth";
import Apply from "./SelectCurrency";

function Otpindex() {
  if(Auth.isAuthenticated()) return <Apply />
  else return <StepForm/>;
}

export default Otpindex;
