import React from "react";
import { useStepperContext } from "../../StepperContext";

const MonthlyExpenditure = () => {
  const { userData, setUserData } = useStepperContext();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };
  return (
    <div class="max-w-2xl py-2 px-5 m-auto w-full">
      <h1 className="font-bold text-xl sm:text-2xl pb-4">Income</h1>
      <div class="grid grid-cols-2 gap-4 m-auto">

        <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Gross Income:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="number" class=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300" placeholder="" onChange={handleChange}
            value={userData["email"] || ""}
            name="email"
          />
        </div>

        <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Net Income:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="number" class=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300" placeholder=" " onChange={handleChange}
            value={userData["netIncome"] || ""}
            name="netIncome"
          />
        </div>
        <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Other Income:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="number" class=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300" placeholder="" onChange={handleChange}
            value={userData["otherIncome"] || ""}
            name="otherIncome"
          />
        </div>

        <div className=" hidden lg:flex"></div>
        <h1 className="font-bold text-2xl sm:text-2xl pb-4 pr-0 w-full  text-center">Total: $3500</h1>


        <div class="col-span-2 lg:col-span-r">
        <h1 className="font-bold text-xl sm:text-2xl pb-4">Fixed Monthly Expenses</h1>
        </div>

        <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className=""> Monthly Rental:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="number" class=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300" placeholder="" onChange={handleChange}
            value={userData["monthlyRental"] || ""}
            name="monthlyRental"
          />
        </div>
        
        <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Monthyly Vehicle Expense:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="number" class=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300" placeholder="  " onChange={handleChange}
            value={userData["monthlyVehicleExpense"] || ""}
            name="monthlyVehicleExpense"
          />
        </div>

        <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Monthyly Busfare:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="number" class=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300" placeholder=" " onChange={handleChange}
            value={userData["monthylyBusfare"] || ""}
            name="monthylyBusfare"
          />
        </div>

        <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Monthyly Fees: Preschool,High School, University</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="number" class=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300" placeholder=" " onChange={handleChange}
            value={userData["monthylyFees"] || ""}
            name="MonthylyFees"
          />
        </div>

        <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Monthyly Insurance:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="number" class=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300" placeholder="" onChange={handleChange}
            value={userData["monthylyInsurance"] || ""}
            name="monthylyInsurance"
          />
        </div>

        
        <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Monthly Credit Acoounts Instalments:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="number" class=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300" placeholder="" onChange={handleChange}
            value={userData["creditAccInstalmts"] || ""}
            name="creditAccInstalmts"
          />
        </div>

        <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Monthly Groceries:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="number" class=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300" placeholder="" onChange={handleChange}
            value={userData["monthlyGroceries"] || ""}
            name="monthlyGroceries"
          />
        </div>


        <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Monthly Utilities and Electricity:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="number" class=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300" placeholder="" onChange={handleChange}
            value={userData["utilitiesAndElectricity"] || ""}
            name="UtilitiesAndElectricity"
          />
        </div>

        <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Other Monthly Expenses:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="number" class=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300" placeholder="" onChange={handleChange}
            value={userData["otherMonthlyExpenses"] || ""}
            name="otherMonthlyExpenses"
          />
        </div>

        <div className=" hidden lg:flex"></div>
        <h1 className="font-bold text-2xl sm:text-2xl pb-4 pr-0 w-full  text-center">Total Expences: $3500</h1>



        

        
{/* end////////////////////// */}

      </div>
    </div>
  );
}

export default MonthlyExpenditure