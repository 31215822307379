import React from "react";
import { useState } from "react";
import otp from "../images/otp.png";
import logoo from "../images/logoo.png";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StepForm from "./StepForm";
import Auth from "./Auth";
import Apply from "./SelectCurrency";

const Otp = (props) => {
  const { value, handleChange, hashHandleChange } = props;
  const [visible, setIsVisible] = useState(true);

  const [apiError, setApiError] = useState(null);
  const user = useSelector((state) => state.user);

  const navigate = useNavigate();

  if (apiError != null) {
    setTimeout(function () {
      setIsVisible(false);
    }, 5000);
  }

  const checkCode = (e)=>{
    axios.post("https://rest.bluedotsms.com/api/VerifyStatus", {
      "api_id": "API5917286622",
      "api_password": "Password1",
       "sender_id": "CLUBPLUS",
      // "phonenumber": fullMobileNo,
       "message_id": "20060379",
       "brand": "CLUBPLUS",
       "sms_type": "P",
       "encoding": "T",
        "verification_code": `${value.otp}`,
        "templateid": null,
        //"textmessage":"Club Plus Otp is: ",
        "V1": null,
        "V2": null,
        "V3": null,
        "V4": null,
        "V5": null
    })
    .then(function (response) {
      console.log(response);
      alert(response);
    })
    .catch(function (error) {
      console.log(error);
      alert(error);
    });
  }
  const Back = (e) => {
    e.preventDefault();
    props.prevStep();
  };
  return (
    <div className="container">
      <div className="flex h-screen bg-gray-100 md:bg-gray-100">
        <div className="hidden lg:flex h-full">
          <img src={otp} className="mx-auto" alt="clubplus" />
        </div>
        <div className="w-full flex items-center justify-center lg:w-1/2 ">
          <div class="max-w-2xl mx-auto">
            <div class="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm p-4 sm:p-6 lg:p-8 dark:bg-gray-800 dark:border-gray-700">
              <form class="space-y-6" action="#">
                <a href="/">
                  <img src={logoo} className="mx-auto" alt="clubplus" />
                </a>
                <h3 class="text-xl text-black font-bold dark:text-white text-center">
                  Check OTP Sent to your email/phone to login
                </h3>

                {apiError && visible && (
                  <div
                    className="bg-red-100 rounded-lg py-3 px-6 text-base text-red-700 mb-3"
                    role="alert"
                  >
                    {<p>{apiError}</p>}
                  </div>
                )}

                <input
                  type="text"
                  name="otp"
                  id="otp"
                  //value={value.otp}
                  //onChange={handleChange("otp")}
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-500 focus:outline-none focus:border-gray-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  placeholder="Enter OTP"
                />
                <div className="flex space-x-4">
                  <button
                    type="submit"
                    onClick={Back}
                    class="w-full text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-md text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    onClick={checkCode}
                    class="w-full text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-md text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Verify
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;
