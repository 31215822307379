import React from "react";
import { useStepperContext } from "../../StepperContext";

export default function Payment() {
  const { userData, setUserData } = useStepperContext();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };
  return (
    <div class="max-w-2xl py-2 px-5 m-auto w-full">
       <h1 className="font-bold text-xl sm:text-2xl pb-16">Employment Details</h1>
      <div class="grid grid-cols-2 gap-4 m-auto">

        <div class="col-span-2 lg:col-span-1">
          <label className="">Work Address:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="text" class=" bg-white rounded-lg p-2 w-full focus:outline-none" placeholder="Work Address" onChange={handleChange}
            value={userData["workAddress"] || ""}
            name="workAddress"
          />
        </div>

        <div class="col-span-2 lg:col-span-1">
          <label className="">Name Of Employer:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="text" class=" bg-white rounded-lg p-2 w-full focus:outline-none" placeholder="Name Of Employer" onChange={handleChange}
            value={userData["nameOfEmployer"] || ""}
            name="nameOfEmployer"
          />
        </div>

        <div class="col-span-2 lg:col-span-1">
          <label className="">Job Title:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="text" class=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Job Title" onChange={handleChange}
            value={userData["jobTitle"] || ""}
            name="jobTitle"
          />
        </div>

        <div class="col-span-2 lg:col-span-1">
          <label className="">Work Number:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="text" class=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Work Number" onChange={handleChange}
            value={userData["workNumber"] || ""}
            name="workNumber"
          />
        </div>
        <div class="col-span-2 lg:col-span-1">
          <label className="">Payment Date:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="date" class=" bg-white rounded-lg p-2  w-full focus:outline-none" onChange={handleChange}
            value={userData["paymentDate"] || ""}
            name="paymentDate"
          />
        </div>

        
        <div class="col-span-2 lg:col-span-1">
          <label className="">Time Of Current Job:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="text" class=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Time Of Current Job" onChange={handleChange}
            value={userData["timeOfCurrentJob"] || ""}
            name="timeOfCurrentJob"
          />
        </div>
        <div class="col-span-2 lg:col-span-1">
          <label className="">Contact Status:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="text" class=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Contact Status" onChange={handleChange}
            value={userData["contactStatus"] || ""}
            name="contactStatus"
          />
        </div>
        <div class="col-span-2 lg:col-span-1">
          <label className="">Contact Expiry Date:</label>
        </div>
        <div class="col-span-2 lg:col-span-1">
          <input type="date" class=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Payment Period" onChange={handleChange}
            value={userData["contactExpiryDate"] || ""}
            name="contactExpiryDate"
          >

          </input>
        </div>

      </div>
    </div>
  );
}
