import * as Yup from 'yup';
// import moment from 'moment';
import checkoutFormModel from './checkoutFormModel';
const {
  formField: {
    //personal details
    Resedential_Address17,
    Postal_Address20,
    ID_No6,
    Mobile_No214,
    LandlineHome_phone16,
    Passport_No7,
    No_of_dependents9,
    profession_cd_Profession11,
    Nationality10,

   // employment details
    Employer1,
    Employer_Physic3,
    Job_Title2,
    Time_at_current7,
    Previous_compan8,
    Contract_Status9,
    Pay_Dayeg_25t12,
    Work_Phone_Number12,
    //banking details
    Bank_Name1,
    Account_Name2,
    Branch_Name3,
    Bank_Branch_Code4,
    Account_Number5,
    Account_Type6,
    //references
    //#spouse details

    TITLE_cd_Title1,
    First_Name2,
    Surname3,
    Maiden_Name4,
    Date_Of_Birth5,
    spouseID_No6,
    spousePassport_No7,
    Gender_cd_Gender8,
    spouseNo_of_dependents9,
    spouseNationality10,
    spouse_profession_cd_Profession11,
    Mobile_No12,
    Mobile_no213,
    Email_address14,
    Landline15,
    Residential_Address16,
    Postal_Address17,
    // //#next of keen details

    GuarantorRelationship_cd_relation_to_client,
    TITLE_cd_Title5,
    name,
    Surname6,
    Mobile_No8,
    Email_Address9,
    LandlineHomephone10,
    Residential_Address11,
    Employer_Physic12,
    Work_Phone_no13,
    Gender_cd_Gender19,
    dateOfBirth,
    ID_number17,
    Passport_number18,
    Nationality20,
    profession_cd_Profession21,
    Postal_Address22,
    Employer23,
    Mobile_Number24,
    Number_of_Dependency25,
    // //references #details of relatives that do not stay with you
    relativesTITLE_cd_Title1,
    relativesFirst_Name2,
    relativesSurname3,
    Relationship4,
    Mobile_No6,
    Email_Address7,
    Home_Landline8,
    Resedential_Address9,
    Business_Address10,
    Occupation5,
    Work_phone_no11,
    // //references #details of relatives that dont stay with you
    First_Name21111,
    Surname31010,
    Relationship599,
    Occupation488,
    Mobile_No677,
    Email_address766,
    Home_landlineH855,
    Resedential_add4,
    Work_phone_no1033,

    // //montly expenditure #####INCOME
    Gross_Incomepe1,
    Net_Income_per2,
    Other_Incomesp3,
    Total_IncomeNe4,
    Monthly_Rental1,
    Monthly_Bus_Fare2,
    Monthly_vehicle3,
    Monthly_school4,
    Monthly_Insurance7,
    Monthly_credit_8,
    Monthly_Groceries9,
    Monthly_Utiliti10,
    Other_monthly_e11,
  }
} = checkoutFormModel;

//const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
const MOBILE_NO_REGEX = /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
//const ID_NO_REGEX = /^([a-zA-Z]{2}\d^[a-zA-Z]{1}\d{2})$/;
const PASSPORT_REGEX = /[a-zA-Z]{2}\d{6}/;
const COUNTRY_REGEX = /^[A-Za-z]+$/;

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  Yup.object().shape({
    //personal details page
     [Resedential_Address17.name]: Yup.string().min(5, "Enter a valid residential address").required(`${Resedential_Address17.requiredErrorMsg}`),
    [Postal_Address20.name]: Yup.string().min(5, "Enter a valid postal address").required(`${Postal_Address20.requiredErrorMsg}`),
    [ID_No6.name]: Yup.string().min(10, "ID Number too short and invalid").max(15, "ID Number is too long and invalid").required(`${ID_No6.requiredErrorMsg}`),
    [Passport_No7.name]: Yup.string().min(5, "Enter a valid passport number").max(8, "Enter a valid passport number"),
    [No_of_dependents9.name]: Yup.number().typeError("Enter numbers only").max(99, "Please enter a reasonable number of dependents"),
    [profession_cd_Profession11.name]: Yup.string(),
    [Nationality10.name]: Yup.string().required(`${Nationality10.requiredErrorMsg}`).matches(COUNTRY_REGEX, "Enter a valid nationality"),
    [Mobile_No214.name]:
      Yup
        .string()
        .matches(MOBILE_NO_REGEX, "Please enter a valid mobile number")
        .required(`${Mobile_No214.requiredErrorMsg}`),
    [LandlineHome_phone16.name]: Yup.number().typeError("Enter numbers only"), 
  }),

   Yup.object().shape({
  //   //employment details page
      [Employer_Physic3.name]: Yup.string() .required(`${Employer_Physic3.requiredErrorMsg}`),
    [Employer1.name]: Yup.string().required(`${Employer1.requiredErrorMsg}`),
     [Job_Title2.name]: Yup.string().required(`${Job_Title2.requiredErrorMsg}`),
     [Work_Phone_Number12.name]: Yup.number().typeError("Enter numbers only").required(`${Work_Phone_Number12.requiredErrorMsg}`),
     [Pay_Dayeg_25t12.name]: Yup.number().max(31, "Enter a number between 1 and 31 ").typeError("Enter numbers only").required(`${Pay_Dayeg_25t12.requiredErrorMsg}`),
    [Time_at_current7.name]: Yup.number().max(47, "Enter a number between 0 and 47 ").typeError("Enter numbers only").required(`${Time_at_current7.requiredErrorMsg}`),
     [Contract_Status9.name]: Yup.string(),//.required(`${Contract_Status9.requiredErrorMsg}`),
     [Previous_compan8.name]: Yup.string(),//.required(`${Previous_compan8.requiredErrorMsg}`) 

   }),
  Yup.object().shape({
    //banking details page
    [Bank_Name1.name]: Yup.string().required(`${Bank_Name1.requiredErrorMsg}`),
    [Account_Type6.name]: Yup.string() .required(`${Account_Type6.requiredErrorMsg}`),
    [Branch_Name3.name]: Yup.string() .required(`${Branch_Name3.requiredErrorMsg}`),
    [Account_Name2.name]: Yup.string().required(`${Account_Name2.requiredErrorMsg}`),
    [Bank_Branch_Code4.name]: Yup.string(),//.required(`${Bank_Branch_Code4.requiredErrorMsg}`),
    [Account_Number5.name]: Yup.number().typeError('Enter numbers only').required(`${Account_Number5.requiredErrorMsg}`),
 
  }), 

   Yup.object().shape({
    //references page #spouse details

    [TITLE_cd_Title1.name]: Yup.string(),
    [First_Name2.name]: Yup.string(),
    [Surname3.name]: Yup.string(),
    [Maiden_Name4.name]: Yup.string(),
    [Date_Of_Birth5.name]: Yup.string(),
    [spouseID_No6.name]: Yup.string(),
    [spousePassport_No7.name]: Yup.string(),
    [Gender_cd_Gender8.name]: Yup.string(),
    [spouseNo_of_dependents9.name]: Yup.string(),
    [spouseNationality10.name]: Yup.string(),
    [spouse_profession_cd_Profession11.name]: Yup.string(),
    [Mobile_No12.name]: Yup
      .string()
      .matches(MOBILE_NO_REGEX, "Please enter a valid mobile number"),
    [Mobile_no213.name]: Yup
      .string()
      .matches(MOBILE_NO_REGEX, "Please enter a valid mobile number"),
    [Email_address14.name]: Yup.string().email('Enter a valid email'),
    [Landline15.name]: Yup.string(),
    [Residential_Address16.name]: Yup.string(),
    [Postal_Address17.name]: Yup.string(),

    ////references # next of keen details 
    [GuarantorRelationship_cd_relation_to_client.name]: Yup.string(),
    [TITLE_cd_Title5.name]: Yup.string(),
    [name.name]: Yup.string(),
    [Surname6.name]: Yup.string(),
    [Mobile_No8.name]:  Yup
    .string()
    .matches(MOBILE_NO_REGEX, "Please enter a valid mobile number"),
    [Email_Address9.name]: Yup.string().email('Enter a valid email'),
    [LandlineHomephone10.name]: Yup.number().typeError('Enter numbers only'),
    [Residential_Address11.name]: Yup.string(),
    [Employer_Physic12.name]: Yup.string(),
    [Work_Phone_no13.name]: Yup.number().typeError('Enter numbers only'),
    [Gender_cd_Gender19.name]: Yup.string(),
    [dateOfBirth.name]: Yup.string(),
    [ID_number17.name]: Yup.string(),
    [Passport_number18.name]: Yup.string(),
    [Nationality20.name]: Yup.string(),
    [profession_cd_Profession21.name]: Yup.string(),
    [Postal_Address22.name]: Yup.string(),
    [Employer23.name]: Yup.string(),
    [Mobile_Number24.name]: Yup.number().typeError('Enter numbers only'),
    [Number_of_Dependency25.name]: Yup.number().typeError('Enter numbers only'),

    //references #details of relatives that stay with you
    [relativesTITLE_cd_Title1.name]: Yup.string(),
    [relativesFirst_Name2.name]: Yup.string(),
    [relativesSurname3.name]: Yup.string(),
    [Relationship4.name]: Yup.string(),
    [Mobile_No6.name]: Yup.string().matches(MOBILE_NO_REGEX, " Enter a valid mobile number"),
    [Email_Address7.name]: Yup.string().email('Enter a valid email address'),
    [Home_Landline8.name]: Yup.number().typeError('Enter numbers only'),
    [Resedential_Address9.name]: Yup.string(),
    [Business_Address10.name]: Yup.string(),
    [Occupation5.name]: Yup.string(),
    [Work_phone_no11]: Yup.number().typeError('Enter numbers only'),

    //references #details of relatives that dont stay with you
    [First_Name21111.name]: Yup.string(),
    [Surname31010.name]: Yup.string(),
    [Relationship599.name]: Yup.string(),
    [Occupation488.name]: Yup.string(),
    [Mobile_No677.name]: Yup.string().matches(MOBILE_NO_REGEX, "Enter a valid mobile number"),
    [Email_address766.name]: Yup.string().email("Enter a valid email"),
    [Home_landlineH855.name]: Yup.string(),
    [Resedential_add4.name]: Yup.string(),
    [Work_phone_no1033.name]: Yup.number().typeError('Enter numbers only'),

  }),
  Yup.object().shape({
    //montly expenditure #####INCOME
    [Gross_Incomepe1.name]: Yup.number().typeError('Enter numbers only').required(`${Gross_Incomepe1.requiredErrorMsg}`),
    [Net_Income_per2.name]: Yup.number().typeError('Enter numbers only').required(`${Net_Income_per2.requiredErrorMsg}`),
    [Other_Incomesp3.name]: Yup.number().typeError('Enter numbers only'),
    // [Total_IncomeNe4.name]: Yup.number(),
    [Monthly_Rental1.name]: Yup.number().typeError('Enter numbers only'),
    [Monthly_Bus_Fare2.name]: Yup.number().typeError('Enter numbers only'),
    [Monthly_vehicle3.name]: Yup.number().typeError('Enter numbers only'),
    [Monthly_school4.name]: Yup.number().typeError('Enter numbers only'),
    [Monthly_Insurance7.name]: Yup.number().typeError('Enter numbers only'),
    [Monthly_credit_8.name]: Yup.number().typeError('Enter numbers only'),
    [Monthly_Groceries9.name]: Yup.number().typeError('Enter numbers only').required(`${Monthly_Groceries9.requiredErrorMsg}`),
    [Monthly_Utiliti10.name]: Yup.number().typeError('Enter numbers only').required(`${Monthly_Utiliti10.requiredErrorMsg}`),
    [Other_monthly_e11.name]: Yup.number().typeError('Enter numbers only'),

  }), 

];
