/* eslint-disable import/no-anonymous-default-export */
import checkoutFormModel from './checkoutFormModel';
const {
  formField: {
    //personals details page
    Resedential_Address17,
    Postal_Address20,
    ID_No6,
    Mobile_No214,
    LandlineHome_phone16,
    Passport_No7,
    No_of_dependents9,
    profession_cd_Profession11,
    Nationality10,

    //montly expenditure #####INCOME
    Gross_Incomepe1,
    Net_Income_per2,
    Other_Incomesp3,
    Total_IncomeNe4,
    Monthly_Rental1,
    Monthly_Bus_Fare2,
    Monthly_vehicle3,
    Monthly_school4,
    Monthly_Insurance7,
    Monthly_credit_8,
    Monthly_Groceries9,
    Monthly_Utiliti10,
    Other_monthly_e11,
    Net_IncomeTota12,
    //Documents Upload page
    Files
  

  }
} = checkoutFormModel;

export default {
  //personal details 
  [Resedential_Address17.name]: '',
  [Postal_Address20.name]: '',
  [ID_No6.name]: '',
  [Mobile_No214.name]: '',
  [LandlineHome_phone16.name]: '',
  [Passport_No7.name]: '',
  [No_of_dependents9.name]: '',
  [profession_cd_Profession11.name]: '',
  [Nationality10.name]: '',

 
 
  //montly expenditure #####INCOME
  [Gross_Incomepe1.name]: '',
  [Net_Income_per2.name]: '',
  [Other_Incomesp3.name]: '',
  [Total_IncomeNe4.name]: '',
  [Monthly_Rental1.name]: '',
  [Monthly_Bus_Fare2.name]: '',
  [Monthly_vehicle3.name]: '',
  [Monthly_school4.name]: '',
  [Monthly_Insurance7.name]: '',
  [Monthly_credit_8.name]: '',
  [Monthly_Groceries9.name]: '',
  [Monthly_Utiliti10.name]: '',
  [Other_monthly_e11.name]: '',
  [Net_IncomeTota12.name]: '',

  //[Files.name]:'',

};