import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  Item,
} from "@material-ui/core";
import useStyles from "./styles";
import formField from "../FormModel/checkoutFormModel";

function NextOfKinDetails(props) {
  const { formValues } = props;

  const classes = useStyles();

  JSON.stringify(formField);
  //console.log(formValues);
  // console.log(formField.formField.Passport_No7.label);
  return (
    <List>
      <ListItem className={classes.listItem}>
        <div className="flex flex-col md:flex-col">
        <h4 className="font-bold uppercase bg-gray-100 text-center py-2 rounded-lg">
            {" "}
            2: Next of Keen's Details
          </h4>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.GuarantorRelationship_cd_relation_to_client.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.GuarantorRelationship_cd_relation_to_client
                ? formValues.GuarantorRelationship_cd_relation_to_client : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.TITLE_cd_Title5.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.TITLE_cd_Title5 ? formValues.TITLE_cd_Title5 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.name.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.name ? formValues.name : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Surname6.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.Surname6 ? formValues.Surname6 : "Not provided" }
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Mobile_No8.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.Mobile_No8 ? formValues.Mobile_No8 : "Not provided" }
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Email_Address9.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Email_Address9 ? formValues.Email_Address9 : "Not provided"}
              </Typography>
            </Grid>
  
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.LandlineHomephone10.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.LandlineHomephone10 ? formValues.LandlineHomephone10 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Residential_Address11.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Residential_Address11 ? formValues.Residential_Address11 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Employer_Physic12.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Employer_Physic12 ? formValues.Employer_Physic12 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Work_Phone_no13.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Work_Phone_no13 ? formValues.Work_Phone_no13 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Gender_cd_Gender19.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Gender_cd_Gender19 ? formValues.Gender_cd_Gender19 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.dateOfBirth.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.dateOfBirth ? formValues.dateOfBirth : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.ID_number17.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.ID_number17 ? formValues.ID_number17 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Passport_number18.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Passport_number18 ? formValues.Passport_number18 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Nationality20.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Nationality20 ? formValues.Nationality20 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.profession_cd_Profession21.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.profession_cd_Profession21 ? formValues.profession_cd_Profession21 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Postal_Address22.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Postal_Address22 ? formValues.Postal_Address22 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Employer23.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Employer23 ? formValues.Employer23 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Mobile_Number24.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Mobile_Number24 ? formValues.Mobile_Number24 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Number_of_Dependency25.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Number_of_Dependency25 ? formValues.Number_of_Dependency25 : "Not provided"}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </ListItem>
    </List>
  );
}

export default NextOfKinDetails;
