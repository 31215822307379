import React, { useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import axios from "axios";
import { Buffer } from "buffer";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const FilesUpload = (props) => {
  const userData = useSelector((state) => state.user)
  const user = userData.user
  const [name, setName] = useState("");
  const [file, setFile] = useState("");
  const [status, setStatus] = useState("");
  const [statusError, setStatusError] = useState("");
  const navigate = useNavigate();
  const formData = new FormData();
  
//   function handleFile(e) {
//     setFile(e.target.files[0]);
//     const fname = file.name;
//     const ftype = fname.split('.').pop();
//    // console.log(file.name);
//     const ttye = file.type;
//     console.log(ttye.toLowerCase());

// /*     
//     if(ftype.toLowerCase() ==="pdf"){ submitFile();
//     }else{
//       alert("file type not allowed!!")
//     } */
//     var fttt = ftype.toLowerCase();
//        switch(fttt) {
//       case "pdf":
//         // submit
//         submitFile();
//         break;
//       case "png":
//         // submit
//         submitFile();
//         break;
//       case "jpg":
//         //submit
//         submitFile();
//         break;
//       case "xml":
//         submitFile();
//       default:
//         // file not allowed
//         alert("File type not allowed. Try again")
//     } 
//   }

function handleFile(e) {
  if (!file) {
    return;
  }
  setFile(e.target.files[0]);
  const fname = file.name;
  const ftype = fname.split('.').pop();
 // console.log(file.name);
  const ttye = file.type;
  console.log(ttye.toLowerCase());

/*     
  if(ftype.toLowerCase() ==="pdf"){ submitFile();
  }else{
    alert("file type not allowed!!")
  } */
  var fttt = ftype.toLowerCase();
     switch(fttt) {
    case "pdf":
      // submit
      submitFile();
      break;
    case "png":
      // submit
      submitFile();
      break;
    case "jpg":
      //submit
      submitFile();
      break;
    case "xml":
      submitFile();
    default:
      // file not allowed
      alert("File type not allowed. Try again")
  } 
}


 // console.log(file);
  function handleInput(e) {
    setName(file.name);
    //setDescription(e.target.value);
    console.log(file);
  }
  
  //submit file
  async function submitFile(e) {
    e.preventDefault();

    let apiHeaders = {
      Authorization:
        "Basic " + Buffer.from("webuser:Demo@#2022").toString("base64"),
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Allow_Methods: ["PUT"],
      Allow_Origin: ["*"],
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": "https://clubplus-backend.onrender.com",
      "x-api-key": "oHJ0G18QYt2SMVNpogzio8WttytilwE17tuAky7p",
      "X-Fineract-Platform-TenantId": "clubplus",
    };

    try {
      const formData = new FormData();

      formData.append("file", file);
      formData.append("name", file.name);
      //formData.append("description", description);

      const response = await axios.post(
        "https://api.demo.irl.musoniservices.com/v1/ml_client_details/"+ user.id +"/documents",
        formData,
        {
          headers: apiHeaders,
          mode: "cors",
          withCredentials: true,
          credentials: "same-origin",
          // onUploadProgress: onUploadProgress,
        }
      );
      // console.log(percentage);

      console.log(response.status);
      if (response.status === 200) {
        alert("Document uploaded successfully");
      }
     
      // console.log(percentage);
    } catch (err) {
      console.error(err);
      alert("Error uploading file, please try again!!");
    }
  }



  return (
    <div className="bg-white">
      <div class="max-w-2xl py-2 px-5 m-auto w-full bg-gray-100 mt-4 rounded-lg">
        <h1 className="font-bold text-lg pb-4 text-center">
        Upload Documents
        </h1>
        {status && (
          <div
            className="bg-green-300 rounded-lg py-3 px-6 text-base text-gray-700 mb-3"
            role="alert"
          >
            {<p>{status}</p>}
          </div>
        )}
        {statusError && (
          <div
            className="bg-red-100 rounded-lg py-3 px-6 text-base text-red-700 mb-3"
            role="alert"
          >
            {<p>{statusError}</p>}
          </div>
        )}

        <form >
          <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
          <h7 className="font-bold text-sm pb-4 text-center">Current Payslip</h7><br></br>
          <label className=" w-auto pb-4 text-center text-xs text-red-500 py-4">
                UPLOAD in JPG/PNG/PDF/XML format
              </label>

              <label class="block bg-gray-300 pl-4 py-8 rounded-lg">
                <span class="sr-only bg-black">Choose File</span>
                <TextField
                  type="file"
                  name="file"
                  required
                  onChange={handleFile}
                  class="block w-full text-sm text-white file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300"
                />
              </label>
        </Grid>
        <Grid item xs={12} sm={6}>
        <h7 className="font-bold text-sm pb-4 text-center">1 Months Bank Statement For Loan Top Up</h7><br></br>
        <label className=" w-auto pb-4 text-center text-xs text-red-500 py-4">
                UPLOAD in JPG/PNG/PDF/XML format
              </label>

              <label class="block bg-gray-300 pl-4 py-8 rounded-lg">
                <span class="sr-only bg-black">Choose File</span>
                <TextField
                  type="file"
                  name="file"
                  required
                  onChange={handleFile}
                  class="block w-full text-sm text-white file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300"
                />
              </label>
        </Grid>
        <Grid item xs={12} sm={6}>
        <h7 className="font-bold text-sm pb-4 text-center">1 Month Bank Statement For Loan Renewal</h7><br></br>
          <label className=" w-auto pb-4 text-center text-xs text-red-500 py-4">
                UPLOAD in JPG/PNG/PDF/XML format
              </label>

              <label class="block bg-gray-300 pl-4 py-8 rounded-lg">
                <span class="sr-only bg-black">Choose File</span>
                <TextField
                  type="file"
                  name="file"
                  required
                  onChange={handleFile}
                  class="block w-full text-sm text-white file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-grey-100 file:text-grey-700 hover:file:bg-gray-300"
                />
              </label>
        </Grid>
      </Grid>
        </form>
      </div>
    </div>
  );
};

export default FilesUpload;
