import React from "react";
const LoanStatus = () => {

    return (
        <div className="px-5 pt-10 ">
            <div class="bg-gray-100 w-auto rounded-lg  h-60 ">
                <div className="flex flex-col items-center justify-center" >
                    <h3 class="text-center text-4xl font-bold pt-8">Loan Status</h3>
                    <div className="flex gap-5 pt-5 w-full px-40 ">
                        <h1 className=" w-1/3 text-center uppercase">Processing application</h1>
                        <h1 className=" w-1/3 text-center uppercase">approval / rejected</h1>
                        <h1 className=" w-1/3 text-center uppercase">funded</h1>
                    </div>
                    <div className="flex gap-5 pt-5 w-full px-40">
                        <h1 className="bg-red-500 w-1/3 rounded-full"></h1>
                        <h1 className="bg-white w-1/3 h-10 rounded-full"></h1>

                        <h1 className="bg-white w-1/3 rounded-full"></h1>


                    </div>


                </div>


            </div>

        </div>
    )
}

export default LoanStatus