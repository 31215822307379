import React, { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Formik, Form } from "formik";

import AddressForm from "./Forms/PersonalDetailsForm";
import EmploymentDetails from "./Forms/EmploymentDetails";
import BankingDetails from "./Forms/BankingDetails";
import UploadDocuments from "./Forms/UploadDocuments";
import References from "./Forms/References";
import MonthlyExpenditure from "./Forms/MonthlyExpenditure";
import Review from "./Review";
import CheckoutSuccess from "./CheckoutSuccess";
import validationSchema from "./FormModel/validationSchema";
import checkoutFormModel from "./FormModel/checkoutFormModel";
import formInitialValues from "./FormModel/formInitialValues";

import axios from "axios";
import { Buffer } from "buffer";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";

const steps = [
  "Personal Details",
  "Employment Details",
  "Banking Details",
  "References",
  "Monthly Expenditure",
  "Review",
];
const { formId, formField } = checkoutFormModel;

function _renderStepContent(step) {
  switch (step) {
    case 0:
      return <AddressForm formField={formField} />;
    case 1:
      return <EmploymentDetails formField={formField} />;
    case 2:
      return <BankingDetails formField={formField} />;
    case 3:
      return <References formField={formField} />;
    case 4:
      return <MonthlyExpenditure formField={formField} />;

    case 5:
      return <Review />;
    default:
      return <div>Not Found</div>;
  }
}

export default function ApplyLoanPage() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  function _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  // const apiResponse = reactLocalStorage.getObject("apiResponse");
  // const clientId = apiResponse.clientId;
  const userData = useSelector((state) => state.user);
  const user = userData.user;
  //console.log(user)
  async function _submitForm(values, actions) {
    await _sleep(1000);
    JSON.stringify(values);

    // console.log(values.copyOfID);
    //  alert(JSON.stringify(values, null, 2));

    //headers
    let apiHeaders = {
      Authorization:
        "Basic " + Buffer.from("webuser:Demo@#2022").toString("base64"),
      "Content-Type": "application/json",
      Accept: "application/json",
      Allow_Methods: ["PUT"],
      Allow_Origin: ["*"],
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": "https://clubplus-backend.onrender.com",
      "x-api-key": "oHJ0G18QYt2SMVNpogzio8WttytilwE17tuAky7p",
      "X-Fineract-Platform-TenantId": "clubplus",
    };
    ///clientPersonalDetails
    const clientPersonalDetails = {
      locale: "en",
      dateFormat: "yyyy-MM-dd",
      Resedential_Address17: values.Resedential_Address17,
      Postal_Address20: values.Postal_Address20,
      ID_No6: values.ID_No6,
      Mobile_No214: values.Mobile_No214,
      LandlineHome_phone16: values.LandlineHome_phone16,
      Passport_No7: values.Passport_No7,
      No_of_dependents9: values.No_of_dependents9,
      // profession_cd_Profession11: values.profession_cd_Profession11,
      Nationality10: values.Nationality10,
    };
    // //   //axios api call  //send personal details to datatables api
    axios({
      method: "PUT",
      url:
        "https://api.demo.irl.musoniservices.com/v1/datatables/cct_PERSONALDETAILS/" +
        user.id,
      data: clientPersonalDetails,
      mode: "no-cors",
      withCredentials: true,
      credentials: "same-origin",
      headers: apiHeaders,
    })
      .then(async function (response) {
        console.log(response);
      })
      .catch(async function (error) {
        console.log(error);
      });
    // //console.log(clientDetails)
    ///clientEmploymentDetails
      const clientEmploymentDetails = {
        locale: "en",
        dateFormat: "yyyy-MM-dd",
        Employer1: values.Employer1,
        Employer_Physic3: values.Employer_Physic3,
        Job_Title2: values.Job_Title2,
        Time_at_current7: values.Time_at_current7,
        Previous_compan8: values.Previous_compan8,
        Contract_Status9: values.Contract_Status9,
        Pay_Dayeg_25t12: values.Pay_Dayeg_25t12,
        Work_Phone_Number12: values.Work_Phone_Number12,
      };
    //   // // //Banking clientDetails
      const clientBankingDetails = {
        locale: "en",
        dateFormat: "yyyy-MM-dd",
        Bank_Name1: values.Bank_Name1,
        Account_Name2: values.Account_Name2,
        Branch_Name3: values.Branch_Name3,
        Bank_Branch_Code4: values.Bank_Branch_Code4,
        Account_Number5: values.Account_Number5,
        Account_Type6: values.Account_Type6,
      };
    //   // // clientSpouseDetails
      const clientSpouseDetails = {
        locale: "en",
        dateFormat: "yyyy-MM-dd",
        TITLE_cd_Title1: values.TITLE_cd_Title1,
        First_Name2: values.First_Name2,
        Surname3: values.Surname3,
        Maiden_Name4: values.Maiden_Name4,
        Date_Of_Birth5: values.Date_Of_Birth5,
        ID_No6: values.spouseID_No6,
        Passport_No7: values.spousePassport_No7,
        Gender_cd_Gender8: values.Gender_cd_Gender8,
        No_of_dependents9: values.spouseNo_of_dependents9,
        Nationality10: values.spouseNationality10,
        profession_cd_Profession11: values.spouse_profession_cd_Profession11,
        Mobile_No12: values.Mobile_No12,
        Mobile_no213: values.Mobile_no213,
        Email_address14: values.Email_address14,
        Landline15: values.Landline15,
        Residential_Address16: values.Residential_Address16,
        Postal_Address17: values.Postal_Address17,
      };
    //   // ///client next of keen details
      const nextOfKin = {
        locale: "en",
        dateFormat: "yyyy-MM-dd",
        GuarantorRelationship_cd_relation_to_client:
          values.GuarantorRelationship_cd_relation_to_client,
        TITLE_cd_Title5: values.TITLE_cd_Title5,
        name: values.name,
        Surname6: values.Surname6,
        Mobile_No8: values.Mobile_No8,
        Email_Address9: values.Email_Address9,
        LandlineHomephone10: values.LandlineHomephone10,
        Residential_Address11: values.Residential_Address11,
        Employer_Physic12: values.Employer_Physic12,
        Work_Phone_no13: values.Work_Phone_no13,
        Gender_cd_Gender19: values.Gender_cd_Gender19,
        dateOfBirth: values.dateOfBirth,
        ID_number17: values.ID_number17,
        Passport_number18: values.Passport_number18,
        Nationality20: values.Nationality20,
        profession_cd_Profession21: values.profession_cd_Profession21,
        Postal_Address22: values.Postal_Address22,
        Employer23: values.Employer23,
        Mobile_Number24: values.Mobile_Number24,
        Number_of_Dependency25: values.Number_of_Dependency25,
      };

    //   // //client relativesWhoDontStayWithYou details
      const relativesWhoDontStayWithYou = {
        locale: "en",
        dateFormat: "yyyy-MM-dd",
        TITLE_cd_Title1: values.relativesTITLE_cd_Title1,
        First_Name2: values.relativesFirst_Name2,
        Surname3: values.relativesSurname3,
        Relationship4: values.Relationship4,
        Mobile_No6: values.Mobile_No6,
        Email_Address7: values.Email_Address7,
        Home_Landline8: values.Home_Landline8,
        Resedential_Address9: values.Resedential_Address9,
        Business_Address10: values.Business_Address10,
        Occupation5: values.Occupation5,
        Work_phone_no11: values.Work_phone_no11,
      };
    //   ///client second relativesWhoDontStayWithYou details
      const secondRelativesWhoDontStayWithYou = {
        locale: "en",
        dateFormat: "yyyy-MM-dd",
        First_Name21111: values.First_Name21111,
        Surname31010: values.Surname31010,
        Relationship599: values.Relationship599,
        Occupation488: values.Occupation488,
        Mobile_No677: values.Mobile_No677,
        Email_address766: values.Email_address766,
        Home_landlineH855: values.Home_landlineH855,
        Resedential_add4: values.Resedential_add4,
        Work_phone_no1033: values.Work_phone_no1033,
      };

    //   //   ///monthly expenditure, income and fixed expenditure
      const monthlyExpenditure = {
        locale: "en",
        dateFormat: "yyyy-MM-dd",
        Gross_Incomepe1: values.Gross_Incomepe1,
        Net_Income_per2: values.Net_Income_per2,
        Other_Incomesp3: values.Other_Incomesp3,
        Total_IncomeNe4: values.Total_IncomeNe4,

     /*    // ##expenditure
        Monthly_school4: values.Monthly_school4,
        Monthly_Insurance7: values.Monthly_Insurance7,
        Monthly_credit_8: values.Monthly_credit_8,
        Monthly_Groceries9: values.Monthly_Groceries9,
        Monthly_Utiliti10: values.Monthly_Utiliti10,
        Other_monthly_e11: values.Other_monthly_e11,
        Net_IncomeTota12: values.Net_IncomeTota12, */
      };



      //send employment details to the API
      // https://api.demo.irl.musoniservices.com/v1/datatables/cct_EmploymentDetails/444
      axios({
        method: "PUT",
        url: "https://api.demo.irl.musoniservices.com/v1/datatables/cct_EmploymentDetails/" + user.id,
        data: clientEmploymentDetails,
        mode: "no-cors",
        withCredentials: true,
        credentials: "same-origin",
        headers: apiHeaders,
      })
        .then(async function (response) {
          console.log(response);
        })
        .catch(async function (error) {
          console.log(error);
        });

      //// send banking details to the API
      axios({
        method: "PUT",
        url: "https://api.demo.irl.musoniservices.com/v1/datatables/cct_BankDetails/"+ user.id,
        data: clientBankingDetails,
        mode: "no-cors",
        withCredentials: true,
        credentials: "same-origin",
        headers: apiHeaders,
      })
        .then(async function (response) {
          console.log(response);
        })
        .catch(async function (error) {
          console.log(error);
        });
      // // send banking details to the API
      // https://api.demo.irl.musoniservices.com/v1/datatables/cct_SpouseDetails/444
      axios({
        method: "PUT",
        url: "https://api.demo.irl.musoniservices.com/v1/datatables/cct_SpouseDetails/"+ user.id,
        data: clientSpouseDetails,
        mode: "no-cors",
        withCredentials: true,
        credentials: "same-origin",
        headers: apiHeaders,
      })
        .then(async function (response) {
          console.log(response);
        })
        .catch(async function (error) {
          console.log(error);
        });
      //// send bnext of keen details to the API
      axios({
        method: "POST",
        url:
          "https://api.demo.irl.musoniservices.com/v1/datatables/ml_client_next_of_kin/" +
          user.id,
        data: nextOfKin,
        mode: "no-cors",
        withCredentials: true,
        credentials: "same-origin",
        headers: apiHeaders,
      })
        .then(async function (response) {
          console.log(response);
        })
        .catch(async function (error) {
          console.log(error);
        });
      //// send Detailsofrelativeswhodonotlivewithyou to the API
      axios({
        method: "PUT",
        url:
          "https://api.demo.irl.musoniservices.com/v1/datatables/cct_Detailsofrelativeswhodonotlivewithyou/" +
          user.id,
        data: relativesWhoDontStayWithYou,
        mode: "no-cors",
        withCredentials: true,
        credentials: "same-origin",
        headers: apiHeaders,
      })
        .then(async function (response) {
          console.log(response);
        })
        .catch(async function (error) {
          console.log(error);
        });

      ////send SecondRelativewhodonotlivewithyou to API
      axios({
        method: "PUT",
        url:
          "https://api.demo.irl.musoniservices.com/v1/datatables/cct_SecondRelativewhodonotlivewithyou/" +
          user.id,
        data: secondRelativesWhoDontStayWithYou,
        mode: "no-cors",
        withCredentials: true,
        credentials: "same-origin",
        headers: apiHeaders,
      })
        .then(async function (response) {
          console.log(response);
        })
        .catch(async function (error) {
          console.log(error);
        });

      // send monthlyExpenditure to API
      axios({
        method: "POST",
        url:
          "https://api.demo.irl.musoniservices.com/v1/datatables/cct_MonthlyIncome/" +
          user.id,
        data: monthlyExpenditure,
        mode: "no-cors",
        withCredentials: true,
        credentials: "same-origin",
        headers: apiHeaders,
      })
        .then(async function (response) {
          console.log(response);
        })
        .catch(async function (error) {
          console.log(error);
        });

     // fixedMonthlyExpenditure
      const fixedMonthlyExpenditure = {
        locale: "en",
        // dateFormat: "yyyy-MM-dd",

        Monthly_Rental1: values.Monthly_Rental1,
        Monthly_Bus_Fare2: values.Monthly_Bus_Fare2,
        Monthly_Rental1: values.Monthly_Rental1,
        Monthly_school4: values.Monthly_school4,
        Monthly_Insurance7: values.Monthly_Insurance7,
        Monthly_credit_8: values.Monthly_credit_8,
        Monthly_Groceries9: values.Monthly_Groceries9,
        Monthly_Utiliti10: values.Monthly_Utiliti10,
        Other_monthly_e11: values.Other_monthly_e11,
        Net_IncomeTota12: values.Net_IncomeTota12,
      };

    // send fixedMonthlyExpenditure to API
    axios({
      method: "POST",
      url:
        "https://api.demo.irl.musoniservices.com/v1/datatables/cct_FixedMonthlyExpenditure/" +
        user.id,
      data: fixedMonthlyExpenditure,
      mode: "no-cors",
      withCredentials: true,
      credentials: "same-origin",
      headers: apiHeaders,
    })
      .then(async function (response) {
        console.log(response);
      })
      .catch(async function (error) {
        console.log(error);
      });
    //set setSubmitting to true .........
    actions.setSubmitting(true);
    //next step ...............
    setActiveStep(activeStep + 1);
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4" align="center">
        Apply For A New Loan
      </Typography>
      <div className="hidden lg:flex">
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <React.Fragment>
        {activeStep === steps.length ? (
          <CheckoutSuccess />
        ) : (
          <Formik
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form id={formId}>
                {_renderStepContent(activeStep)}

                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={_handleBack} className={classes.button}>
                      Previous
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {isLastStep ? "Submit Application" : "Next"}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}
