import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  Item,
} from "@material-ui/core";
import useStyles from "./styles";
import formField from "../FormModel/checkoutFormModel";

function BankingDetails(props) {
  const { formValues } = props;
 
  const classes = useStyles();

  JSON.stringify(formField);
  //console.log(formValues);
  // console.log(formField.formField.Passport_No7.label);
  return (
    <List>
      <ListItem className={classes.listItem}>
        <div className="flex flex-col md:flex-col">
        <h3 className="uppercase font-bold test-2xl text-center py-4 bg-gray-200 rounded-lg">Banking Details</h3>


          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Bank_Name1.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Bank_Name1}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Branch_Name3.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Branch_Name3 ? formValues.Branch_Name3 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Bank_Branch_Code4.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.Bank_Branch_Code4 ? formValues.Bank_Branch_Code4 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Account_Number5.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.Account_Number5}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Pay_Dayeg_25t12.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.Pay_Dayeg_25t12}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Account_Type6.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Account_Type6 ? formValues.Account_Type6  : "Not provided"}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </ListItem>
    </List>
  );
}

export default BankingDetails;
