import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import Home from "./components/Home";
import Register from "./components/Register";
import Login from "./components/Login/Login";
import Otp from "./components/Login/OtpVerify";
import PendingVerify from "./components/Dashboard/PendingVerification";
import Dashboard from "./components/Dashboard";
import Loan from "./components/Loan";
import LoanRenewal from "./components/LoanRenewal";
import ForgotPassword from "./components/ForgotPassword";
import Currency from "./components/Currency";
import LoanRenewalCurrency from "./components/LoanRenewalCurrency";
import LoanApplyCurrency from "./components/LoanApplyCurrency";
import CalculatorCurrency from "./components/CalculatorCurrency";
import LoanCalculator from "./components/LoanCalculator";
import Otpindex from "./components/Otpindex";
import Apply from "./components/Apply";
import UploadID from "./applyloan/UploadDocuments/UploadID";
import CurrentPayslip from "./renewloan/UploadDocuments/CurrentPayslip";
import ForeignCurrencyPayslip from "./applyloan/UploadDocuments/ForeignCurrencyPayslip";
import ProofOfResidence from "./applyloan/UploadDocuments/ProofOfResidence";
import PassportPhoto from "./applyloan/UploadDocuments/PassportPhoto";
import NextOfKinID from "./applyloan/UploadDocuments/NextOfKinID";
import UploadSuccess from "./applyloan/UploadDocuments/UploadSuccess/UploadSuccess";
import RenewLoan from "./components/RenewLoan";
import BankStatement from "./applyloan/UploadDocuments/BankStatement";
import BankStatementForTopup from "./renewloan/UploadDocuments/BankStatementForTopup";
import BankStatementForRenewal from "./renewloan/UploadDocuments/BankStatementForRenewal";
import PrivateRoutes from "./components/PrivateRoutes";
import RenewUS from "./components/RenewUS";
import RenewZW from "./components/RenewZW";
import ApplyZW from "./components/ApplyZW";
import ApplyUS from "./components/ApplyUS";
import SelectCurrency from "./components/SelectCurrency";
import Profile from "./components/Profile";

function App() {
  return (
    <div className="container ">
      <BrowserRouter>
        <Routes>
          <Route path="/Login" element={<Login />}></Route>
          <Route path="/otp" element={<Otp />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/currency" element={<Currency />}></Route>
          <Route path="/loan" element={<Loan />}></Route>
          <Route path="/loan-renewal" element={<LoanRenewal />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/currency" element={<Currency />}></Route>
          <Route path="/select-currency" element={<SelectCurrency />}></Route>
          <Route path="/pending-verify" element={<PendingVerify />}></Route>
          <Route
            path="/loan-renewal-currency"
            element={<LoanRenewalCurrency />}
          ></Route>
          <Route
            path="/apply-currency"
            element={<LoanApplyCurrency />}
          ></Route>
          <Route
            path="/calculator-currency"
            element={<CalculatorCurrency />}
          ></Route>
          <Route path="/loan-calculator" element={<LoanCalculator />}></Route>
          <Route path="/apply-loan" element={<Apply />}></Route>
          {/* ///uploads  */}
          <Route path="/current-payslip" element={<CurrentPayslip />}></Route>
          <Route path="/foreign-currency-payslip" element={<ForeignCurrencyPayslip />}></Route>
          

          
          <Route
            path="/proof-of-residence"
            element={<ProofOfResidence />}
          ></Route>
          <Route path="/passport-photo" element={<PassportPhoto />}></Route>
        <Route path="/upload-id" element={<UploadID/>}></Route>
          <Route path="/spouse-id" element={<NextOfKinID />}></Route>
          <Route path="/upload-success" element={<UploadSuccess />}></Route>
          <Route path="/renew-loan" element={<RenewLoan />}></Route>
          <Route path="/renew-us" element={<RenewUS />}></Route>
          <Route path="/renew-zw" element={<RenewZW />}></Route>
          <Route path="/apply-us" element={<ApplyUS />}></Route>
          <Route path="/apply-zw" element={<ApplyZW />}></Route>
          <Route path="/bank-statement" element={<BankStatement/>}></Route>

          <Route path="/bankstmt" element={<BankStatementForTopup/>}></Route>
          <Route path="/bankstmt-renewal" element={<BankStatementForRenewal />}></Route>

          {/* </Route> */}
          {/* /// public routes */}
          <Route path="/" element={<Home />}></Route>
          <Route path="/otpindex" element={<Otpindex />}></Route>
          <Route path="/register" element={<Register />}></Route>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/profile-modify" element={<Profile />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
