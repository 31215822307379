
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from './rootReducer';
import * as reduxPersist from 'redux-persist';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';


/*import userReducer from '../Features/userSlice';
import userOtpReducer from '../Features/userOtpSlice';*/




const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [reduxPersist.FLUSH, reduxPersist.REHYDRATE, reduxPersist.PAUSE, reduxPersist.PERSIST, reduxPersist.PURGE, reduxPersist.REGISTER],
      },
    }),

})

export const persistor = persistStore(store)





