import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
} from "@material-ui/core";
import useStyles from "./styles";
import formField from "../FormModel/checkoutFormModel";

function RelativesDetails(props) {
  const { formValues } = props;

  const classes = useStyles();

  JSON.stringify(formField);
  //console.log(formValues);
  // console.log(formField.formField.Passport_No7.label);
  return (
   <>
    <List>
      <ListItem className={classes.listItem}>
        <div className="flex flex-col md:flex-col">
        <h4 className="font-bold uppercase bg-gray-100 text-center py-2 rounded-lg">
            {" "}
            2: Details Of Relatives Who Do Not Stay With You 
          </h4>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          > 
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.relativesTITLE_cd_Title1.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.relativesTITLE_cd_Title1
                ? formValues.relativesTITLE_cd_Title1 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.relativesFirst_Name2.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.relativesFirst_Name2 ? formValues.relativesFirst_Name2 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.relativesSurname3.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.relativesSurname3 ? formValues.relativesSurname3 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Relationship4.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.Relationship4 ? formValues.Relationship4 : "Not provided" }
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Mobile_No6.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.Mobile_No6 ? formValues.Mobile_No6 : "Not provided" }
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Email_Address7.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Email_Address7 ? formValues.Email_Address7 : "Not provided"}
              </Typography>
            </Grid>
  
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Home_landlineH855.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Home_landlineH855 ? formValues.Home_landlineH855 : "Not provided"}
              </Typography>
            </Grid>
      

            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Resedential_Address9.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Resedential_Address9 ? formValues.Resedential_Address9 : "Not provided"}
              </Typography>
            </Grid>
            
            
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Home_landlineH855.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Home_landlineH855 ? formValues.Home_landlineH855 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Occupation5.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Occupation5 ? formValues.Occupation5 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Work_phone_no11.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Work_phone_no11 ? formValues.Work_phone_no11 : "Not provided"}
              </Typography>
            </Grid>
          
          </Grid>
        </div>
      </ListItem>
    </List>
    <List>
      <ListItem className={classes.listItem}>
        <div className="flex flex-col md:flex-col">
        <h4 className="font-bold uppercase bg-gray-100 text-center py-2 rounded-lg">
            {" "}
            2: Details Of Second Relatives Who Do Not Stay With You 
          </h4>
   
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          > 
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.First_Name21111.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.First_Name21111
                ? formValues.First_Name21111 : "Not provided"}
              </Typography>
            </Grid>
           
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Surname31010.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.Surname31010 ? formValues.Surname31010 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Relationship599.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.Relationship599 ? formValues.Relationship599 : "Not provided" }
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Occupation488.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.Occupation488 ? formValues.Occupation488 : "Not provided" }
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Mobile_No677.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Mobile_No677 ? formValues.Mobile_No677 : "Not provided"}
              </Typography>
            </Grid>
  
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Email_address766.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Email_address766 ? formValues.Email_address766 : "Not provided"}
              </Typography>
            </Grid>
      

           
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Resedential_add4.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Resedential_add4 ? formValues.Resedential_add4 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Resedential_add4.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Resedential_add4 ? formValues.Resedential_add4 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Work_phone_no1033.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Work_phone_no1033 ? formValues.Work_phone_no1033 : "Not provided"}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </ListItem>
    </List></>
  );
}

export default RelativesDetails;
