import React from "react";
import { useState } from "react";
import { Buffer } from "buffer";
import login from "../images/register.svg";
import logoo from "../images/logoo.png";
import { useFormik } from "formik";
import { validationSchema } from "../validations/RegisterValidation";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [apiError, setApiError] = useState(null);
  const [apiSuccess, setApiSuccess] = useState(null);
  const [visible, setIsVisible] = useState(true);

  const navigate = useNavigate();

  if (apiError != null) {
    setTimeout(function () {
      setIsVisible(false);
    }, 5000);
  }

  const onSubmit = (values) => {
    //alert(JSON.stringify(values));

    const officeId = 1;
    const locale = "en";
    const dateFormat = "dd MMMM yyyy";
    const active = false;
    const firstname = values.firstname;
    const lastname = values.lastname;
    const emailAddress = values.emailAddress;
    const mobileNo = values.mobileNo;
    const middlename = values.middlename;
    const genderId = values.genderId;
    const mobileNoSecondary = values.mobileNoSecondary;
    //  const submittedOnDate = Moment().format('DD MMMM YYYY')
    const activationDate = "15 July 2022";

    let apiHeaders = {
      Authorization:
        "Basic " + Buffer.from("webuser:Demo@#2022").toString("base64"),
      "Content-Type": "application/json",
      Accept: "application/json",
      Allow_Methods: ["HEAD, GET, POST, PUT, PATCH, DELETE, OPTION"],
      Allow_Origin: ["*"],
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": "*",
      "x-api-key": "oHJ0G18QYt2SMVNpogzio8WttytilwE17tuAky7p",
      "X-Fineract-Platform-TenantId": "clubplus",
    };

    let clientDetails = {
      firstname,
      lastname,
      emailAddress,
      mobileNo,
      locale,
      dateFormat,
      active,
      officeId,
      activationDate,
      middlename,
      genderId,
      mobileNoSecondary,
      // submittedOnDate
    };

    axios({
      method: "POST",
      url: "https://demo.irl.musoniservices.com:8443/api/v1/clients",
      data: clientDetails,
      mode: "cors",
      withCredentials: true,
      credentials: "origin",
      headers: apiHeaders,
    })
      .then(async function (response) {
        console.log(response.data);
        //send message with client id to the client

        const userData = response.data;
        const nozero = mobileNo.slice(1);
        //add +263 to mobileNo and
        const fullMobileNo = "263" + nozero;

        axios
          //.post("http://localhost:3001/sendSMS",
          .post("https://rest.bluedotsms.com/api/SendSMS", 
          {
           // phone: fullMobileNo,
            //clientID: userData.clientId,
            "api_id": "API5917286622",
            "api_password": "Password1",
             "sender_id": "CLUBPLUS",
             "phonenumber": fullMobileNo,
             //"message_id": "20060379",
             "brand": "CLUBPLUS",
             "sms_type": "P",
             "encoding": "T",
              //"sender_id": "CLUBPLUS",
              "templateid": null,
              "textmessage":"Club Plus Client ID is:\b "+userData.clientId,
              "V1": null,
              "V2": null,
              "V3": null,
              "V4": null,
              "V5": null
          })
          .then((res) => {
            // console.log(res);
            console.log(res.data);
            setApiSuccess(
              "We have sent you a message with your Client ID use it to login"
            );
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch(async function (error) {
        console.log(error);
        if (
          error.response.status == 403
        ) {
          setApiError(
            "Either primary or secondary mobile number has been registered"
          );
        } 
       
        
      });
  };
  //formik
  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      emailAddress: "",
      middlename: "",
      genderId: "",
      mobileNoSecondary: "",
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });
  // console.log("Error ", formik.errors)
  return (
    <div className="">
      <div className="flex h-screen bg-gray-100 md:bg-gray-100 mt-5">
        <div className="hidden lg:flex h-full">
          <img src={login} className="mx-auto" alt="clubplus" />
        </div>
        <div className="w-full flex flex-col justify-center lg:w-1/2">
          <div className="max-w-2xl mx-auto">
            <div className="bg-white rounded-lg max-w-sm p-4 sm:p-6 lg:p-8 dark:bg-gray-800 dark:border-gray-700">
              <a href="/">
                <img src={logoo} className="mx-auto" alt="clubplus" />
              </a>
              <form className="space-y-3" onSubmit={formik.handleSubmit}>
                <h3 className="text-xl text-black font-bold dark:text-white text-center">
                  Create an Account
                </h3>

                {apiError && visible && (
                  <div
                    className="bg-red-100 rounded-lg py-3 px-6 text-base text-red-700 mb-3"
                    role="alert"
                  >
                    {<p>{apiError}</p>}
                  </div>
                )}
                {apiSuccess && visible && (
                  <div
                    class="bg-green-200 rounded-lg py-3 px-6 text-base text-black mb-3"
                    role="alert"
                  >
                    {<p>{apiSuccess}</p>}
                  </div>
                )}
                <div className="flex flex-row justify-between space-x-3">
                  <div className="flex flex-col">
                    <input
                      type="text"
                      name="firstname"
                      value={formik.values.firstname}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="pr-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 focus:outline-none dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder="First name"
                      required=""
                    />
                    <span class="my-0 inline-flex text-sm text-red-700">
                      {formik.touched.firstname && formik.errors.firstname
                        ? formik.errors.firstname
                        : ""}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <input
                      type="text"
                      name="lastname"
                      value={formik.values.lastname}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="pl-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 focus:outline-none dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder="Last name"
                    />
                    <span className="my-0 inline-flex text-sm text-red-700">
                      {formik.touched.lastname && formik.errors.lastname
                        ? formik.errors.lastname
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="flex flex-row justify-between space-x-3">
                  <div className="flex flex-col">
                    <input
                      type="text"
                      name="middlename"
                      value={formik.values.middlename}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="pr-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 focus:outline-none dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder="Middle name"
                      required=""
                    />
                    <span class="my-0 inline-flex text-sm text-red-700">
                      {formik.touched.middlename && formik.errors.middlename
                        ? formik.errors.middlename
                        : ""}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <div className="pr-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 focus:outline-none dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
                      <select
                        type="text"
                        name="genderId"
                        value={formik.values.genderId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="" selected>
                          Select Gender :::::
                        </option>
                        <option value="218">Male</option>
                        <option value="219">Female</option>
                      </select>
                    </div>
                    <span className="my-0 inline-flex text-sm text-red-700">
                      {formik.touched.genderId && formik.errors.genderId
                        ? formik.errors.genderId
                        : ""}
                    </span>
                  </div>
                </div>
                <input
                  type="email"
                  name="emailAddress"
                  id="emailAddress"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 focus:outline-none dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  placeholder="Email"
                  onBlur={formik.handleBlur}
                />
                <span className="my-0 text-sm text-red-700">
                  {formik.touched.emailAddress && formik.errors.emailAddress
                    ? formik.errors.emailAddress
                    : ""}
                </span>

                <input
                  type="text"
                  name="mobileNo"
                  id="mobileNo"
                  value={formik.values.mobileNo}
                  onChange={formik.handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 focus:outline-none dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  placeholder="Phone Number"
                  onBlur={formik.handleBlur}
                />
                <span className="my-0 text-sm text-red-700 pb-2">
                  {formik.touched.mobileNo && formik.errors.mobileNo
                    ? formik.errors.mobileNo
                    : ""}
                </span>
                <div className="flex flex-col">
                  <input
                    type="text"
                    name="mobileNoSecondary"
                    value={formik.values.mobileNoSecondary}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="pl-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 focus:outline-none dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Secondary Mobile Number "
                  />
                  <span className="my-0 inline-flex text-sm text-red-700">
                    {formik.touched.mobileNoSecondary &&
                    formik.errors.mobileNoSecondary
                      ? formik.errors.mobileNoSecondary
                      : ""}
                  </span>
                </div>

                <div className="flex items-center justify-between">
                  <button
                    type="submit"
                    className="w-1/2 text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-md text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
                  >
                    Create account
                  </button>
                </div>
                <div className="text-sm font-medium text-gray-500 dark:text-gray-300 items-end">
                  Existing User:{" "}
                  <a href="/Login" className=" hover:underline text-red-400">
                    Login
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
