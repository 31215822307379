
// import React, { useState } from 'react';
// import { TextField, Select, Button, Typography } from '@material-ui/core';

// function LoanCalculator() {
//   const [loanAmount, setLoanAmount] = useState(0);
//   const [interestRate, setInterestRate] = useState(0);
//   const [loanTerm, setLoanTerm] = useState(0);
//   const [monthlyPayment, setMonthlyPayment] = useState(0);

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     const numerator = loanAmount * interestRate / 12;
//     const denominator = 1 - (1 + (interestRate / 12)) ** (-loanTerm);
//     const calculatedPayment = numerator / denominator;
//     setMonthlyPayment(calculatedPayment);
//   }

//   return (
//     <form onSubmit={handleSubmit}>
//       <TextField
//         label="Loan Amount"
//         type="number"
//         value={loanAmount}
//         onChange={(event) => setLoanAmount(event.target.value)}
//       />
//       <TextField
//         label="Interest Rate"
//         type="number"
//         value={interestRate}
//         onChange={(event) => setInterestRate(event.target.value)}
//       />
//       <Select
//         label="Loan Term (in years)"
//         value={loanTerm}
//         onChange={(event) => setLoanTerm(event.target.value)}
//       >
//         <option value={5}>5</option>
//         <option value={10}>10</option>
//         <option value={15}>15</option>
//         <option value={20}>20</option>
//         <option value={30}>30</option>
//       </Select>
//       <Button type="submit">Calculate</Button>
//       <Typography>Monthly Payment: {monthlyPayment}</Typography>
//     </form>
//   );
// }

// export default LoanCalculator;

import Footer from "./Footer";
import Header from "./Header";
import React, { useState, useEffect } from "react";

const LoanCalculator = () => {
  //hook defined
  const [input, setInput] = useState({
    num1: "",
    num2: "",
    num3: "",
  });
  const [sum, setSum] = useState(undefined);

  useEffect(() => {
    setSum(parseInt(input.num1) * parseInt(input.num2)* parseInt(input.num3)/100);
  }, [input]);

  //handle input change

  const handleInput = function (e) {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="bg-white">
      <Header />
      <div class="max-w-2xl py-8 my-8 px-5 m-auto bg-gray-100">
        <h1 className="font-bold text-xl sm:text-2xl pb-4 text-center">
          Loan Calculator
        </h1>
        <div class="grid grid-cols-2 gap-4 m-auto">
          <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
            <label className="">Loan Amount: Principal</label>
          </div>
          <div class="col-span-2 lg:col-span-1">
            <input
              type="text"
              class=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300"
              name="num1"
              value={input.num1}
              onChange={handleInput}
            />
          </div>
          <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
            <label className="">Loan Period: Rate of interest per annum</label>
          </div>
          <div class="col-span-2 lg:col-span-1">
            <input
              type="text"
              class=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300"
              placeholder=" "
              name="num2"
              value={input.num2}
              onChange={handleInput}
            />
          </div>

          <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
            <label className="">Time Period: (Years)</label>
          </div>
          <div class="col-span-2 lg:col-span-1">
            <input
              type="text"
              class=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300"
              placeholder=" "
              name="num3"
              value={input.num3}
              onChange={handleInput}
            />
          </div>
          <div class="col-span-1" >

          <h1 className="font-bold text-2xl text-right">
          ${ sum ? sum : "0"}

          </h1>
          </div>
          {/* <button
            type="submit"
            class="w-40 mr-0 text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-md text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Calculate
          </button> */}

          {/* end////////////////////// */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LoanCalculator;
