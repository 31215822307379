import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { InputField, DatePickerField, SelectField } from "../../FormFields";

const titles = [
  {
    value: "369",
    label: "Dr",
  },
  {
    value: "370",
    label: "Nurse",
  },
  {
    value: "371",
    label: "Teacher",
  },
  {
    value: "372",
    label: "Lawyer",
  },
  {
    value: "373",
    label: "Other",
  },
];

export default function AddressForm(props) {
  const {
    formField: {
      ID_No6,
      Passport_No7,
      No_of_dependents9,
      Nationality10,
      profession_cd_Profession11,
      Mobile_No214,
      LandlineHome_phone16,
      Resedential_Address17,
      Postal_Address20,
      //submittedon_date,
    },
  } = props;

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Personal Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Passport_No7.name}
            label={Passport_No7.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={LandlineHome_phone16.name}
            label={LandlineHome_phone16.label}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={ID_No6.name} label={ID_No6.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Mobile_No214.name}
            label={Mobile_No214.label}
            fullWidth
            type=""
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={No_of_dependents9.name}
            label={No_of_dependents9.label}
            fullWidth
            type=""
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectField
            name={profession_cd_Profession11.name}
            label={profession_cd_Profession11.label}
            data={titles}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField
            name={Nationality10.name}
            label={Nationality10.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Resedential_Address17.name}
            label={Resedential_Address17.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Postal_Address20.name}
            label={Postal_Address20.label}
            fullWidth
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
