import React from "react";
import { useFormikContext } from "formik";
import { Typography, Grid } from "@material-ui/core";
import PersonalDetails from "./PersonalDetails";

import MonthlyExpenditure from "./MonthlyExpenditure";
import { useSelector } from "react-redux";

export default function ReviewOrder() {
  const { values: formValues } = useFormikContext();
  const userData = useSelector((state) => state.user);
  const user = userData.user;
  console.log(user);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        <h3 className="text-center">Review your application</h3>
      </Typography>
      <PersonalDetails formValues={formValues} />
     
      <MonthlyExpenditure formValues={formValues} />
    </React.Fragment>
  );
}
