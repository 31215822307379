import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
} from "@material-ui/core";
import useStyles from "./styles";
import formField from "../FormModel/checkoutFormModel";

function PersonalDetails(props) {
  const { formValues } = props;

  const classes = useStyles();

  JSON.stringify(formField);
  //console.log(formValues);
  // console.log(formField.formField.Passport_No7.label);
  return (
    <List>
      <ListItem className={classes.listItem}>
        <div className="flex flex-col">
        <h3 className="uppercase font-bold test-2xl text-center py-4 bg-gray-200 rounded-lg">Personal Details</h3>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Passport_No7.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Passport_No7 ? formValues.Passport_No7 : "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.Resedential_Address17.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Resedential_Address17}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              {" "}
              <ListItemText
                primary={formField.formField.ID_No6.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.ID_No6}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              {" "}
              <ListItemText
                primary={formField.formField.Postal_Address20.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.Postal_Address20}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              {" "}
              <ListItemText
                primary={formField.formField.LandlineHome_phone16.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.LandlineHome_phone16 ? formValues.LandlineHome_phone16 : "Not provided"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ListItemText
                primary={formField.formField.No_of_dependents9.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.No_of_dependents9 ?formValues.No_of_dependents9 : "Not provided"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              {" "}
              <ListItemText
                primary={formField.formField.Nationality10.label}
                className={classes.heading}
              />
              <Typography
                variant="body2"
                className={classes.value}
                sx={{ paddingY: 2 }}
              >
                {formValues.Nationality10}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              {" "}
              <ListItemText
                primary={formField.formField.Mobile_No214.label}
                className={classes.heading}
              />
              <Typography variant="body2" className={classes.value}>
                {formValues.Mobile_No214}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </ListItem>
    </List>
  );
}

export default PersonalDetails;
