import React from 'react';
import MaterialLayout from "../applyloan/Layout/MaterialLayout";
import CheckoutPage from "../applyzw";

function ApplyZW() {
  return (
    <MaterialLayout>
      <CheckoutPage />
    </MaterialLayout>
  );
}

export default ApplyZW;
