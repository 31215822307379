import React from "react";


//import Currency from './Currency';
// import { TfiReload } from 'react-icons/TfiReload'

const DashboardContentUSD = () => {
    return (
        <>
        <div class="bg-gray-100 rounded-lg p-4 sm:p-6 xl:p-8 ">
                    <div class=" flex flex-start flex-col">
                    <h3 class="text-base pb-2 uppercase text-red-600">balance</h3>

                    <span class="text-2xl sm:text-4xl leading-none font-bold text-gray-900">USD 2 000</span>
                    {/* <TfiReload/> */}
                    </div>


                </div>
                <div class="bg-gray-100 rounded-lg p-4 sm:p-6 xl:p-8 ">
                    <div class=" flex flex-start flex-col">
                        <h3 class="text-base pb-2 uppercase text-red-600">Monthly installments</h3>

                        <span class="text-2xl sm:text-4xl leading-none font-bold text-gray-900">$ 200</span>
                    </div>


                </div>
                <div class="bg-gray-100 rounded-lg p-4 sm:p-6 xl:p-8 ">
                    <div class=" flex flex-start flex-col">
                    <h3 class="text-base pb-2 uppercase text-red-600">Next Repayment date</h3>

                    <span class="text-2xl sm:text-4xl leading-none font-bold text-gray-900">20 March 2023</span>
                    </div>


                </div>

        </>
    )
}

export default DashboardContentUSD;
