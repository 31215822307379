import React from "react";
import { useStepperContext } from "../../StepperContext";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";

export default function PersonalDetails() {
  const { userData, setUserData } = useStepperContext();

  const renderError = (message) => (
    <p className="italic text-red-600">{message}</p>
  );


  // const onSubmit = (values) => {
  //   alert(JSON.stringify(values));
  //   console.log(JSON.stringify(values))
  // };
  const validationSchema = yup.object({
    firstname: yup
      .string()
      .min(3, "Please enter your real firstname")
      .required("firstname is required"),
  });
  //formik
  // const formik = useFormik({
  //   initialValues: {
  //     firstname: "",
  //   },
  //   validateOnBlur: true,
  //   onSubmit,
  //   validationSchema: validationSchema,
  // });

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setUserData({ ...userData, [name]: value });
  // };

  return (
    <Formik
    initialValues={{
      name: "",
      email: "",
    }}
    validationSchema={validationSchema}
    onSubmit={(values) => {
      const data = { ...userData, ...values };
      setUserData(data);
    alert(values)
    }}
  >
    <Form class="max-w-2xl py-2 px-5 m-auto w-full">
      <h1 className="font-bold text-xl sm:text-2xl pb-16">Personal Details</h1>
      <div class="grid grid-cols-2 gap-4 m-auto">
        
          <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
            <label className="">Title:</label>
          </div>

          <div class="col-span-2 lg:col-span-1">
            <select
              type="text"
              class=" bg-white rounded-lg p-2 w-full focus:outline-none"
              // onChange={handleChange}
              value={userData["title"] || ""}
              name="title"
            >
              <option value="Mr">Mr</option>
              <option value="Miss">Miss</option>
              <option value="Mrs">Mrs</option>
              <option value="Dr">Dr</option>
              <option value="Prof">Prof</option>
            </select>
          </div>

          <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
            <label className="">Firstname:</label>
          </div>
          <div class="col-span-2 lg:col-span-1">
            <Field
              type="text"
              class=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300"
              placeholder="Firstname"
              name="firstname"
            
             
            />
         <ErrorMessage name="firstname" render={renderError} />
          </div>
         

          <div class="col-span-2 lg:col-span-1">
            <label className="">Middle Name:</label>
          </div>
          <div class="col-span-2 lg:col-span-1">
            <input
              type="text"
              class=" bg-white rounded-lg p-2 w-full focus:outline-none"
              placeholder="Middle Name"
              // onChange={handleChange}
              value={userData["middleName"] || ""}
              name="middleName"
            />
          </div>

          <div class="col-span-2 lg:col-span-1">
            <label className="">Lastname:</label>
          </div>
          <div class="col-span-2 lg:col-span-1">
            <input
              type="text"
              class=" bg-white rounded-lg p-2 w-full focus:outline-none"
              placeholder="Last Name"
              // onChange={handleChange}
              value={userData["lastname"] || ""}
              name="lastname"
            />
          </div>

          <div class="col-span-2 lg:col-span-1">
            <label className="">ID Number:</label>
          </div>
          <div class="col-span-2 lg:col-span-1">
            <input
              type="text"
              class=" bg-white rounded-lg p-2  w-full focus:outline-none"
              placeholder="ID Number"
              // onChange={handleChange}
              value={userData["idNumber"] || ""}
              name="idNumber"
            />
          </div>
          <div class="col-span-2 lg:col-span-1">
            <label className="">Date Of Birth:</label>
          </div>
          <div class="col-span-2 lg:col-span-1">
            <input
              type="date"
              class=" bg-white rounded-lg p-2  w-full focus:outline-none"
              // onChange={handleChange}
              value={userData["dob"] || ""}
              name="dob"
            />
          </div>

          <div class="col-span-2 lg:col-span-1">
            <label className="">Number Of Dependents:</label>
          </div>
          <div class="col-span-2 lg:col-span-1">
            <input
              type="text"
              class=" bg-white rounded-lg p-2  w-full focus:outline-none"
              placeholder="Number Of Dependents"
              // onChange={handleChange}
              value={userData["numberOfDependents"] || ""}
              name="numberOfDependents"
            />
          </div>

          <div class="col-span-2 lg:col-span-1">
            <label className="">Profession:</label>
          </div>
          <div class="col-span-2 lg:col-span-1">
            <input
              type="text"
              class=" bg-white rounded-lg p-2  w-full focus:outline-none"
              placeholder="Profession"
              // onChange={handleChange}
              value={userData["profession"] || ""}
              name="profession"
            />
          </div>

          <div class="col-span-2 lg:col-span-1">
            <label className="">Nationality:</label>
          </div>
          <div class="col-span-2 lg:col-span-1">
            <input
              type="text"
              class=" bg-white rounded-lg p-2  w-full focus:outline-none"
              placeholder="Nationality"
              // onChange={handleChange}
              value={userData["nationality"] || ""}
              name="nationality"
            />
          </div>
          <div class="col-span-2 lg:col-span-1">
            <label className="">Loan Amount:</label>
          </div>
          <div class="col-span-2 lg:col-span-1">
            <input
              type="text"
              class=" bg-white rounded-lg p-2  w-full focus:outline-none"
              placeholder="Loan Amount"
              // onChange={handleChange}
              value={userData["loanAmount"] || ""}
              name="loanAmount"
            />
          </div>
          <div class="col-span-2 lg:col-span-1">
            <label className="">Loan Type:</label>
          </div>
          <div class="col-span-2 lg:col-span-1">
            <select
              type="text"
              class=" bg-white rounded-lg p-2  w-full focus:outline-none"
              placeholder="Payment Period"
              // onChange={handleChange}
              value={userData["loanType"] || ""}
              name="loanType"
            >
              <option value="4 Months To Pay">4 Months To Pay</option>
              <option value="6 Months To Pay">6 Months To Pay</option>
            </select>
          </div>
   
      </div>
    </Form>
    </Formik>
  );
}
