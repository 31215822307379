import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField } from "@material-ui/core";
import { InputField, SelectField } from "../../FormFields";

export default function MonthlyExpenditure(props) {
  const {
    formField: {
      //montly expenditure #####INCOME
      Gross_Incomepe1,
      Net_Income_per2,
      Other_Incomesp3,
      Total_IncomeNe4,
      Monthly_Rental1,
      Monthly_Bus_Fare2,
      Monthly_vehicle3,
      Monthly_school4,
      Monthly_Insurance7,
      Monthly_credit_8,
      Monthly_Groceries9,
      Monthly_Utiliti10,
      Other_monthly_e11,
      Net_IncomeTota12,
    },
  } = props;

  // //hook defined
  const [input, setInput] = useState({
    num1: "",
    num2: "",
    num3: "",
  });
  const [sum, setSum] = useState(undefined);

  useEffect(() => {
    setSum(parseInt(input.num1) + parseInt(input.num3));
  }, [input]);

  // handle input change

  const handleInput = function (e) {
    setInput({
      ...input,
      [e.target.id]: e.target.value,
    });
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
      <h2 className="font-bold">   Income</h2>
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Gross_Incomepe1.name}
            id="num1"
            label={Gross_Incomepe1.label}
            // onChange={handleInput}
            // value={input.num1}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Net_Income_per2.name}
            label={Net_Income_per2.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Other_Incomesp3.name}
            id="num3"
            label={Other_Incomesp3.label}
            // onChange={handleInput}
            // value={input.num3}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
           <h2 className="font-bold"> Fixed Monthly Expenditure</h2>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Monthly_Rental1.name}
            id="num3"
            label={Monthly_Rental1.label}
            // onChange={handleInput}
            // value={input.num3}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Monthly_Bus_Fare2.name}
            id="num3"
            label={Monthly_Bus_Fare2.label}
            // onChange={handleInput}
            // value={input.num3}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField
            name={Monthly_vehicle3.name}
            id="num3"
            label={Monthly_vehicle3.label}
            // onChange={handleInput}
            // value={input.num3}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Monthly_school4.name}
            id="num3"
            label={Monthly_school4.label}
            // onChange={handleInput}
            // value={input.num3}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Monthly_Insurance7.name}
            id="num3"
            label={Monthly_Insurance7.label}
            // onChange={handleInput}
            // value={input.num3}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Monthly_credit_8.name}
            id="num3"
            label={Monthly_credit_8.label}
            // onChange={handleInput}
            // value={input.num3}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Monthly_Groceries9.name}
            id="num3"
            label={Monthly_Groceries9.label}
            // onChange={handleInput}
            // value={input.num3}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Monthly_Utiliti10.name}
            id="num3"
            label={Monthly_Utiliti10.label}
            // onChange={handleInput}
            // value={input.num3}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={Other_monthly_e11.name}
            id="num3"
            label={Other_monthly_e11.label}
            // onChange={handleInput}
            // value={input.num3}
            fullWidth
          />
        </Grid>

        {/* {sum !== undefined && <span>{sum ? sum : 0}</span>} */}
      </Grid>
    </React.Fragment>
  );
}
