import React from "react";
import { useStepperContext } from "../../StepperContext";

export default function References() {
  const { userData, setUserData } = useStepperContext();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  return (
    <div className="max-w-2xl py-2 px-5 m-auto w-full">
       <h1 className="font-bold text-xl sm:text-2xl pb-16">Spouse's Details</h1>
      <div className="grid grid-cols-2 gap-4 m-auto">
      <div className="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Title:</label>
        </div>

        <div className="col-span-2 lg:col-span-1">
          <select type="text" className=" bg-white rounded-lg p-2 w-full focus:outline-none" onChange={handleChange}
            value={userData["title"] || ""}
            name="title"
          >
            <option value="Mr">Mr</option>
            <option value="Miss">Miss</option>
            <option value="Mrs">Mrs</option>
            <option value="Dr">Dr</option>
            <option value="Prof">Prof</option>

          </select>
        </div>

        <div className="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Firstname:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300" placeholder="Firstname" onChange={handleChange}
            value={userData["firstName"] || ""}
            name="firstName"
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <label className="">Middle Name:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2 w-full focus:outline-none" placeholder="Middle Name" onChange={handleChange}
            value={userData["middleName"] || ""}
            name="middleName"
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <label className="">Lastname:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2 w-full focus:outline-none" placeholder="Last Name" onChange={handleChange}
            value={userData["lastname"] || ""}
            name="lastname"
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <label className="">ID Number:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="ID Number" onChange={handleChange}
            value={userData["idNumber"] || ""}
            name="idNumber"
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <label className="">Passport Number:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Passport Number" onChange={handleChange}
            value={userData["passportNumber"] || ""}
            name="passportNumber"
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <label className="">Date Of Birth:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="date" className=" bg-white rounded-lg p-2  w-full focus:outline-none" onChange={handleChange}
            value={userData["dob"] || ""}
            name="dob"
          />
        </div>
        

        <div className="col-span-2 lg:col-span-1">
          <label className="">Number Of Dependents:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Number Of Dependents" onChange={handleChange}
            value={userData["numberOfDependents"] || ""}
            name="numberOfDependents"
          />
        </div>
        <div className="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Gender:</label>
        </div>

        <div className="col-span-2 lg:col-span-1">
          <select type="text" className=" bg-white rounded-lg p-2 w-full focus:outline-none" onChange={handleChange}
            value={userData["gender"] || ""}
            name="gender"
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>

          </select>
        </div>

        
        <div className="col-span-2 lg:col-span-1">
          <label className="">Profession:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Profession" onChange={handleChange}
            value={userData["profession"] || ""}
            name="profession"
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <label className="">Mobile Number:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="+263 777 777 777" onChange={handleChange}
            value={userData["mobileNumber"] || ""}
            name="mobileNumber" 
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <label className="">Email Address:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Email Address" onChange={handleChange}
            value={userData["emailAddress"] || ""}
            name="emailAddress"
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <label className="">House Number:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="House Number" onChange={handleChange}
            value={userData["houseNumber"] || ""}
            name="houseNumber"
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <label className="">Street Name:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Street Name" onChange={handleChange}
            value={userData["streetName"] || ""}
            name="streetName"
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <label className="">Town / City:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Town / City" onChange={handleChange}
            value={userData["townCity"] || ""}
            name="townCity"
          />
        </div>

       <div className="col-span-2 lg:col-span-2"><h1 className="font-bold text-xl sm:text-2xl pb-16">Next of Kin's Details</h1></div> 
       
       <div className="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Title:</label>
        </div>

        <div className="col-span-2 lg:col-span-1">
          <select type="text" className=" bg-white rounded-lg p-2 w-full focus:outline-none" onChange={handleChange}
            value={userData["title"] || ""}
            name="title"
          >
            <option value="Mr">Mr</option>
            <option value="Miss">Miss</option>
            <option value="Mrs">Mrs</option>
            <option value="Dr">Dr</option>
            <option value="Prof">Prof</option>

          </select>
        </div>

        <div className="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Name/s:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300" placeholder="Name/s" onChange={handleChange}
            value={userData["names"] || ""}
            name="names"
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <label className="">Lastname:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2 w-full focus:outline-none" placeholder="Lastname" onChange={handleChange}
            value={userData["lastname"] || ""}
            name="lastname"
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <label className="">Mobile Number:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="+263 777 777 777" onChange={handleChange}
            value={userData["mobileNumber"] || ""}
            name="mobileNumber" 
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <label className="">Landline Number:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Landline Number " onChange={handleChange}
            value={userData["landlineNumber"] || ""}
            name="landlineNumber" 
          />
        </div>
        

        <div className="col-span-2 lg:col-span-1">
          <label className="">Email Address:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Email Address" onChange={handleChange}
            value={userData["emailAddress"] || ""}
            name="emailAddress"
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <label className="">House Number:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="House Number" onChange={handleChange}
            value={userData["houseNumber"] || ""}
            name="houseNumber"
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <label className="">Street Name:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Street Name" onChange={handleChange}
            value={userData["streetName"] || ""}
            name="streetName"
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <label className="">Town / City:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Town / City" onChange={handleChange}
            value={userData["townCity"] || ""}
            name="townCity"
          />
        </div>
        
        <div className="col-span-2 lg:col-span-2"><h1 className="font-bold text-xl sm:text-2xl pb-16">Details of Relatives Who Live With You</h1></div> 
       
       <div className="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Title:</label>
        </div>

        <div className="col-span-2 lg:col-span-1">
          <select type="text" className=" bg-white rounded-lg p-2 w-full focus:outline-none" onChange={handleChange}
            value={userData["title"] || ""}
            name="title" 
          >
            <option value="Mr">Mr</option>
            <option value="Miss">Miss</option>
            <option value="Mrs">Mrs</option>
            <option value="Dr">Dr</option>
            <option value="Prof">Prof</option>

          </select>
        </div>

        <div className="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Name/s:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300" placeholder="Name/s" onChange={handleChange}
            value={userData["names"] || ""}
            name="names"
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <label className="">Lastname:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2 w-full focus:outline-none" placeholder="Lastname" onChange={handleChange}
            value={userData["lastname"] || ""}
            name="lastname"
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <label className="">Mobile Number:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="+263 777 777 777" onChange={handleChange}
            value={userData["mobileNumber"] || ""}
            name="mobileNumber" 
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <label className="">Landline Number:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Landline Number " onChange={handleChange}
            value={userData["landlineNumber"] || ""}
            name="landlineNumber" 
          />
        </div>
        

        <div className="col-span-2 lg:col-span-1">
          <label className="">Email Address:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Email Address" onChange={handleChange}
            value={userData["emailAddress"] || ""}
            name="emailAddress"
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <label className="">Residential address:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Residential address" onChange={handleChange}
            value={userData["residentialAddress"] || ""}
            name="residentialAddress"
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <label className="">House Number:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="House Number" onChange={handleChange}
            value={userData["houseNumber"] || ""}
            name="houseNumber"
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <label className="">Street Name:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Street Name" onChange={handleChange}
            value={userData["streetName"] || ""}
            name="streetName"
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <label className="">Town / City:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Town / City" onChange={handleChange}
            value={userData["townCity"] || ""}
            name="townCity"
          />
        </div>

        <div className="col-span-2 lg:col-span-2"><h1 className="font-bold text-xl sm:text-2xl pb-16">Details of Relatives Who Don't Live With You</h1></div> 
       
       <div className="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Title:</label>
        </div>

        <div className="col-span-2 lg:col-span-1">
          <select type="text" className=" bg-white rounded-lg p-2 w-full focus:outline-none" onChange={handleChange}
            value={userData["title"] || ""}
            name="title" 
          >
            <option value="Mr">Mr</option>
            <option value="Miss">Miss</option>
            <option value="Mrs">Mrs</option>
            <option value="Dr">Dr</option>
            <option value="Prof">Prof</option>

          </select>
        </div>

        <div className="col-span-2 lg:col-span-1 flex flex-col justify-center">
          <label className="">Name/s:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2 w-full focus:outline-none focus:ring-gray-300" placeholder="Name/s" onChange={handleChange}
            value={userData["names"] || ""}
            name="names"
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <label className="">Lastname:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2 w-full focus:outline-none" placeholder="Lastname" onChange={handleChange}
            value={userData["lastname"] || ""}
            name="lastname"
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <label className="">Mobile Number:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="+263 777 777 777" onChange={handleChange}
            value={userData["mobileNumber"] || ""}
            name="mobileNumber" 
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <label className="">Landline Number:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Landline Number " onChange={handleChange}
            value={userData["landlineNumber"] || ""}
            name="landlineNumber" 
          />
        </div>
        

        <div className="col-span-2 lg:col-span-1">
          <label className="">Email Address:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Email Address" onChange={handleChange}
            value={userData["emailAddress"] || ""}
            name="emailAddress"
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <label className="">Residential address:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Residential address" onChange={handleChange}
            value={userData["residentialAddress"] || ""}
            name="residentialAddress"
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <label className="">House Number:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="House Number" onChange={handleChange}
            value={userData["houseNumber"] || ""}
            name="houseNumber"
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <label className="">Street Name:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Street Name" onChange={handleChange}
            value={userData["streetName"] || ""}
            name="streetName"
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <label className="">Town / City:</label>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <input type="text" className=" bg-white rounded-lg p-2  w-full focus:outline-none" placeholder="Town / City" onChange={handleChange}
            value={userData["townCity"] || ""}
            name="townCity"
          />
        </div>
        
       

        
        
        
      </div>
    </div>
  );
}
